import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import NavCard from './subComponents/NavCard'
import { Container, NavBucket, Title } from './subComponents/Styles'
import { useTranslation } from 'react-i18next'
import SenderModal from './SenderModal'


const NavPage = ({ navItems }) => {

  const { t } = useTranslation()

  const { user } = useSelector(s => s.auth)
  const { sender } = user

  const [selected, setSelected] = useState(null)
  
  return (

    <Container>
      <Title>{t('casenio.cockpit')}</Title>

      <NavBucket className="row">
     
        {navItems.map((item, i) => 
          <NavCard 
            key= {i}
            selected = {selected}
            setSelected = {setSelected}
            section = {item}

          />
        )}

      </NavBucket>
      
      { !sender && <SenderModal user={user}/> }
      
    </Container>
  )
}
  
  
export default NavPage

