import { ALERTS } from './feathers'
// import { checkSent } from './userMessages'

export const _fetchAlerts = async () => { 
  const alerts = await ALERTS.find({ query: { $sort: { id: -1 } } })
  return alerts?.data || alerts
}

export const _createAlert = async (alert) => await ALERTS.create(alert)


export const _patchAlert = async (alert) =>  await ALERTS.patch(alert.id, alert)

