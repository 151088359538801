import React, { useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import { File } from './Styles'
import PDFNavigation from './PDFNavigation/PDFNavigation'

const PDFViewer = ({ path, open }) => {

  const [{ numPages, pageNumber, opened }, setData] = useState({ numPages: null, pageNumber: 1, opened: false })

  useEffect(() => {
    !opened && setData(d => ({ ...d, opened: open }))
  }, [open, opened])


  const onDocumentLoadSuccess = ({ numPages }) => setData(d => ({ ...d, numPages }))


  const onNavigate = (add) => {
    return add
      ? +pageNumber < +numPages && setData(d => ({ ...d, pageNumber: pageNumber + 1 }))
      : +pageNumber > 1 && setData(d => ({ ...d, pageNumber: pageNumber - 1 }))
  }
  


  return (
    <File className="PDFViewer">
       
      { numPages > 1 &&<PDFNavigation 
        pages={numPages} 
        activePage={pageNumber}
        onNavigate={onNavigate}
      />}

      <div>
        {opened && <Document
          file={path}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>}
      </div>

      
    </File>
  )
  
}

export default PDFViewer

