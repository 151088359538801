import { FETCH_HUBS_SUCCESS, FETCH_HUBS_FAILURE } from 'r_constants/hubs.constants'

import { _fetchHubs } from 'r_services/hubs.service'

export const fetchHubs = () => async dispatch => {
  try {
    const hubs = await _fetchHubs()
    dispatch({ type: FETCH_HUBS_SUCCESS, hubs })
  } catch (error) {
    dispatch({ type: FETCH_HUBS_FAILURE, error })
  }
}


