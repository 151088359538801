import styled from 'styled-components'


const HeaderWrapper = styled.div`
position: sticky;
top: 0;
z-index: 998;
`

const MainHeaderStyled = styled.div`
  line-height: 50px;
  background-color: #363a3f;

  img {
    cursor: pointer;
  }

  .logout {
    cursor: pointer;
  }

  .logo {
    margin-right: 1em;
    width: 140px;

    @media (max-width: 768px) {
    width: 80px;
  }
  }

  .LanguagePicker {
    margin-right: 20px;
    margin-bottom: 2em;
    white-space: nowrap;
  }
`

const StyledLogoPack = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 50px;
  margin-right: 1em;
  flex: 1 0 auto;
`

export { MainHeaderStyled, StyledLogoPack, HeaderWrapper }
