import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import DateLine from './DateSelector/DateLine'
import { setSearch } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'


const ByDate = ({ properties, skip }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const searchValue = useSelector(s => s.search)
  const { from, to } = searchValue.date

  const selectDate = (date) => dispatch(setSearch({ date: { ...searchValue.date, ...date } }))

  return ( 

    <div className={`ByDate container  ${properties}`}>

      {
        !skip && <div className="row">
      
          <DateLine 
            property="from"
            placeholder={t('from')}
            date={from}
            referenceDate={to}
            onChange={selectDate}
            maxValue={true}
            properties="col"
          />

          <div className="col-1 p-0"/>

          <DateLine 
            property="to"
            placeholder={t('to')}
            date={to}
            referenceDate={from}
            onChange={selectDate}
            properties="col"
          />

        </div>
      }

    </div>

  )
}

ByDate.propTypes = {
  properties: PropTypes.string,
  skip: PropTypes.bool,
}
    
export default ByDate