import { UPDATE_SEARCH_VALUE, UPDATE_RESULT_VALUE, RESET_SEARCH_VALUE, SET_MISSING } from 'r_constants/noApi.constants'

export const setSearch = (search) => async dispatch => dispatch({ type: UPDATE_SEARCH_VALUE, search })

export const setResult = (value) => async dispatch => dispatch({ type: UPDATE_RESULT_VALUE, value })

export const resetSearch = () => async dispatch => dispatch({ type: RESET_SEARCH_VALUE })

export const setMissing = (missing) => async dispatch => dispatch({ type: SET_MISSING, missing })



