
import styled from 'styled-components'

const StyledHeaderMenu = styled.button`
 background-color: #1d2124;
 border-radius: 5px;

    &:active, &:focus {
      box-shadow: none;
      outline: none;
    }
    &:hover{
      background-color: #000000;
      color: #ffffff;
    }
`

const StyledList = styled.div`
  margin-top: 5px;
  margin-right: 8px;
`

const StyledListItem = styled.div`
 display: flex;
 align-items: center;
 cursor: default;
 padding: 0 1.5em;
`

const StyledRow = styled.div`
  width: max-content;
`

export { StyledHeaderMenu, StyledList, StyledListItem, StyledRow }


