import React from 'react'
import { PropTypes } from 'prop-types'
import { StyledInfoBlock, StyledDescription, StyledHubWrapper, MoreHubsButton } from './Styles'
import { useTranslation } from 'react-i18next'
import HubCell from '../../../../common/HubsList/subs/HubCell'


const InfoBlock = ({ intl, selectedImage, setHubInfo, onClick }) => {

  let { description, hubs, hubsObjects, formedDate, user } = selectedImage

  const { t } = useTranslation()

  if(hubs && hubsObjects) hubs = hubs.map(h => ({ ...h, info: hubsObjects.find(i => +i.hubID === +h.id) }))

  const onMore = e => {
    e.stopPropagation()
    setHubInfo(hubs)
  }


  return <StyledInfoBlock onClick={onClick}>

    <div className="row"> 
      <StyledDescription className="col-sm-12 col-md-2">{t('images.tile')}</StyledDescription>
      <div className="col">{description}</div>
    </div>

    <div className="row"> 
      <StyledDescription className="col-sm-12 col-md-2">{t('images.date')}</StyledDescription>
      <div className="col">{formedDate}</div>
    </div>

    { user && <div className="row"> 
      <StyledDescription className="col-sm-12 col-md-2">{t('images.sender')}</StyledDescription>
      <div className="col">{user.username}</div>
    </div>
    }

    <div className="row">
      <StyledDescription className="col-sm-12 col-md-2">{t('affected.hubs')}</StyledDescription>

      <StyledHubWrapper className="col-sm-12 col-md-2 row">

        {hubs && hubs.length   

          ? hubs.length > 6

            ? hubs.slice(0,4).map(h => <HubCell key={h.id} hub={h}/>)

            : hubs.map(h => <HubCell key={h.id} hub={h}/>)

          : <div>{`- ${t('none')}`}</div>

        }

        {      
          hubs.length > 6 && <div className="col" >
            <MoreHubsButton onClick={onMore}>{t('images.show.all')}</MoreHubsButton>
          </div>
        }

      </StyledHubWrapper>

    </div>
  </StyledInfoBlock>

  
}

InfoBlock.propTypes = {
  selectedImage: PropTypes.object,
}
  

export default InfoBlock

