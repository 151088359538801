import { 
  FETCH_OEM_SUCCESS, FETCH_OEM_FAILURE
} from 'r_constants/oem.constants'
  
import { _fetchOEM } from 'r_services/oem.service'
  
export const fetchOEM = () => async dispatch => {
  try {
    const OEM = await _fetchOEM()
    dispatch({ type: FETCH_OEM_SUCCESS, OEM })
  } catch (error) {
    dispatch({ type: FETCH_OEM_FAILURE, error })
  }
}
  
  
  
  