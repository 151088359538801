const defaultSelected = '#e7e7e7'

export const customStyles = {
  control: (provided, { isFocused }) => ({
    ...provided, 
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'hsl(0,0%,80%)',
    },
    border: isFocused ? '1px solid hsl(0,0%,80%)' : '1px solid hsl(0,0%,80%)'
  }),


  option: (provided, { data: { bgColor, value } }) => ({
    ...provided,
    cursor: 'pointer',

    backgroundColor: value === '*' ? bgColor : 'white',
    color: value === '*' ? 'white' : 'black',


    ':hover': {
      backgroundColor: value === '*' ? '#7d7d7d' : '#d4d4d4',
      color:  value === '*' ? 'white' : 'black',
    }
  }),
  


  multiValue: (styles, { data: { bgColor } }) => ({
    ...styles,
    backgroundColor: bgColor || defaultSelected,
  }),
  
}