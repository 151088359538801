import { 
  FETCH_DOCS_SUCCESS, FETCH_DOCS_FAILURE,
  CREATE_DOCS_SUCCESS, CREATE_DOCS_FAILURE,
  DELETE_DOCS_SUCCESS, DELETE_DOCS_FAILURE
} from 'r_constants/docs.constants'
    
import { _fetchDocs, _createDocs, _deleteDocs } from 'r_services/docs.service'
    
export const fetchDocs = () => async dispatch => {
  try {
    const docs = await _fetchDocs()
    dispatch({ type: FETCH_DOCS_SUCCESS, docs })
  } catch (error) {
    dispatch({ type: FETCH_DOCS_FAILURE, error })
  }
}
    
export const createDocs = (_docs) => async dispatch => {
  try {
    const docs = await _createDocs(_docs)
    dispatch({ type: CREATE_DOCS_SUCCESS, docs })
  } catch (error) {
    dispatch({ type: CREATE_DOCS_FAILURE, error })
  }
}

export const deleteDocs = (id) => async dispatch => {
  try {
    const doc = await _deleteDocs(id)
    dispatch({ type: DELETE_DOCS_SUCCESS, doc })
  } catch (error) {
    console.log(error)
    dispatch({ type: DELETE_DOCS_FAILURE, error })
  }
}
    
    
    