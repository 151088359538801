import { 
  FETCH_VOICE_MESSAGES_SUCCESS, FETCH_VOICE_MESSAGES_FAILURE,
  CREATE_VOICE_MESSAGES_SUCCESS, CREATE_VOICE_MESSAGES_FAILURE
} from 'r_constants/voiceMessages.constants'
    
import { _fetchVoiceMessages, _createVoiceMessages } from 'r_services/voiceMessages.service'
    
export const fetchVoiceMessages = () => async dispatch => {
  try {
    const voiceMessages = await _fetchVoiceMessages()
    dispatch({ type: FETCH_VOICE_MESSAGES_SUCCESS, voiceMessages })
  } catch (error) {
    dispatch({ type: FETCH_VOICE_MESSAGES_FAILURE, error })
  }
}
    
export const createVoiceMessages = (_voiceMessages) => async dispatch => {

  console.log(_voiceMessages)
  try {
    const voiceMessages = await _createVoiceMessages(_voiceMessages)
    dispatch({ type: CREATE_VOICE_MESSAGES_SUCCESS, voiceMessages })
  } catch (error) {
    dispatch({ type: CREATE_VOICE_MESSAGES_FAILURE, error })
  }
}
    
    
    