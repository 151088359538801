import initialState from './initialState'
import { 
  FETCH_VOICE_MESSAGES_SUCCESS, FETCH_VOICE_MESSAGES_FAILURE,
  CREATE_VOICE_MESSAGES_SUCCESS, CREATE_VOICE_MESSAGES_FAILURE,
} from 'r_constants/voiceMessages.constants'

export default function voiceMessages(voiceMessages = initialState.voiceMessages, action) {
  switch (action.type) {
      
  case FETCH_VOICE_MESSAGES_SUCCESS:
    return action.voiceMessages || null

  case FETCH_VOICE_MESSAGES_FAILURE:
    return voiceMessages

  case CREATE_VOICE_MESSAGES_SUCCESS:
    return voiceMessages

  case CREATE_VOICE_MESSAGES_FAILURE:
    return voiceMessages

  default:
    return voiceMessages
  }
}
