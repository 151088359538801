import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { parseISO, isEqual } from 'date-fns'
import { patchAlert } from 'r_actions/alerts.actions'
import Button from '../../Button/Button'
import Modal from '../ModalComponents/Modal'
import ModalHeader from '../ModalComponents/ModalHeader'
import ModalBody from '../ModalComponents/ModalBody'
import ModalFooter from '../ModalComponents/ModalFooter'
import { useTranslation } from 'react-i18next'
import SettingsView from './SettingsView'


//TODO: refactor modal

const AlertSettingsModal = ({ setModal, item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [{ planedDate, repeat }, setData] = useState({ 
    planedDate: parseISO(item.planedDate), 
    repeat: item.repeat 
  })

  const onSet = () => {
    dispatch(patchAlert({ id: item.id, repeat, planedDate }))
    setModal(false)
  }

  const disabled = isEqual(new Date(parseISO(item.planedDate)), new Date(planedDate)) && item.repeat === repeat

  return ( <Modal className="modal modal-lg" active={true} >
    <ModalHeader>
      <h5>{t('shipping.settings')}</h5>
    </ModalHeader>
    <ModalBody>
      <SettingsView setData={setData} planedDate={planedDate} repeat={repeat}/>
    </ModalBody>
    <ModalFooter>
      <Button className="left-button" onClick={() => setData({ planedDate: null, repeat: null })} semantic="danger">{t('alerts.reset')}</Button>
      <Button onClick={() => onSet()} disabled={disabled} semantic="success">{t('apply')}</Button>
      <Button onClick={() => setModal(false)} semantic="dark">{t('cancel')}</Button>
    </ModalFooter>
  </Modal>
  )
}


AlertSettingsModal.propTypes = {
  item: PropTypes.object,
  cancel: PropTypes.func,
}




export default AlertSettingsModal
