import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

const StyledSettingsSection = styled.div`
line-height: normal;

`

const StyledDateWrapper = styled.div`
padding: 20px;


`

const StyledRepititionWrapper = styled.div`
margin-top: 2em;

`

const StyledTitle = styled.div`
margin-bottom: 1em;

`


export { StyledSettingsSection, StyledDateWrapper, StyledRepititionWrapper, StyledTitle  }
