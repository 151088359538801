import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { foreignLogin } from  'r_actions/auth.actions'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import IconWithTooltip from './IconWithTooltip'

const noHubs = ['hubs-archive', 'docs-archive']

const IconRow = ({ itemId, onClick, open, item, setModal, pathname, remove }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const formDate = date => format(new Date(date), 'dd.MM.yyyy HH:mm', { locale: de })

  const now = new Date()

  const showLoop = item.repeat
  const showTime = item.planedDate && new Date(item.planedDate) > now


  const loop = {
    d: t('alerts.send.daily'), 
    w: t('alerts.send.weekly'), 
    m: t('alerts.send.monthly'), 
    y: t('alerts.send.yearly') 
  }


  //TODO handle multi user on one hub...
  //TODO refactor all this s****...
  const moveToWeb = () => {
    if (item.users?.length) dispatch(foreignLogin({ subordinateID: item.users[0].id }))
  }

  return (
    <>
      {showLoop && <IconWithTooltip 
        name="loop"
        itemId={itemId}
        message={t('tooltip.loop', { loop: loop[item.repeat] })}
        onClick={() => setModal(true)}
      />
      }
      
      {showTime && <IconWithTooltip 
        name="clock"
        itemId={itemId}
        message={t('tooltip.clock', { clock: formDate(item.planedDate) })}
        onClick={() => setModal(true)}
      />
      }
      
      {
        !noHubs.includes(pathname) && <IconWithTooltip 
          name="grid"
          itemId={itemId}
          message={t('tooltip.hubs')}
          onClick={() => onClick(itemId, 'hub')}
        />
      }

      {pathname === 'hubs-archive' && <IconWithTooltip 
        name="login"
        itemId={itemId}
        message={t('tooltip.forward')}
        onClick={() => moveToWeb()}
      />
      }

      {pathname !== 'hubs-archive' && <IconWithTooltip 
        name={open ? 'arrow-up' : 'arrow-down'}
        itemId={itemId}
        message={t('tooltip.information')}
        onClick={() => onClick(itemId, 'content')}
      />
      }

      {remove && <IconWithTooltip 
        name="trash"
        itemId={itemId}
        message={t('tooltip.delete')}
        onClick={() => setModal(true)}
      />   
      }
    </>

  )
}

IconRow.propTypes = {
  itemId: PropTypes.number,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  item: PropTypes.object,
  setModal: PropTypes.func, 
  pathname: PropTypes.string,
  remove: PropTypes.func,
  
}

export default IconRow


