import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Button from 'components/common/Button/Button'
import Modal from 'components/common/Modal/ModalComponents/Modal'
import ModalHeader from 'components/common/Modal/ModalComponents/ModalHeader'
import ModalBody from 'components/common/Modal/ModalComponents/ModalBody'
import ModalFooter from 'components/common/Modal/ModalComponents/ModalFooter'
import { useTranslation } from 'react-i18next'
import InputField from 'components/common/InputField/InputField'
import { patchUserProfile, createUserProfile } from 'r_actions/userProfile.actions'
import toastr from 'toastr'


const SenderModal = ({ user, cancel = null }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { username, sender, profileID } = user

  const [_sender, setSender] = useState(sender || username)

  const onChange = e => setSender(e.target.value)

  const patchProfile = async () => { 
    if (_sender){ 
      const good = await dispatch(profileID 
        ? patchUserProfile({ sender: _sender }) 
        : createUserProfile({ sender: _sender, name: _sender }) 
      )   
      good && toastr.success(t('sender.updated'))
      cancel && cancel()
    }
  }
  

  return ( <Modal className="modal modal-lg" active={true} >

    <ModalHeader>
      <h5>{t('sender.create')}</h5>
    </ModalHeader>

    <ModalBody>
    
      {t('sender.info')}
      
      <div>
        <InputField 
          className="pt-4 pb-2"
          type="text"
          property="sender"
          isValid= {!!_sender} 
          value={_sender}
          onChange={onChange}
          invalidText={t('sender.invalid')}
          maxLength="25"
        />
      </div>
    
    </ModalBody>
    <ModalFooter>
      <Button onClick={patchProfile} disabled={!_sender} semantic="success">{t('apply')}</Button>
      {sender && <Button onClick={cancel} semantic="dark">{t('cancel')}</Button>}
    </ModalFooter>
  </Modal>
  )
}


SenderModal.propTypes = {
  user: PropTypes.object,
  cancel: PropTypes.func
}

export default SenderModal
