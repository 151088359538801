import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone, faSave } from '@fortawesome/free-solid-svg-icons'
import { StyledContainer, StyledStartSaveButton, StyledDisplay, StyledIndicator } from './Styles'

const formatTime = (time) => {
  const formatUnits = units => units < 10 ? `0${units}` : `${units}`

  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${formatUnits(minutes)}:${formatUnits(seconds)}`
  }
  return '00:00'
}

const VoiceRecorder = ({ handlers, data, setDuration }) => {
  const { startRecording, stopRecording, isRecording, recordingTime } = handlers

  const playOrSave = () => {
    if (isRecording) {
      setDuration((Date.now() - data.duration) / 1000)
      stopRecording()
    } else {
      setDuration(Date.now())
      startRecording()
    }
  }


  return (
    <StyledContainer className="controls-container" onClick={playOrSave}>

      <StyledDisplay className="recorder-display">
        {isRecording && <StyledIndicator/>}
        <span>
          {formatTime(recordingTime)} 
        </span>
      </StyledDisplay>

      <StyledStartSaveButton>
        <FontAwesomeIcon icon={isRecording ? faSave: faMicrophone} size="2x" />
      </StyledStartSaveButton>
    </StyledContainer>
  )
}

export default VoiceRecorder