import React from 'react'
import { PropTypes } from 'prop-types'
import HubCell from '../../../../common/HubsList/subs/HubCell'
import { HubsModalComponent } from './Styles'
import { useTranslation } from 'react-i18next'


const HubsInfo = ({ hubs, onClose }) => {
  const { t } = useTranslation()

  return <HubsModalComponent className="row" onClick={onClose}>
     
    {hubs && hubs.length 
      ? hubs.map(h => <HubCell key={h.id} hub={h} boots="col-lg-4 col-md-5" />)
      : <div>{`- ${t('none')}`}</div>
    }

  </HubsModalComponent>
}

HubsInfo.propTypes = {
  hubs: PropTypes.object,
  onClose: PropTypes.func,
}
  

export default HubsInfo
