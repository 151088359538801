import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  margin-left: 8px;
  cursor: pointer;
  z-index: 999;
  font-size: 14px;
  width: 80px;
`

const Background = styled.div`
position: absolute;
left: 0;
right: 0;
background-color: #e9e9e9;
border-radius: 6px;

`

const ListElement = styled.div`
display: flex;
justify-content: center;
align-items: center;
white-space: nowrap;
border-radius: 6px;
padding: 0 20px 0 20px;
height: 30px;


  &:hover {
    background-color: #d6d6d6; 
  }


`

const StyledImg = styled.img`
height: 15px;
padding-left: 10px;
`

export { Wrapper, Background, ListElement, StyledImg }
