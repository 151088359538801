import I18NApi from 'r_services/i18n.service'
// import { switchLocaleTo } from 'intl/intl'
import {
  LOAD_I18N_INFO_SUCCESS,
  LOAD_I18N_INFO_ERROR,
  SWITCH_LANGUAGE
} from 'r_constants/i18n.constants'

export const loadI18NInformation = () => async dispatch => {
  try {
    const i18n = await I18NApi.getI18Nproject()
    dispatch({ type: LOAD_I18N_INFO_SUCCESS, i18n })
  } catch (error) {
    dispatch({ type: LOAD_I18N_INFO_ERROR, error })
  }
}

export const switchLanguage = lng => async dispatch => {
  localStorage.setItem('lng', lng)
  dispatch({ type: SWITCH_LANGUAGE, lng })
  // dispatch(switchLocaleTo(lng))
}
