import React from 'react'
import PropTypes from 'prop-types'
import { useSpring } from 'react-spring'
import { Content } from './Styles'
import { useTranslation } from 'react-i18next'

const skipDate = ['hubs-archive']

const customTitle = { 'voice-archive': 'title-bar.duration' }

const TitleRow = ({ location }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })

  const { t } = useTranslation()

  return (
    <Content className="TitleRow row" style={props}>
      <div className="col-3">{t(customTitle[location] || 'title-bar.tile')}</div>
      {!skipDate.includes(location) && <div className="col-2">{t('title-bar.date')}</div>}
    </Content>
  )
}

TitleRow.propTypes = {
  location: PropTypes.string,
}



export default TitleRow


