import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { setSearch } from 'r_actions/noApi.actions'
import Selector from './selector/Selector'
import './ByHub.scss'


const ByHub = ({ properties, skip }) => {
  
  const dispatch = useDispatch()

  const hubs = useSelector(s => s.hubs || [])
  const search = useSelector(s => s.search)

  const onSearch = (hub) => dispatch(setSearch( { hub } ))
  
  const formedHubs = formHubsOptions(hubs)

  return ( 

    <div className={`ByHub ${properties}`}> 

      { 
        hubs.length && !skip
          ? <Selector 
            selected={search.hub}
            options={formedHubs}
            onChange={onSearch}    
            placeholderID="hubs.selector.placeholder"
            noOptionsID="hubs.selector.no-options"

          /> 
          : <div/>
      }

    </div>
  )
}

ByHub.propTypes = {
  properties: PropTypes.string,
  skip: PropTypes.bool,
}
  
  
export default ByHub

const formHubsOptions = (hubs) => {
  if(!hubs || !hubs.length) return null
  return hubs.map(h => ({ value: h.id, label: h.description }))
}