//!https://feathericons.com

import Bell from './bell.svg'
import Image from './image.svg'
import Mail from './mail.svg'
import Hub from './hub.svg'
import Book from './book.svg'
import Mic from './mic.svg'

export const Icons = { 
  1: Bell, 
  2: Mail, 
  3: Image, 
  4: Mic, 
  5: Hub, 
  6: Book, 
}