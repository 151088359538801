import styled from 'styled-components'
import { animated } from 'react-spring'


const Content = styled(animated.div)`
padding-left: 2em;
line-height: 40px;
margin-right: 0;
background-color: #363a3f;
color: white;
/* border-top: 1px solid #5b6673; */
border-top: 1px solid #4E515C;
font-weight: bold;
user-select: none;
position: sticky;

`

export { Content }
