export default {
   "de": {
      "Alertsound.1": "Ihre Wohnungstuer steht offen,Bitte schliessen Sie die Tuer,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.2": "Ihr Herd ist noch eingeschaltet,Bitte schalten Sie den Herd ab,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.3": "Ihr Wasserhahn laeuft schon sehr lange,Bitte schliessen Sie den Wasserhahn,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.4": "Achtung, Seit geraumer Zeit wird in Ihrer Wohnung keinerlei Aktivitaet mehr erkannt,Ist bei Ihnen alles in Ordnung,Falls ja.beruehren Sie bitte den Bildschirm",
      "affected.hubs": "betreffende Zentraleinheiten",
      "alert.name": "Titel",
      "alert.name.required": "Titel wird benötigt",
      "alerts.applied": "Änderungen wurden übernommen",
      "alerts.archive": "Alarm-Archiv",
      "alerts.created": "Alarm wurde angelegt",
      "alerts.description": "In diesem Bereich können Benachrichtigungen an Zentraleinheiten gesendet werden sowie bereits versendete und erhaltene Benachrichtigungen eingesehen werden.",
      "alerts.not.repeat": "Nicht wiederholen",
      "alerts.reset": "Zurücksetzen",
      "alerts.send": "Alarm versenden",
      "alerts.send.daily": "Jeden Tag",
      "alerts.send.monthly": "Jeden Monat",
      "alerts.send.now": "Sofort versenden",
      "alerts.send.weekly": "Jede Woche",
      "alerts.send.yearly": "Jedes Jahr",
      "alerts.sent": "Alarm wurde versendet",
      "alerts.title": "Alarme",
      "app.accept": "Annehmen",
      "app.refresh": "Aktualisiere...",
      "app.reject": "Ablehnen",
      "app_version": "Erfordert TabletApp Version 3.3.6",
      "apply": "Übernehmen",
      "available": "verfügbar",
      "bluetooth.nosensors.description": "Es befinden sich keine aktiven Bluetooth Geräte in der Nähe",
      "bluetooth.nosensors.title": "Keine Bluetooth Sensoren gefunden",
      "bluetooth.search": "Die App sucht nach verfügbaren Bluetooth Geräten",
      "button.about": "Über",
      "button.connect": "Verbinden",
      "button.deleteconnection": "Verbindung löschen",
      "button.info": "Informationen",
      "button.refresh": "aktualisieren",
      "button.retry": "Erneut probieren",
      "button.search": "Suche starten",
      "button.sensordata": "Sensordaten",
      "button.system": "System",
      "cancel": "Abbrechen",
      "casenio.cockpit": "Cockpit",
      "check-input": "Bitte überprüfen Sie Ihre Eingaben",
      "content": "Inhalt",
      "content-required": "Geben Sie den Inhalt ein",
      "delete": "Löschen",
      "deleted": "erfolgreich gelöscht",
      "description": "Beschreibung",
      "description-required": "Geben Sie eine Beschreibung ein",
      "dispatch.time.set": "Versandzeitpunkt einstellen",
      "docs-create.select.file": "Datei auswählen",
      "docs-create.select.file.please": "Bitte wählen Sie ein Dokument aus.",
      "docs-create.select.oem": "OEM auswählen",
      "docs-create.select.oem.none": "OEMs konnten nicht geladen werden.",
      "docs.archive": "Handbücher einsehen",
      "docs.create": "Handbuch anlegen",
      "docs.create.title": "Bezeichnung",
      "docs.create.title.required": "Bezeichnung wird benötigt",
      "docs.description": "In diesem Bereich können Handbücher angelegt und eingesehen werden.",
      "docs.overview": "Zur Übersicht",
      "docs.title": "Handbücher",
      "error": "Fehler",
      "error.alertremoval": "Alarm konnte nicht gelöscht werden.",
      "error.connection": "Verbindungsfehler",
      "error.notavailable": "nicht verfügbar",
      "error.read": "Fehler beim Vorlesen",
      "error.read.text": "Der Text konnte leider nicht vorgelesen werden. Bitte erneut probieren.",
      "error.sensorconnection": "Sensor konnte nicht verbunden werden. Bitte probieren Sie es erneut.",
      "exclusion": "Exklusion",
      "exclusion.0.description": "Bitte warten und die folgenden Anweisungen beachten.",
      "exclusion.0.title": "Exklusion wird gestartet.",
      "exclusion.1.description": "Bitte jetzt den Sensor ablernen.",
      "exclusion.1.title": "Exklusion aktiv.",
      "exclusion.2.description": "Bitte warten Sie während der Vorgang beendet wird.",
      "exclusion.2.title": "Exklusion wird abgeschlossen",
      "exclusion.3.description": "Das Anlernen eines neues Sensors war erfolgreich.\n Bitte wählen Sie einen Namen für den Sensor.",
      "exclusion.3.title": "Bitte benennen Sie den Sensor",
      "exclusion.4.description": "Der Sensor wurde erfolgreich entfernt.",
      "exclusion.4.title": "Exklusion abgeschlossen.",
      "exclusion.5.description": "Es ist leider ein Fehler aufgetreten,\nversuchen Sie es bitte erneut oder kontaktieren Sie den Support.",
      "exclusion.5.title": "Exklusion fehlgeschlagen.",
      "exclusion.6.description": "Das Zeitlimit wurde überschritten. Bitte versuchen Sie es erneut\noder kontaktieren Sie den Support.",
      "exclusion.6.title": "Zeitlimit überschritten.",
      "exclusion.confirm": "Bitte bestätigen Sie die Exklusion des Sensors",
      "exclusion.confirm.hint": "Der Sensor wird sofort gelöscht und muss vor Benutzung erneut angelernt werden.",
      "exclusion.deleting": "Sensor wird gelöscht",
      "exclusion.remove": "Sensoren entfernen",
      "exclusion.select.sensor": "Bitte den zu exkludierenden Sensor auswählen",
      "feature.1": "Lufttemperatur (Istwert)",
      "feature.1.value": "{value} °C",
      "feature.10000": "Zentraleinheit",
      "feature.101": "Rauch (Alarm)",
      "feature.101.0": "Rauch erkannt",
      "feature.101.1": "Kein Rauch erkannt",
      "feature.105": "Wasserleckage (Alarm)",
      "feature.105.0": "Wasserleckage erkannt",
      "feature.105.1": "Wasserleckage beendet",
      "feature.106": "Brennbares Gas (Alarm)",
      "feature.106.0": "Brennbares Gas erkannt",
      "feature.108": "Hinderniserkennung",
      "feature.108.1": "Hindernis erkannt",
      "feature.109": "Abdeckung",
      "feature.109.1": "Sensor verdeckt",
      "feature.110": "Abstand zum Hindernis",
      "feature.110.1": "{value} m",
      "feature.200": "Stromzähler (Meter)",
      "feature.200.value": "{value} kWh",
      "feature.2000": "Tag Sichtbarkeit",
      "feature.2000.0": "nicht sichtbar",
      "feature.2000.1": "sichtbar",
      "feature.2002": "Körperhaltung",
      "feature.2002.0": "liegen",
      "feature.2002.1": "sitzen",
      "feature.2002.2": "stehen",
      "feature.2002.unknown": "unklar",
      "feature.202": "Wasserzähler",
      "feature.202.value": "{value} m³",
      "feature.203": "Wärmezähler",
      "feature.203.value": "{value} Wärmeeinheiten",
      "feature.206": "Stichtag",
      "feature.206.value": "{value}",
      "feature.211": "Wasserverbrauch",
      "feature.211.value": "{value} m3",
      "feature.2325": "Trinkgefäß-Nutzung",
      "feature.2326": "Essbesteck/Geschirr-Nutzung",
      "feature.3": "Umgebungshelligkeit",
      "feature.3.value": "{value} %",
      "feature.300": "Öffnungskontakt (Tür / Fenster)",
      "feature.300.0": "geöffnet",
      "feature.300.1": "geschlossen",
      "feature.301": "Schalter",
      "feature.301.value": "Taster wurde gedrückt",
      "feature.302": "Schaltbares Gerät",
      "feature.302.0": "ausgeschaltet",
      "feature.302.1": "eingeschaltet",
      "feature.303": "Lampe",
      "feature.303.0": "ausgeschaltet",
      "feature.303.1": "eingeschaltet",
      "feature.304": "Farbe",
      "feature.304.0": "ausgeschaltet",
      "feature.304.1": "eingeschaltet",
      "feature.305": "Bewegung",
      "feature.305.0": "Bewegung registriert",
      "feature.305.1": "Bewegung registriert",
      "feature.306": "Medikationssensor",
      "feature.307": "Gasdetektion",
      "feature.307.0": "Gas erkannt",
      "feature.308": "Wasserfluss",
      "feature.309": "Belegung (Bett)",
      "feature.309.0": "Bett nicht belegt",
      "feature.309.1": "Bett belegt",
      "feature.31": "Nässe (Bett)",
      "feature.31.0": "nass",
      "feature.31.1": "trocken",
      "feature.31.2": "feucht",
      "feature.310": "Elektro-Verbrauchserkennung",
      "feature.310.0": "Gerät verbraucht keinen Strom",
      "feature.310.1": "Gerät verbraucht Strom",
      "feature.311": "Eingestellte Temperatur (Sollwert)",
      "feature.311.1": "erkannt",
      "feature.311.value": "{value} °C",
      "feature.312": "Uhrzeit",
      "feature.312.value": "{value}",
      "feature.313": "Funktionssperre",
      "feature.313.0": "schaltbares Gerät gesperrt",
      "feature.313.1": "schaltbares Gerät freigegeben",
      "feature.314": "Leuchtstärkeregelung",
      "feature.314.value": "{value} W",
      "feature.315": "Manipulationswarnung",
      "feature.315.0": "Gehäuse geöffnet oder Sensor abmontiert",
      "feature.315.1": "Gehäuse geschlossen oder Sensor montiert",
      "feature.316": "Alarmsignalisierung",
      "feature.316.0": "Alarmsignalisierung ausgeschaltet",
      "feature.316.1": "Alarmsignalisierung eingeschaltet",
      "feature.317": "Hahnsteuerung",
      "feature.317.0": "Absperrhahn gesperrt",
      "feature.317.1": "Absperrhahn geöffnet",
      "feature.320": "GPS Tracking",
      "feature.323": "Ventilarretierung",
      "feature.323.0": "frei",
      "feature.323.1": "arretiert",
      "feature.323.2": "keine Info",
      "feature.324": "Fenstergriffstellung",
      "feature.324.0": "geschlossen",
      "feature.324.1": "geöffnet",
      "feature.324.2": "gekippt",
      "feature.325": "Ist-Temperatur Einlieferung",
      "feature.325.value": "{value} °C",
      "feature.331": "Umgebungsänderung",
      "feature.333": "Sensor manipuliert Zähler",
      "feature.333.value": "{value}",
      "feature.334": "Gesammte Manipulationsdauer",
      "feature.334.value": "{value} min",
      "feature.335": "Datum der letzten Manipulation",
      "feature.335.value": "{value}",
      "feature.338": "Türklingel",
      "feature.338.1": "es klingelt",
      "feature.39": "Luftgüte",
      "feature.39.0": "schlechte Luftqualität",
      "feature.39.1": "mittlere Luftqualität",
      "feature.39.2": "gute Luftqualität",
      "feature.39.bad": "schlechte Luftqualität",
      "feature.39.good": "gute Luftqualität",
      "feature.39.middle": "mittlere Luftqualität",
      "feature.4": "Leistung",
      "feature.4.value": "{value} W",
      "feature.5": "Luftfeuchtigkeit",
      "feature.5.value": "{value} %",
      "feature.501": "Energiegewinnung",
      "feature.501.0": "Inaktiv",
      "feature.501.1": "Aktiv",
      "feature.504": "Ventilstellung",
      "feature.504.value": "{value} %",
      "feature.505": "Temperatursturzerkennung",
      "feature.505.0": "Normalbetrieb",
      "feature.505.1": "erkannt",
      "feature.506": "Jalousieposition",
      "feature.506.value": "{value} % geschlossen",
      "feature.58": "GPS SOS",
      "feature.59": "GPS-Entfernung",
      "feature.6": "Geschwindigkeit",
      "feature.6.value": "{value} km/h",
      "feature.60": "Bett Bremse gelöst",
      "feature.600": "System",
      "feature.601": "Bildschirm",
      "feature.601.0": "Touch erkannt",
      "feature.602": "Batterie",
      "feature.602.255": "Batterie wechseln",
      "feature.602.value": "{value} %",
      "feature.603": "Empfangsqualität",
      "feature.603.1": "schlecht",
      "feature.603.2": "gut",
      "feature.603.3": "sehr gut",
      "feature.606": "WVM De-/Aktivierung",
      "feature.61": "Bett Höhenänderung",
      "feature.614": "Letzter Batteriewechsel",
      "feature.614.value": "Batterie gewechselt",
      "feature.619": "Batteriemodus",
      "feature.619.0": "Stecker gezogen",
      "feature.619.1": "wird geladen",
      "feature.619.2": "Voll geladen",
      "feature.62": "Bettgitter Änderung",
      "feature.620": "Sensor-Sender-Verbindung",
      "feature.620.0": "getrennt",
      "feature.620.1": "verbunden",
      "feature.621": "Testdatum Knopf",
      "feature.621.value": "{value}",
      "feature.622": "Baldiges Lebensende",
      "feature.622.1": "gemeldet",
      "feature.623": "Echolot Testdatum",
      "feature.623.value": "{value}",
      "feature.624": "Staubniveau",
      "feature.624.value": "{value} %",
      "feature.625": "Alarmdatum",
      "feature.625.value": "{value}",
      "feature.626": "Testknopf Zähler",
      "feature.626.value": "{value}",
      "feature.65": "Volumenstrom",
      "feature.65.value": "{value} m3h",
      "feature.66": "Energieverbrauch",
      "feature.66.value": "{value} kWh",
      "feature.67": "Vorlauftemperatur",
      "feature.67.value": "{value} °C",
      "feature.68": "Rücklauftemperatur",
      "feature.68.value": "{value} °C",
      "feature.69": "Beleuchtungsstärke",
      "feature.69.value": "{value} Lux",
      "feature.70": "Wärmeverbrauch",
      "feature.70.value": "{value}",
      "feature.71": "Wert am Stichtag\n",
      "feature.71.value": "{value}",
      "feature.72": "Energievernrauch Vormonat",
      "feature.72.value": "{value} kWh",
      "feature.803": "Hilferuf am Tablet",
      "feature.833": "Tablet Verbindung zum Server\n",
      "feature.833.0": "Offline",
      "feature.833.1": "Online",
      "feature.834": "Unbekanntes Feld CL",
      "feature.834.1": "gemeldet",
      "feature.835": "Unbekannter Eintrag",
      "feature.835.1": "gemeldet",
      "feature.836": "Zugangsberechtigung",
      "feature.836.1": "gemeldet",
      "feature.837": "Eintragsgrösse",
      "feature.837.1": "Fehler gemeldet",
      "feature.838": "Eintragswerte",
      "feature.838.1": "Fehler gemeldet",
      "feature.839": "Falsches Password",
      "feature.839.1": "gemeldet",
      "feature.840": "Installation beended",
      "feature.840.1": "gemeldet",
      "feature.841": "Echolot Fehler",
      "feature.841.1": "gemeldet",
      "feature.842": "Kommunikationsfehler",
      "feature.843": "Scan-Fehler",
      "feature.843.1": "gemeldet",
      "feature.844": "Sensorfehler",
      "feature.844.1": "gemeldet",
      "feature.845": "SEODS-Fehler",
      "feature.845.1": "gemeldet",
      "feature.846": "Lebensende",
      "feature.846.1": "gemeldet",
      "feature.847": "Status",
      "feature.847.0": "OK",
      "feature.847.1": "Problem",
      "feature.848": "Unbekanntes Feld C",
      "feature.848.1": "gemeldet",
      "feature.849": "Update der Config",
      "feature.849.1": "bekommen",
      "feature.849.2": "geholt",
      "feature.9000": "X-position",
      "feature.9001": "Y-Position",
      "feature.9002": "Z-Position",
      "feature.9003": "X1-Position",
      "feature.9004": "X2-Position",
      "feature.9005": "Y1-Position",
      "feature.9006": "Y2-Position",
      "feature.9007": "Z1-Position",
      "feature.9008": "Z2-Position",
      "feature.ismeter": "{value} m",
      "from": "Von",
      "hubs.description": "Dieser Bereich beinhaltet die Auflistung aller zugänglichen Zentraleinheiten mit der Möglichkeit zur Anmeldung im Einrichtungsportal.",
      "hubs.overview": "Zur Übersicht",
      "hubs.select.missing": "Keine Hauszentralen vorhanden",
      "hubs.select.placeholder": "Hauszentralen zuweisen",
      "hubs.selector.no-options": "Keine Zentraleinheiten gefunden",
      "hubs.selector.placeholder": "Nach Zentraleinheit filtern",
      "hubs.title": "Zentraleinheiten",
      "images-create.select.file": "Bild auswählen",
      "images.archive": "Bilder-Archiv",
      "images.big-file": "Die gewählte Datei darf maximal {size}MB groß sein.",
      "images.create.select.file.please": "Bitte wählen Sie ein Bild aus.",
      "images.date": "versendet am ",
      "images.description": "In diesem Bereich können Bilder an Zentraleinheiten gesendet werden sowie bereits versendete und erhaltene Bilder eingesehen werden.",
      "images.invalid-file": "Das gewählte Dateiformat ist nicht zugelassen.",
      "images.send": "Bild versenden",
      "images.sender": "Absender",
      "images.sent": "Bild wurde versendet",
      "images.show.all": "alle anzeigen",
      "images.tile": "Titel",
      "images.title": "Bilder",
      "inclusion": "Inklusion",
      "inclusion.0.description": "Bitte warten und die folgenden Anweisungen beachten.",
      "inclusion.0.title": "Inklusion wird gestartet.",
      "inclusion.1.description": "Bitte jetzt den Sensor anlernen.",
      "inclusion.1.title": "Inklusion aktiv.",
      "inclusion.2.description": "Bitte warten Sie während der Vorgang beendet wird.",
      "inclusion.2.title": "Inklusion wird abgeschlossen",
      "inclusion.3.description": "Das Anlernen eines neues Sensors war erfolgreich.\n Bitte wählen Sie einen Namen für den Sensor.",
      "inclusion.3.title": "Bitte benennen Sie den Sensor",
      "inclusion.4.description": "Der Sensor wurde erfolgreich zu Ihrem System\nhinzugefügt und kann nun verwendet werden.",
      "inclusion.4.title": "Inklusion abgeschlossen.",
      "inclusion.5.description": "Es ist leider ein Fehler aufgetreten,\nversuchen Sie es bitte erneut oder kontaktieren Sie den Support.",
      "inclusion.5.title": "Inklusion fehlgeschlagen.",
      "inclusion.6.description": "Das Zeitlimit wurde überschritten. Bitte versuchen Sie es erneut\noder kontaktieren Sie den Support.",
      "inclusion.6.title": "Zeitlimit überschritten.",
      "inclusion.add": "Sensoren hinzufügen",
      "inclusion.addremove": "Hinzufügen und entfernen von Sensoren",
      "inclusion.alreadyincluded": "Gerät bereits gekoppelt",
      "inclusion.bluetooth": "Inklusion eines Bluetooth Gerätes",
      "inclusion.bluetooth.error": "Bluetooth muss eingeschaltet sein um neue Geräte zu Koppeln.\\n Bitte wiederholen Sie die Suche und erlauben die Anfrage",
      "inclusion.changeconnection": "Verbindungstyp Ändern",
      "inclusion.changemode": "Wechsel zwischen AP und WIFI Modus",
      "inclusion.exclusion": "In-/Exklusion",
      "inclusion.fail": "Sensor konnte nicht erstellt werden. Bitte probieren Sie es erneut.",
      "inclusion.name": "Neuer Sensorname",
      "inclusion.select": "Bitte einen Sensortyp aus der Liste auswählen",
      "inclusion.select.action": "Was möchten Sie tun?",
      "inclusion.start": "Bitte das zu inkludierende Gerät aktivieren und die Suche starten",
      "inclusion.success": "Der Sensor wurde erfolgreich angelernt",
      "inclusion.technology": "Um welche Sensortechnologie geht es ?",
      "inclusion.tooshort": "Muss mindestens 3 chars sein",
      "input-password": "Bitte Passwort eingeben",
      "input-username": "Bitte Benutzernamen eingeben",
      "input.area.chars.left": "{{chars}} von {{max}} Zeichen übrig",
      "input.area.chars.max": "Max.{{max}} Zeichen",
      "load.more": "Weitere Ergebnisse laden",
      "logout": "Logout",
      "message.attention": "Achtung",
      "message.confirm": "Wenn Sie fortfahren möchten, klicken Sie auf Bestätigen, anderfalls klicken Sie auf Abbrechen",
      "message.connected": "Verbunden",
      "message.creating": "wird erstellt...",
      "message.current": "aktuell",
      "message.from": "Gesendet von",
      "message.irrevocable": "Diese Aktion kann nicht rückgängig gemacht werden!",
      "message.wait": "Das kann einige Sekunden dauern",
      "news-create.content-or-file": "Bitte geben Sie den Inhalt der Nachricht an oder wählen sie eine Datei.",
      "news-create.select.file": "Datei auswählen",
      "news.archive": "Nachrichten-Archiv",
      "news.big-file": "Die gewählte Datei darf maximal {size}MB groß sein.",
      "news.delete": "Sie sind dabei die Nachricht zu löschen.",
      "news.description": "In diesem Bereich können Nachrichten mit oder ohne Anhang (Bild, PDF) an Zentraleinheiten gesendet werden sowie bereits versendete und erhaltene Nachrichten und deren Inhalt eingesehen werden.",
      "news.invalid-file": "Das gewählte Dateiformat ist nicht zugelassen.",
      "news.notdeleted": "Die Nachricht konnte nicht gelöscht werden.",
      "news.send": "Nachricht versenden",
      "news.sent": "Nachricht wurde versendet",
      "news.title": "Nachrichten",
      "no_match.title": "Uuups!",
      "none": "keine",
      "not-read": "nicht gelesen",
      "not_sent": "ein Fehler ist aufgetreten",
      "password": "Passwort",
      "photos.nophotos": "Noch keine Bilder vorhanden",
      "properties.multi.incomplete": "Einge Liegenschaften sind nicht vollständig ausgewählt",
      "properties.one.incomplete": "Eine Liegenschaft ist nicht vollständig ausgewählt",
      "properties.select.missing": "Keine Liegenschaften vorhanden",
      "properties.select.placeholder": "Liegenschaften zuweisen",
      "read": "gelesen",
      "repetition.cycle.set": "Wiederholungszyklus einstellen",
      "reset.content": "Sie sind dabei die App zurückzusetzen. Alle Einstellungen und bekannte Verbindungen werden gelöscht. Anschließend muss die App erneut konfiguriert und mit dem Internet verbunden werden.",
      "reset.warning": "Ich bin mir bewusst, dass diese Aktion ein erneutes Einrichten der App (Initial Setup) erfordert.",
      "search.filter": "Nach Titel, Inhalt oder Absender filtern",
      "select-all": "Alle auswählen",
      "select-file.optional": "Datei auswählen (optional)",
      "select-least-hub": "Wählen Sie mindestens eine Hauszentrale aus.",
      "select.all": "alle auswählen",
      "send": "Senden",
      "sender": "Absender",
      "sender.create": "Absender festlegen",
      "sender.edit": "Absendernamen ändern",
      "sender.info": "Der Absender wird auf allen aus dem Cockpit versendeten Alarmen, Nachrichten und Bildern angezeigt.",
      "sender.invalid": "Geben Sie einen Absender ein.",
      "sender.updated": "Absender wurde aktualisiert.",
      "sensor.date.add": "Hinzugefügt am: {date}",
      "sensor.meta.1002": "Elektro-Verbrauchserkennung:",
      "sensor.meta.1003": "Empfindlichkeit:\n",
      "sensor.meta.1006": "Arretierungstest nach Schließvorgang:",
      "sensor.meta.1008": "Spannungs-Schwellwert:",
      "sensor.meta.1011": "DIN-Anschluss:",
      "sensor.meta.27": "Der Sensor kann ausgeschaltet werden:",
      "sensor.meta.29": "Lampe",
      "sensor.meta.30": "Thermostat",
      "sensor.meta.31": "Schaltbares Gerät",
      "sensor.meta.43": "Die LED's sind aktiviert:",
      "sensor.meta.47": "Der Sensor wird in der Home-App angezeigt:",
      "sensor.meta.48": "Der Sensor wird im Bereich Raumkima angezeigt:",
      "sensor.meta.no": "NEIN",
      "sensor.meta.show_as": "Sensor anzeigen als:",
      "sensor.meta.yes": "JA",
      "sensor.state": "Sensorstatus",
      "sensor.status.0": "Sensor ist gelöscht oder neu",
      "sensor.status.2": "Sensor ist inaktiv",
      "sensor.status.3": "Sensor ist inaktiv da er zwar registriert aber nicht inkludiert ist (zwave)",
      "sensor.status.4": "Sensor ist inaktiv da er zwar inkludiert aber nicht registriert ist (zwave)",
      "sensor.status.5": "Sensor ist aktiv aber reagiert nicht (zwave)",
      "sensor.status.6": "Sensor ist nicht registriert und reagiert nicht (zwave)",
      "sensor.status.7": "Sensor ist inaktiv und nicht gekoppelt (bluetooth)",
      "sensor.status.8": "Sensor ist inaktiv aber gekoppelt (bluetooth)",
      "sensor.status.disabled": "inaktiv",
      "sensor.status.enabled": "aktiv",
      "sensor.status.unavailable": "Dieser Sensor kann aufgrund von folgendem Status nicht ausgewählt werden: \"{reason}\"",
      "sensor.type": "Sensortyp",
      "sensor.type.0": "Bildschirm",
      "sensor.type.1000": "Mehrfarbige LED-Lampe (E27)",
      "sensor.type.10000": "Zentraleinheit",
      "sensor.type.10002": "Zentraleinheit V3",
      "sensor.type.10003": "Zentraleinheit V3 mit Notrufknopf",
      "sensor.type.10004": "Zentraleinheit",
      "sensor.type.1001": "Wassermelder",
      "sensor.type.1002": "Rauchmelder",
      "sensor.type.1003": "Funksteckdose",
      "sensor.type.10032": "Zentraleinheit V3.2",
      "sensor.type.10033": "Zentraleinheit V3.2 mit Notrufknopf",
      "sensor.type.10034": "Zentraleinheit V3.2 mit Rauchalarm",
      "sensor.type.1004": "Bewegungssensor",
      "sensor.type.1005": "Wandtaster WALLC-S",
      "sensor.type.1006": "Tür-/Fenstersensor",
      "sensor.type.1008": "Bewegungssensor",
      "sensor.type.1009": "Rauchmelder mit Innensirene",
      "sensor.type.101": "Rauchmelder",
      "sensor.type.1011": "Herdabschaltung",
      "sensor.type.1012": "Multisensor",
      "sensor.type.1013": "Hitzemelder",
      "sensor.type.1014": "LED-Lampe",
      "sensor.type.1015": "Thermostat mit LCD Anzeige",
      "sensor.type.1016": "Schalter (vierfach)",
      "sensor.type.1017": "Zeitabschaltung",
      "sensor.type.1018": "Thermostat (nur Funk)",
      "sensor.type.1019": "3-in-1-Bewegungsmelder",
      "sensor.type.1020": "4 in 1 Bewegungsmelder und Türsensor",
      "sensor.type.1021": "Hahnverschlussautomatik",
      "sensor.type.1022": "Bewegungsmelder",
      "sensor.type.1023": "Bettbelegt-Sensor",
      "sensor.type.1024": "Schnurschalter",
      "sensor.type.1025": "Wandtaster (vierfach)",
      "sensor.type.1026": "Funksteckdose",
      "sensor.type.1027": "Wasser- und Gefrierungssensor",
      "sensor.type.1028": "Detektor für brennbare Gase",
      "sensor.type.1029": "Luftfeuchtigkeitssensor",
      "sensor.type.1030": "Bettbelegt-Sensor",
      "sensor.type.1031": "Funksteckdose",
      "sensor.type.1032": "Funksteckdose",
      "sensor.type.1033": "Funksteckdose",
      "sensor.type.1034": "Funksteckdose",
      "sensor.type.1035": "Bettbelegt-Sensor",
      "sensor.type.1036": "Funktaster (Hilferuf-Knopf)",
      "sensor.type.1037": "Alarmsirene",
      "sensor.type.1038": "LED-Lampe (E27)",
      "sensor.type.1039": "Funkthermostat",
      "sensor.type.1040": "3 in 1 Feuchtigkeitssensor",
      "sensor.type.1041": "Wippschalter",
      "sensor.type.1042": "3 in 1 Helligkeits- und Türsensor",
      "sensor.type.1043": "Tür- und Fenstersensor (verdeckter Einbau)",
      "sensor.type.1044": "Funkrepeater zur Reichweitenerhöhung",
      "sensor.type.1045": "Innensirene mit Backup",
      "sensor.type.1046": "Großflächentaster",
      "sensor.type.1047": "Rauchmelder mit Innensirene",
      "sensor.type.1048": "4-Tasten Mini-Fernbedienung",
      "sensor.type.1049": "CO-Melder",
      "sensor.type.1050": "Panikknopf",
      "sensor.type.1051": "Temperatursensor mit Display und Sollwertverstellung",
      "sensor.type.1052": "Heizkörperthermostat inkl. Temperaturmessung",
      "sensor.type.1053": "Heizungsthermostat mit Display und Sollwertverstellung",
      "sensor.type.1054": "Funksteckdose",
      "sensor.type.1055": "Temperatursensor",
      "sensor.type.1056": "2 in 1 Feuchtigkeits- und Temperatursensor",
      "sensor.type.1058": "Wassermelder",
      "sensor.type.1059": "Thermostat mit Display",
      "sensor.type.1060": "Türsensor",
      "sensor.type.1061": "Relay Switch",
      "sensor.type.1062": "Schalter Single Switch 2",
      "sensor.type.1063": "Color Strip",
      "sensor.type.1065": "Panic Button",
      "sensor.type.1066": "Smart Plug-in switch",
      "sensor.type.1067": "Fernbedienung mit einem Knopf",
      "sensor.type.1068": "Schalter Double Switch",
      "sensor.type.1069": "Wassersensor",
      "sensor.type.1070": "3-Phase Smart Meter",
      "sensor.type.1071": "Smart Meter",
      "sensor.type.1072": "Smart Implant",
      "sensor.type.1073": "Schimmelsensor",
      "sensor.type.1074": "Türsensor",
      "sensor.type.1075": "Thermostat",
      "sensor.type.1076": "Wassersensor (2)",
      "sensor.type.1077": "LED-Lampe",
      "sensor.type.1078": "Funksteckdose",
      "sensor.type.1079": "Eurotronic Air Quality Sensor",
      "sensor.type.1080": "Popp Z-Rain",
      "sensor.type.1081": "Popp Z-Weather",
      "sensor.type.1082": "Climax/Coqon Light Sensor",
      "sensor.type.11000": "Bilderahmen mit Standfuß",
      "sensor.type.12000": "Tablet",
      "sensor.type.1255": "Sensor unbekannt, bitte beim Support melden",
      "sensor.type.13001": "Easywave Door Window",
      "sensor.type.13002": "Easywave Med Box",
      "sensor.type.13003": "Easywave One Key Controller",
      "sensor.type.13004": "Easywave Motion Detector",
      "sensor.type.13005": "Easywave On Off Switch",
      "sensor.type.13006": "Easywave Bed Occupancy",
      "sensor.type.13007": "Easywave Bed Moisture",
      "sensor.type.13008": "Easywave Bed Breaks",
      "sensor.type.13009": "Easywave Bed Height",
      "sensor.type.13010": "Easywave Bed Rails",
      "sensor.type.13200": "Easywave Socket",
      "sensor.type.13201": "Easywave In Wall Actor",
      "sensor.type.14203": "WuT OUT",
      "sensor.type.14204": "WuT IN",
      "sensor.type.14210": "Funksteckdose",
      "sensor.type.15001": "OmniConnect Ort",
      "sensor.type.15002": "OmniConnect Objekt",
      "sensor.type.21": "Batterie der Zentraleinheit",
      "sensor.type.3": "Funktaster (Hilferuf-Knopf)",
      "sensor.type.4003": "Funktaster (GPS SOS)",
      "sensor.type.5000": "Taster",
      "sensor.type.5001": "Funk-Minirufsender",
      "sensor.type.5002": "Funk-Minirufsender",
      "sensor.type.5003": "Tür- und Fenstersensor",
      "sensor.type.5004": "Bewegungsmelder",
      "sensor.type.5005": "Funksteckdose",
      "sensor.type.5006": "Thermostat",
      "sensor.type.5007": "Schalter",
      "sensor.type.5008": "Schalter",
      "sensor.type.5009": "Dimmer",
      "sensor.type.5010": "Rollladen/Jalousie",
      "sensor.type.5011": "Bewegungsmelder",
      "sensor.type.5012": "Kartenschalter",
      "sensor.type.5013": "Feuchtigkeits- und Temperatursensor",
      "sensor.type.5014": "Wassermelder",
      "sensor.type.5015": "Bewegungsmelder AAL",
      "sensor.type.5016": "Bewegungs-Helligkeitssensor",
      "sensor.type.5017": "Thermostat",
      "sensor.type.5018": "Fenstergriff",
      "sensor.type.5019": "Fenstergriff mit Kipperkennung",
      "sensor.type.5020": "Feuchtigkeits- und Temperatursensor",
      "sensor.type.5021": "Luftgütesensor",
      "sensor.type.5023": "Virtuelles Raumthermostat",
      "sensor.type.5024": "Bewegungs-Helligkeitssensor",
      "sensor.type.6006": "Bettbelegungs- und Feuchtesensor",
      "sensor.type.7004": "Rauchmelder",
      "sensor.type.7005": "Wasserzähler",
      "sensor.type.7006": "Wärmezähler",
      "sensor.type.7007": "Wasserzähler",
      "sensor.type.7008": "Wasserzähler",
      "sensor.type.7009": "Wärmezähler",
      "sensor.type.7011": "Temperatur/Feuchtigkeit",
      "sensor.type.7012": "Temperatur",
      "sensor.type.7013": "Temperatur/Feuchtigkeit",
      "sensor.type.8000": "Eldat Social Alarm Button",
      "sensor.type.placeholder": "z.B. Wohnzimmertür",
      "sent": "erfolgreich gesendet",
      "shipping.settings": "Versandeinstellungen",
      "situation.delay.1": "Es soll eine Aktion erfolgen, wenn die Tür ungewöhnlich lange geöffnet ist, das heißt länger als",
      "situation.delay.16": "Es soll eine Aktion erfolgen, wenn das Fenster ungewöhnlich lange geöffnet ist, das heißt länger als",
      "situation.delay.18": "Es soll eine Aktion erfolgen, wenn gleichzeitig ein Elektrogerät (z.B. der Herd) eingeschaltet und die Tür geöffnet ist, und zwar länger als",
      "situation.delay.19": "Es soll eine Aktion erfolgen, wenn ein Elektrogerät (z.B. der Herd) eingeschaltet ist und keine Aktivität mehr festgestellt wird für länger als",
      "situation.delay.2": "Es soll eine Aktion erfolgen, wenn ein Elektrogerät (z.B. der Herd) ungewöhnlich lange eingeschaltet ist, das heißt länger als",
      "situation.delay.21": "Abschaltung bei fehlender Aktivität länger als",
      "situation.delay.27": "Es soll eine Aktion erfolgen, wenn die Zentraleinheit länger im Batteriebetrieb ist als",
      "situation.delay.31": "Es soll eine Aktion erfolgen, wenn die Kühlschranktür ungewöhnlich lange offen steht, das heißt länger als",
      "situation.delay.34": "Es soll eine Aktion erfolgen, wenn die Kühlschranktür ungewöhnlich lange nicht geöffnet wird, das heißt länger als",
      "situation.delay.38": "Nach welcher Verzögerung sollte Licht / der Aktor ausgeschaltet werden, wenn keine Bewegung mehr festgestellt wird?",
      "situation.delay.4": "Es soll eine Aktion erfolgen, wenn ungewöhnlich lange keine Aktivität registriert wird, das heißt länger als",
      "situation.delay.65": "Einstellung der Temperatur bei keiner Aktivität länger als",
      "situation.delay.68": "Es soll eine Aktion erfolgen, wenn eine Temperatur über 58°C gemessen wird, und zwar für länger als",
      "situation.hint.103": "Diese Situation ermöglicht eine regelmäßige und schaltungsabhängige Prüfung der Wasserabsperrventile auf manuelle Arretierung. Hierzu kann ein Spannungs-Schwellwert pro Sensor gesetzt werden, dessen Überschreitung beim Arretierungstest zu einem Alarm führt.",
      "situation.hint.11": "Die Zentraleinheit gilt als \"Offline\" wenn sie länger als 15 Minuten keine Verbindung zum Rechenzentrum aufbauen konnte.",
      "situation.hint.21": "Bitte beachten Sie, dass erst die Auswahl mehrerer Sensoren sicherstellt, dass die Situationserkennung zuverlässig funktioniert.",
      "situation.hint.70": "Es soll eine Aktion erfolgen, wenn Sensoren verschoben oder entfernt werden.",
      "situation.hint.90": "Bei Fensteröffnung werden ausgewählten Thermostate abgeschaltet. Wenn alle zugeordneten Fenster wieder geschlossen sind, wird entweder der Wert des aktuellen Zeitschaltplans benutzt, oder der letzte bekannte Wert vor der Öffnung.",
      "situation.type.1": "Die Wohnungstür steht ungewöhnlich lange offen.\n",
      "situation.type.10": "Es entwickelt sich Rauch in der Wohnung.",
      "situation.type.100": "Der SOS-Knopf wurde gedrückt.",
      "situation.type.101": "Die angegebene Geschwindigkeit wurde überschritten.",
      "situation.type.102": "Die angegebene Entfernung wurde überschritten.",
      "situation.type.103": "Ventilsteuerung Arretierungserkennung",
      "situation.type.106": "Thermostat Ist-Temperatur Verknüpfung",
      "situation.type.11": "Die Zentraleinheit meldet sich seit längerer Zeit nicht mehr beim Rechenzentrum.",
      "situation.type.12": "Die Laufzeit Ihrer Zentraleinheit endet bald.",
      "situation.type.13": "Brennbares Gas tritt aus.",
      "situation.type.14": "Es läuft Wasser aus oder über.",
      "situation.type.15": "Kohlenstoffmonoxid tritt aus.",
      "situation.type.16": "Ein Fenster steht ungewöhnlich lange offen.",
      "situation.type.17": "Das Bett wird ungewöhnlich lange nicht benutzt.",
      "situation.type.18": "Ein Elektrogerät ist angeschaltet und die Haus-/Wohnungstür wird geöffnet.",
      "situation.type.19": "Ein Elektrogerät ist angeschaltet beim Verlassen der Wohnung.",
      "situation.type.2": "Ein Elektrogerät ist ungewöhnlich lange eingeschaltet.\n",
      "situation.type.20": "Ein Elektrogerät ist angeschaltet, während das Bett benutzt wird.",
      "situation.type.21": "Ausgewählte Elektrogeräte sollen abgeschaltet werden, wenn keine Bewegung innerhalb der Wohnung erkannt wird.",
      "situation.type.22": "Ausgewählte Elektrogeräte sollen abgeschaltet werden, wenn das Bett benutzt wird.",
      "situation.type.23": "Ausgewählte Elektrogeräte sollen zu einer bestimmten Tageszeit abgeschaltet werden (täglich).",
      "situation.type.24": "Ausgewählte Elektrogeräte sollen mit dem \"Alles-aus\"-Taster abgeschaltet werden.",
      "situation.type.25": "Ausgewählte Elektrogeräte sollen mit einem Schalter ein- und ausgeschaltet werden.",
      "situation.type.26": "In Abwesenheit des Bewohners wird Aktivität in der Wohnung festgestellt.",
      "situation.type.27": "Die Zentraleinheit ist im Batteriebetrieb.",
      "situation.type.31": "Die Kühlschranktür steht ungewöhnlich lange offen.",
      "situation.type.33": "Die Wohnung wird zu ungewöhnlichen Zeiten (z.B. nachts) verlassen.",
      "situation.type.34": "Ein Öffnungskontakt wurde im angegebenen Zeitraum nicht benutzt.",
      "situation.type.35": "Der Bewohner benutzt Elektrogeräte zu ungewöhnlichen Zeiten.",
      "situation.type.36": "Der Bewohner verlässt nachts das Bett und kehrt innerhalb einer bestimmten Zeit nicht zurück.",
      "situation.type.38": "Schaltung eines Aktors (z.B. Licht) bei Bewegung.",
      "situation.type.39": "Ausgewählte Elektrogeräte sollen abgeschaltet werden, wenn die Wohnung verlassen und keine Aktivität mehr erkannt wird.",
      "situation.type.4": "Es ist ungewöhnlich lange keine Aktivität festgestellt worden.\n",
      "situation.type.45": "Ein Aktor wurde im angegebenen Zeitraum nicht benutzt.",
      "situation.type.46": "Thermostateinstellung zu einer bestimmten Uhrzeit (täglich).",
      "situation.type.5": "Das Bett wird ungewöhnlich lange benutzt.\n",
      "situation.type.50": "Der Blutdruck über-/unterschreitet die angegebenen Grenzwerte.",
      "situation.type.52": "Der Puls über-/unterschreitet den angegebenen Grenzwert.",
      "situation.type.54": "Der Blutzuckerwert über-/unterschreitet den angegebenen Grenzwert.",
      "situation.type.56": "Das Gewicht über-/unterschreitet den angegebenen Grenzwert.",
      "situation.type.58": "Ein Sensor hat einen zu niedrigen Ladestand seines Akkus bzw. seiner Batterie.",
      "situation.type.59": "Der Bewohner wird an die Messung des Blutdruckes erinnert.",
      "situation.type.6": "Die Medikamenteneinnahme ist überfällig.\n",
      "situation.type.60": "Der Bewohner wird an die Messung des Pulses erinnert.",
      "situation.type.61": "Der Bewohner wird an die Blutzuckermessung erinnert.",
      "situation.type.62": "Der Bewohner wird an die Messung des Gewichtes erinnert.",
      "situation.type.63": "Thermostateinstellung bei Benutzung des Bettes.",
      "situation.type.64": "Thermostateinstellung beim Verlassen der Wohnung.",
      "situation.type.65": "Thermostateinstellung wenn keine Aktivität in der Wohnung festgestellt wird.",
      "situation.type.66": "Anzeige der Sensorübersicht auf der Zentraleinheit.",
      "situation.type.68": "Eine Temperatur über 58°C wurde registriert.",
      "situation.type.7": "Ein Hilferuf wird ausgelöst.\n",
      "situation.type.70": "Ein Sensor wurde entfernt oder verschoben.",
      "situation.type.71": "Aktive Geräte beim Verlassen der Wohnung",
      "situation.type.72": "Ein Sensor hat sich länger als 36 h nicht mehr gemeldet.",
      "situation.type.8": "In der Wohnung ist es ungewöhnlich heiß.",
      "situation.type.81": "Der Bewohner hat das Haus verlassen oder ist wiedergekehrt.",
      "situation.type.82": "Die angegebene Feuchtigkeitsgrenze wurde unter-/überschritten.",
      "situation.type.83": "Die angegebene Feuchtigkeitsgrenze wurde unter-/überschritten.",
      "situation.type.84": "Thermostat schaltet andere Thermostate.",
      "situation.type.85": "Die Temperaturen für Komfort(Anhebung) & Nacht(Absenkung) Modi einstellen.",
      "situation.type.88": "Die Konzentration flüchtiger organischer Verbindungen in der Luft ist zu hoch.",
      "situation.type.89": "Das Tablet hat sich länger als 30 min nicht mehr gemeldet.",
      "situation.type.90": "Temperaturregelung beim Lüften",
      "situation.type.93": "Einstellung von Thermostaten oder Ab-/Anschaltung elektrischer Geräte beim Betreten der Wohnung.",
      "situation.type.94": "Die angegebene Temperaturgrenze wurde unter-/überschritten.",
      "situation.type.95": "Die angegebene Temperaturgrenze wurde unter-/überschritten.",
      "situation.type.96": "Ein Sturz wurde erkannt.",
      "situation.type.97": "Ein Aktor wurde im angegebenen Zeitraum nicht benutzt.",
      "situation.type.configured": "Konfigurierte Situation",
      "situation.type.initial": "Bitte fügen Sie unter \"Kontakte\" einen Kontakt hinzu, um Situationen konfigurieren zu können.",
      "situation.type.list_header.affected": "Betroffene Sensoren",
      "situation.type.title": "Konfigurierbare Situationen",
      "time": "Uhrzeit",
      "title": "Titel",
      "title-bar.date": "Datum",
      "title-bar.duration": "Dauer",
      "title-bar.sender": "Absender",
      "title-bar.tile": "Titel",
      "title-required": "Titel wird benötigt",
      "to": "Bis",
      "tooltip.clock": "Wird am \"{{clock}}\" versendet",
      "tooltip.delete": "Entfernen",
      "tooltip.forward": "Weiter zur WebApp",
      "tooltip.hubs": "Betreffende Zentraleinheiten",
      "tooltip.information": "Weitere Informationen",
      "tooltip.loop": "Wird \"{{loop}}\" wiederholt",
      "username": "Benutzername",
      "voice.archive": "Sprachnachrichten-Archiv",
      "voice.description": "In diesem Bereich können Sprachnachrichten aufgenommen und versendet werden sowie bereits versendete und erhaltene Sprachnachrichten eingesehen werden.",
      "voice.message.send": "Sprachnachricht versenden",
      "voice.messages.create.select.file.please": "Bitte nehmen Sie ein Audio auf.",
      "voice.messages.created": "Sprachnachricht wurde versendet",
      "voice.send": "Sprachnachricht versenden",
      "voice.title": "Sprachnachrichten",
      "wifi": "Wlan",
      "wifi.error.start": "Wlan konnte nicht eingeschaltet werden. Bitte probieren Sie es erneut.",
      "wifi.ip": "IP Addresse",
      "wifi.ip.unknown": "unbekannte IP Adresse",
      "wifi.noconnection": "Keine Verbindung",
      "wifi.noconnection.content": "Wlan ist eingeschaltet aber hat keine Verbindung.",
      "wifi.off": "Wlan ist ausgeschaltet",
      "wifi.signalquality": "Signalqualität",
      "wifi.ssid": "SSID",
      "wifi.turnon": "Wlan einschalten",
      "wrong-password": "Das Passwort ist inkorrekt.",
      "wrong-user": "Der Benutzer \"{{username}}\" wurde nicht gefunden."
   },
   "nl": {
      "Alertsound.1": "Ihre Wohnungstuer steht offen,Bitte schliessen Sie die Tuer,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.2": "Ihr Herd ist noch eingeschaltet,Bitte schalten Sie den Herd ab,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.3": "Ihr Wasserhahn laeuft schon sehr lange,Bitte schliessen Sie den Wasserhahn,Oder,beruehren Sie bitte den Bildschirm",
      "Alertsound.4": "Achtung, Seit geraumer Zeit wird in Ihrer Wohnung keinerlei Aktivitaet mehr erkannt,Ist bei Ihnen alles in Ordnung,Falls ja.beruehren Sie bitte den Bildschirm",
      "affected.hubs": "Betrokken centrale eenheden",
      "alert.name": "Titel",
      "alert.name.required": "Titel is vereist",
      "alerts.applied": "De wijzigingen werden aangenomen",
      "alerts.archive": "Alarm archief",
      "alerts.created": "Alarm werd gemaakt",
      "alerts.description": "In dit gebied kunnen meldingen naar centrale eenheden worden gezonden en kunnen eerder verzonden en ontvangen meldingen worden bekeken.",
      "alerts.not.repeat": "Niet herhalen",
      "alerts.reset": "Reset",
      "alerts.send": "Stuur alarm",
      "alerts.send.daily": "Elke dag",
      "alerts.send.monthly": "Elke maand",
      "alerts.send.now": "Onmiddellijk verzenden",
      "alerts.send.weekly": "Elke week",
      "alerts.send.yearly": "Elk jaar",
      "alerts.sent": "Alarm werd verzonden",
      "alerts.title": "Alarmen",
      "app.accept": "Aannemen",
      "app.refresh": "Aktualisiere...",
      "app.reject": "Afwijzen",
      "app_version": "Vereist TabletApp versie 3.3.6",
      "apply": "Overnemen",
      "available": "beschikbaar",
      "bluetooth.nosensors.description": "Es befinden sich keine aktiven Bluetooth Geräte in der Nähe",
      "bluetooth.nosensors.title": "Keine Bluetooth Sensoren gefunden",
      "bluetooth.search": "Die App sucht nach verfügbaren Bluetooth Geräten",
      "button.about": "Über",
      "button.connect": "Verbinden",
      "button.deleteconnection": "Verbindung löschen",
      "button.info": "Informationen",
      "button.refresh": "aktualisieren",
      "button.retry": "Erneut probieren",
      "button.search": "Suche starten",
      "button.sensordata": "Sensordaten",
      "button.system": "System",
      "cancel": "Annuleren",
      "casenio.cockpit": "Cockpit",
      "check-input": "Controleer uw inzendingen",
      "content": "Inhoud",
      "content-required": "Voer de inhoud in",
      "delete": "Delete",
      "deleted": "met succes verwijderd",
      "description": "Beschrijving",
      "description-required": "Geef een beschrijving",
      "dispatch.time.set": "Stel verzendingstijd in",
      "docs-create.select.file": "Selecteer bestand",
      "docs-create.select.file.please": "Kies een document.",
      "docs-create.select.oem": "Selecteer OEM",
      "docs-create.select.oem.none": "OEM's konden niet worden geladen.",
      "docs.archive": "Handleidingen bekijken",
      "docs.create": "Maak handleiding",
      "docs.create.title": "Benaming",
      "docs.create.title.required": "Aanwijzing is vereist",
      "docs.description": "In dit gedeelte kunnen handleidingen worden gemaakt en bekeken.",
      "docs.overview": "Naar het overzicht",
      "docs.title": "Handleidingen",
      "error": "Fehler",
      "error.alertremoval": "Alarm konnte nicht gelöscht werden.",
      "error.connection": "Verbindungsfehler",
      "error.notavailable": "nicht verfügbar",
      "error.read": "Fehler beim Vorlesen",
      "error.read.text": "Der Text konnte leider nicht vorgelesen werden. Bitte erneut probieren.",
      "error.sensorconnection": "Sensor konnte nicht verbunden werden. Bitte probieren Sie es erneut.",
      "exclusion": "Exklusion",
      "exclusion.0.description": "Bitte warten und die folgenden Anweisungen beachten.",
      "exclusion.0.title": "Exklusion wird gestartet.",
      "exclusion.1.description": "Bitte jetzt den Sensor ablernen.",
      "exclusion.1.title": "Exklusion aktiv.",
      "exclusion.2.description": "Bitte warten Sie während der Vorgang beendet wird.",
      "exclusion.2.title": "Exklusion wird abgeschlossen",
      "exclusion.3.description": "Das Anlernen eines neues Sensors war erfolgreich.\n Bitte wählen Sie einen Namen für den Sensor.",
      "exclusion.3.title": "Bitte benennen Sie den Sensor",
      "exclusion.4.description": "Der Sensor wurde erfolgreich entfernt.",
      "exclusion.4.title": "Exklusion abgeschlossen.",
      "exclusion.5.description": "Es ist leider ein Fehler aufgetreten,\nversuchen Sie es bitte erneut oder kontaktieren Sie den Support.",
      "exclusion.5.title": "Exklusion fehlgeschlagen.",
      "exclusion.6.description": "Das Zeitlimit wurde überschritten. Bitte versuchen Sie es erneut\noder kontaktieren Sie den Support.",
      "exclusion.6.title": "Zeitlimit überschritten.",
      "exclusion.confirm": "Bitte bestätigen Sie die Exklusion des Sensors",
      "exclusion.confirm.hint": "Der Sensor wird sofort gelöscht und muss vor Benutzung erneut angelernt werden.",
      "exclusion.deleting": "Sensor wird gelöscht",
      "exclusion.remove": "Sensoren entfernen",
      "exclusion.select.sensor": "Bitte den zu exkludierenden Sensor auswählen",
      "feature.1": "Lufttemperatur (Istwert)",
      "feature.1.value": "{waarde} °C",
      "feature.10000": "Zentraleinheit",
      "feature.101": "Rauch (Alarm)",
      "feature.101.0": "Rook herkend",
      "feature.101.1": "Kein Rauch erkannt",
      "feature.105": "Wasserleckage (Alarm)",
      "feature.105.0": "Waterlekkage herkend",
      "feature.105.1": "Waterlekkage beëindigd",
      "feature.106": "Brennbares Gas (Alarm)",
      "feature.106.0": "Brandbaar gas herkend",
      "feature.108": "Hinderniserkennung",
      "feature.108.1": "Hindernis erkannt",
      "feature.109": "Abdeckung",
      "feature.109.1": "Sensor verdeckt",
      "feature.110": "Abstand zum Hindernis",
      "feature.110.1": "{value} m",
      "feature.200": "Stromzähler (Meter)",
      "feature.200.value": "{waarde} kWh",
      "feature.2000": "Tag Sichtbarkeit",
      "feature.2000.0": "nicht sichtbar",
      "feature.2000.1": "sichtbar",
      "feature.2002": "Körperhaltung",
      "feature.2002.0": "liegen",
      "feature.2002.1": "sitzen",
      "feature.2002.2": "stehen",
      "feature.2002.unknown": "unklar",
      "feature.202": "Wasserzähler",
      "feature.202.value": "{value} m³",
      "feature.203": "Wärmezähler",
      "feature.203.value": "{value} Wärmeeinheiten",
      "feature.206": "Stichtag",
      "feature.206.value": "{value}",
      "feature.211": "Wasserverbrauch",
      "feature.211.value": "{value} m3",
      "feature.2325": "Trinkgefäß-Nutzung",
      "feature.2326": "Essbesteck/Geschirr-Nutzung",
      "feature.3": "Umgebungshelligkeit",
      "feature.3.value": "{waarde} %",
      "feature.300": "Öffnungskontakt (Tür / Fenster)",
      "feature.300.0": "Geopend",
      "feature.300.1": "Gesloten",
      "feature.301": "Schalter",
      "feature.301.value": "schakelaar werd ingedrukt",
      "feature.302": "Schaltbares Gerät",
      "feature.302.0": "Uitgeschakeld",
      "feature.302.1": "Ingeschakeld",
      "feature.303": "Lampe",
      "feature.303.0": "Uitgeschakeld",
      "feature.303.1": "Ingeschakeld",
      "feature.304": "Farbe",
      "feature.304.0": "Uitgeschakeld",
      "feature.304.1": "Ingeschakeld",
      "feature.305": "Bewegung",
      "feature.305.0": "Beweging geregistreerd",
      "feature.305.1": "Beweging geregistreerd",
      "feature.306": "Medikationssensor",
      "feature.307": "Gasdetektion",
      "feature.307.0": "Gas herkend",
      "feature.308": "Wasserfluss",
      "feature.309": "Belegung (Bett)",
      "feature.309.0": "Bed niet belegen",
      "feature.309.1": "Bed belegen",
      "feature.31": "Nässe (Bett)",
      "feature.31.0": "nat",
      "feature.31.1": "droog",
      "feature.31.2": "droog",
      "feature.310": "Elektro-Verbrauchserkennung",
      "feature.310.0": "Apparaat verbruikt geen stroom",
      "feature.310.1": "Apparaat verbruikt stroom",
      "feature.311": "Eingestellte Temperatur (Sollwert)",
      "feature.311.1": "erkannt",
      "feature.311.value": "{waarde} °C",
      "feature.312": "Uhrzeit",
      "feature.312.value": "{waarde}",
      "feature.313": "Funktionssperre",
      "feature.313.0": "Schakelbaar apparaat geblokkeerd",
      "feature.313.1": "Schakelbaar apparaat vrijgegeven",
      "feature.314": "Leuchtstärkeregelung",
      "feature.314.value": "{waarde} W",
      "feature.315": "Manipulationswarnung",
      "feature.315.0": "Behuizing geopend of sensor afgemonteerd",
      "feature.315.1": "Behuizing gesloten of sensor gemonteerd",
      "feature.316": "Alarmsignalisierung",
      "feature.316.0": "Alarmsignalering uitgeschakeld",
      "feature.316.1": "Alarmsignalering ingeschakeld",
      "feature.317": "Hahnsteuerung",
      "feature.317.0": "Afsluitkraan geblokkeerd",
      "feature.317.1": "Afsluitkraan geopend",
      "feature.320": "GPS Tracking",
      "feature.323": "Ventilarretierung",
      "feature.323.0": "vrij",
      "feature.323.1": "vergrendeld",
      "feature.323.2": "geen info",
      "feature.324": "Fenstergriffstellung",
      "feature.324.0": "gesloten",
      "feature.324.1": "geopendt",
      "feature.324.2": "gekanteld",
      "feature.325": "Ist-Temperatur Einlieferung",
      "feature.325.value": "{value} °C",
      "feature.331": "Umgebungsänderung",
      "feature.333": "Sensor manipuliert Zähler",
      "feature.333.value": "{value}",
      "feature.334": "Gesammte Manipulationsdauer",
      "feature.334.value": "{value} min",
      "feature.335": "Datum der letzten Manipulation",
      "feature.335.value": "{value}",
      "feature.338": "Türklingel",
      "feature.338.1": "es klingelt",
      "feature.39": "Luftgüte",
      "feature.39.0": "schlechte Luftqualität",
      "feature.39.1": "mittlere Luftqualität",
      "feature.39.2": "gute Luftqualität",
      "feature.39.bad": "slechte luchtkwaliteitt",
      "feature.39.good": "goede Luchtkwaliteit",
      "feature.39.middle": "gemiddelde luchtkwaliteit",
      "feature.4": "Leistung",
      "feature.4.value": "{waarde} W",
      "feature.5": "Luftfeuchtigkeit",
      "feature.5.value": "{waarde} %",
      "feature.501": "Energiegewinnung",
      "feature.501.0": "Inactief",
      "feature.501.1": "Actief",
      "feature.504": "Ventilstellung",
      "feature.504.value": "{waarde} %",
      "feature.505": "Temperatursturzerkennung",
      "feature.505.0": "Normale belasting",
      "feature.505.1": "herkend",
      "feature.506": "Jalousieposition",
      "feature.506.value": "{value} % gesloten",
      "feature.58": "GPS SOS",
      "feature.59": "GPS-Entfernung",
      "feature.6": "Geschwindigkeit",
      "feature.6.value": "{waarde} km/h",
      "feature.60": "Bett Bremse gelöst",
      "feature.600": "System",
      "feature.601": "Bildschirm",
      "feature.601.0": "Touch herkend",
      "feature.602": "Batterie",
      "feature.602.255": "Batterie wechseln",
      "feature.602.value": "{waarde} %",
      "feature.603": "Empfangsqualität",
      "feature.603.1": "slecht",
      "feature.603.2": "goed",
      "feature.603.3": "zeer goed",
      "feature.606": "WVM De-/Aktivierung",
      "feature.61": "Bett Höhenänderung",
      "feature.614": "Letzter Batteriewechsel",
      "feature.614.value": "Batterij gewisseld",
      "feature.619": "Batteriemodus",
      "feature.619.0": "Stecker gezogen",
      "feature.619.1": "wird geladen",
      "feature.619.2": "Voll geladen",
      "feature.62": "Bettgitter Änderung",
      "feature.620": "Sensor-Sender-Verbindung",
      "feature.620.0": "gescheiden",
      "feature.620.1": "verbonden",
      "feature.621": "Testdatum Knopf",
      "feature.621.value": "{value}",
      "feature.622": "Baldiges Lebensende",
      "feature.622.1": "gemeldet",
      "feature.623": "Echolot Testdatum",
      "feature.623.value": "{value}",
      "feature.624": "Staubniveau",
      "feature.624.value": "{value} %",
      "feature.625": "Alarmdatum",
      "feature.625.value": "{value}",
      "feature.626": "Testknopf Zähler",
      "feature.626.value": "{value}",
      "feature.65": "Volumenstrom",
      "feature.65.value": "{value} m3h",
      "feature.66": "Energieverbrauch",
      "feature.66.value": "{value} kWh",
      "feature.67": "Vorlauftemperatur",
      "feature.67.value": "{value} °C",
      "feature.68": "Rücklauftemperatur",
      "feature.68.value": "{value} °C",
      "feature.69": "Beleuchtungsstärke",
      "feature.69.value": "{value} Lux",
      "feature.70": "Wärmeverbrauch",
      "feature.70.value": "{value}",
      "feature.71": "Wert am Stichtag\n",
      "feature.71.value": "{value}",
      "feature.72": "Energievernrauch Vormonat",
      "feature.72.value": "{value} kWh",
      "feature.803": "Hilferuf am Tablet",
      "feature.833": "Tablet Verbindung zum Server\n",
      "feature.833.0": "Offline",
      "feature.833.1": "Online",
      "feature.834": "Unbekanntes Feld CL",
      "feature.834.1": "gemeldet",
      "feature.835": "Unbekannter Eintrag",
      "feature.835.1": "gemeldet",
      "feature.836": "Zugangsberechtigung",
      "feature.836.1": "gemeldet",
      "feature.837": "Eintragsgrösse",
      "feature.837.1": "Fehler gemeldet",
      "feature.838": "Eintragswerte",
      "feature.838.1": "Fehler gemeldet",
      "feature.839": "Falsches Password",
      "feature.839.1": "gemeldet",
      "feature.840": "Installation beended",
      "feature.840.1": "gemeldet",
      "feature.841": "Echolot Fehler",
      "feature.841.1": "gemeldet",
      "feature.842": "Kommunikationsfehler",
      "feature.843": "Scan-Fehler",
      "feature.843.1": "gemeldet",
      "feature.844": "Sensorfehler",
      "feature.844.1": "gemeldet",
      "feature.845": "SEODS-Fehler",
      "feature.845.1": "gemeldet",
      "feature.846": "Lebensende",
      "feature.846.1": "gemeldet",
      "feature.847": "Status",
      "feature.847.0": "OK",
      "feature.847.1": "Problem",
      "feature.848": "Unbekanntes Feld C",
      "feature.848.1": "gemeldet",
      "feature.849": "Update der Config",
      "feature.849.1": "bekommen",
      "feature.849.2": "geholt",
      "feature.9000": "X-position",
      "feature.9001": "Y-Position",
      "feature.9002": "Z-Position",
      "feature.9003": "X1-Position",
      "feature.9004": "X2-Position",
      "feature.9005": "Y1-Position",
      "feature.9006": "Y2-Position",
      "feature.9007": "Z1-Position",
      "feature.9008": "Z2-Position",
      "feature.ismeter": "{value} m",
      "from": "Van",
      "hubs.description": "Dit gebied bevat de lijst van alle toegankelijke centrale eenheden met de mogelijkheid om in te loggen op het setup-portaal.",
      "hubs.overview": "Naar het overzicht",
      "hubs.select.missing": "Geen centrale huiseenheden beschikbaar",
      "hubs.select.placeholder": "Toewijzen huis bedieningspanelen",
      "hubs.selector.no-options": "Geen centrale eenheden gevonden",
      "hubs.selector.placeholder": "Filter op Centrale Eenheid",
      "hubs.title": "Centrale eenheden",
      "images-create.select.file": "Selecteer afbeelding",
      "images.archive": "Afbeeldingen archief",
      "images.big-file": "Het geselecteerde bestand mag niet groter zijn dan {size}MB.",
      "images.create.select.file.please": "Kies een foto.",
      "images.date": "Verzonden op  ",
      "images.description": "In dit gebied kunnen beelden naar centrale eenheden worden gezonden en kunnen reeds verzonden en ontvangen beelden worden bekeken.",
      "images.invalid-file": "Het geselecteerde bestandsformaat is niet toegestaan.",
      "images.send": "Stuur afbeelding",
      "images.sender": "Afzender",
      "images.sent": "De foto is verstuurd.",
      "images.show.all": "alles laten zien",
      "images.tile": "Titel",
      "images.title": "Afbeeldingen",
      "inclusion": "Inklusion",
      "inclusion.0.description": "Bitte warten und die folgenden Anweisungen beachten.",
      "inclusion.0.title": "Inklusion wird gestartet.",
      "inclusion.1.description": "Bitte jetzt den Sensor anlernen.",
      "inclusion.1.title": "Inklusion aktiv.",
      "inclusion.2.description": "Bitte warten Sie während der Vorgang beendet wird.",
      "inclusion.2.title": "Inklusion wird abgeschlossen",
      "inclusion.3.description": "Das Anlernen eines neues Sensors war erfolgreich.\n Bitte wählen Sie einen Namen für den Sensor.",
      "inclusion.3.title": "Bitte benennen Sie den Sensor",
      "inclusion.4.description": "Der Sensor wurde erfolgreich zu Ihrem System\nhinzugefügt und kann nun verwendet werden.",
      "inclusion.4.title": "Inklusion abgeschlossen.",
      "inclusion.5.description": "Es ist leider ein Fehler aufgetreten,\nversuchen Sie es bitte erneut oder kontaktieren Sie den Support.",
      "inclusion.5.title": "Inklusion fehlgeschlagen.",
      "inclusion.6.description": "Das Zeitlimit wurde überschritten. Bitte versuchen Sie es erneut\noder kontaktieren Sie den Support.",
      "inclusion.6.title": "Zeitlimit überschritten.",
      "inclusion.add": "Sensoren hinzufügen",
      "inclusion.addremove": "Hinzufügen und entfernen von Sensoren",
      "inclusion.alreadyincluded": "Gerät bereits gekoppelt",
      "inclusion.bluetooth": "Inklusion eines Bluetooth Gerätes",
      "inclusion.bluetooth.error": "Bluetooth muss eingeschaltet sein um neue Geräte zu Koppeln.\\n Bitte wiederholen Sie die Suche und erlauben die Anfrage",
      "inclusion.changeconnection": "Verbindungstyp Ändern",
      "inclusion.changemode": "Wechsel zwischen AP und WIFI Modus",
      "inclusion.exclusion": "In-/Exklusion",
      "inclusion.fail": "Sensor konnte nicht erstellt werden. Bitte probieren Sie es erneut.",
      "inclusion.name": "Neuer Sensorname",
      "inclusion.select": "Bitte einen Sensortyp aus der Liste auswählen",
      "inclusion.select.action": "Was möchten Sie tun?",
      "inclusion.start": "Bitte das zu inkludierende Gerät aktivieren und die Suche starten",
      "inclusion.success": "Der Sensor wurde erfolgreich angelernt",
      "inclusion.technology": "Um welche Sensortechnologie geht es ?",
      "inclusion.tooshort": "Muss mindestens 3 chars sein",
      "input-password": "Voer uw wachtwoord in.",
      "input-username": "Voer gebruikersnaam in",
      "input.area.chars.left": "{{chars}} von {{max}} Zeichen übrig",
      "input.area.chars.max": "Max.{{max}} Zeichen",
      "load.more": "Verdere resultaten laden",
      "logout": "Logout",
      "message.attention": "Achtung",
      "message.confirm": "Wenn Sie fortfahren möchten, klicken Sie auf Bestätigen, anderfalls klicken Sie auf Abbrechen",
      "message.connected": "Verbunden",
      "message.creating": "wird erstellt...",
      "message.current": "aktuell",
      "message.from": "Gesendet von",
      "message.irrevocable": "Diese Aktion kann nicht rückgängig gemacht werden!",
      "message.wait": "Das kann einige Sekunden dauern",
      "news-create.content-or-file": "Voer de inhoud van het bericht in of selecteer een bestand.",
      "news-create.select.file": "Selecteer bestand",
      "news.archive": "Nieuws archief",
      "news.big-file": "Het geselecteerde bestand mag niet groter zijn dan {size}MB.",
      "news.delete": "Sie sind dabei die Nachricht zu löschen.",
      "news.description": "In dit gebied kunnen berichten met of zonder bijlagen (afbeelding, PDF) naar centrale eenheden worden gezonden en kunnen reeds verzonden en ontvangen berichten en hun inhoud worden bekeken.",
      "news.invalid-file": "Het geselecteerde bestandsformaat is niet toegestaan.",
      "news.notdeleted": "Die Nachricht konnte nicht gelöscht werden.",
      "news.send": "Bericht verzenden",
      "news.sent": "Bericht werd verzonden",
      "news.title": "Nieuws",
      "no_match.title": "Oh jee!",
      "none": "geen",
      "not-read": "Niet gelezen",
      "not_sent": "er is een fout opgetreden",
      "password": "Wachtwoord",
      "photos.nophotos": "Noch keine Bilder vorhanden",
      "properties.multi.incomplete": "Sommige eigenschappen zijn niet volledig geselecteerd",
      "properties.one.incomplete": "Een eigenschap is niet volledig geselecteerd",
      "properties.select.missing": "Geen eigendommen beschikbaar",
      "properties.select.placeholder": "Eigenschappen toewijzen",
      "read": "lees",
      "repetition.cycle.set": "Herhalingscyclus instellen",
      "reset.content": "Sie sind dabei die App zurückzusetzen. Alle Einstellungen und bekannte Verbindungen werden gelöscht. Anschließend muss die App erneut konfiguriert und mit dem Internet verbunden werden.",
      "reset.warning": "Ich bin mir bewusst, dass diese Aktion ein erneutes Einrichten der App (Initial Setup) erfordert.",
      "search.filter": "Nach Titel, Inhalt oder Absender filtern",
      "select-all": "Kies alle",
      "select-file.optional": "Selecteer bestand (optioneel)",
      "select-least-hub": "Kies ten minste één centrale eenheid.",
      "select.all": "selecteer alle",
      "send": "Stuur",
      "sender": "Afzender",
      "sender.create": "Afzender instellen",
      "sender.edit": "Afzendernaam wijzigen",
      "sender.info": "De afzender wordt weergegeven op alle alarmen, berichten en foto's die vanuit de cockpit worden verzonden.",
      "sender.invalid": "Voer een afzender in.",
      "sender.updated": "Afzender is bijgewerkt.",
      "sensor.date.add": "Hinzugefügt am: {date}",
      "sensor.meta.1002": "Elektro-Verbrauchserkennung:",
      "sensor.meta.1003": "Empfindlichkeit:\n",
      "sensor.meta.1006": "Arretierungstest nach Schließvorgang:",
      "sensor.meta.1008": "Spannungs-Schwellwert:",
      "sensor.meta.1011": "DIN-Anschluss:",
      "sensor.meta.27": "Der Sensor kann ausgeschaltet werden:",
      "sensor.meta.29": "Lampe",
      "sensor.meta.30": "Thermostat",
      "sensor.meta.31": "Schaltbares Gerät",
      "sensor.meta.43": "Die LED's sind aktiviert:",
      "sensor.meta.47": "Der Sensor wird in der Home-App angezeigt:",
      "sensor.meta.48": "Der Sensor wird im Bereich Raumkima angezeigt:",
      "sensor.meta.no": "NEIN",
      "sensor.meta.show_as": "Sensor anzeigen als:",
      "sensor.meta.yes": "JA",
      "sensor.state": "Sensorstatus",
      "sensor.status.0": "Sensor is verwijderd of nieuw",
      "sensor.status.2": "Sensor is inactief",
      "sensor.status.3": "Sensor is inactief, zij is wel geregistreerd maar niet inbegrepen (zwave)",
      "sensor.status.4": "Sensor is inactief, zij is wel inbegrepen, maar niet geregistreerd (zwave)",
      "sensor.status.5": "Sensor is actief maar reageert niet (zwave)",
      "sensor.status.6": "Sensor is niet geregistreerd en reageert niet (zwave)",
      "sensor.status.7": "Sensor is inactief en niet gekoppeld (bluetooth)",
      "sensor.status.8": "Sensor is inactief, maar wel gekoppeld (bluetooth)",
      "sensor.status.disabled": "Inactief",
      "sensor.status.enabled": "Actief",
      "sensor.status.unavailable": "Deze sensor kan vanwege onderstaande reden niet geselecteerd worden: '{reason}'",
      "sensor.type": "Sensortyp",
      "sensor.type.0": "Beeldscherm",
      "sensor.type.1000": "Meerkleurige LED-lamp (E27)",
      "sensor.type.10000": "Centrale",
      "sensor.type.10002": "Centrale V3",
      "sensor.type.10003": "Centrale V3 met alarmknop",
      "sensor.type.10004": "centrale",
      "sensor.type.1001": "Watermelder",
      "sensor.type.1002": "Rookmelder",
      "sensor.type.1003": "Draadloos stopcontact",
      "sensor.type.10032": "centrale V3.2",
      "sensor.type.10033": "centrale V3.2 met noodoproep",
      "sensor.type.10034": "centrale V3.2 met rookalarm",
      "sensor.type.1004": "Bewegingssensor",
      "sensor.type.1005": "Wandschakelaar WALLC-S",
      "sensor.type.1006": "Deur-/Raamsensor",
      "sensor.type.1008": "Bewegingssensor",
      "sensor.type.1009": "Rookmelder met bnnensirene",
      "sensor.type.101": "rookmelder",
      "sensor.type.1011": "Fornuis-/kookplaatsensor",
      "sensor.type.1012": "Multisensor",
      "sensor.type.1013": "Hittemelder",
      "sensor.type.1014": "LED-Lamp",
      "sensor.type.1015": "Thermostaat metLCD display",
      "sensor.type.1016": "Schakelaar (viervoudig)",
      "sensor.type.1017": "Tijdschakelaar",
      "sensor.type.1018": "Thermostaat (alleen radiografisch)",
      "sensor.type.1019": "3-in-1-Bewegingsmelder",
      "sensor.type.1020": "4 in 1 Bewegingsmelder en deursensor",
      "sensor.type.1021": "automatische kraanafsluiter",
      "sensor.type.1022": "Bewegingsmelder",
      "sensor.type.1023": "Bedliggingsensor",
      "sensor.type.1024": "Snoerschakelaar",
      "sensor.type.1025": "Wandschakelaar (viervoudig)",
      "sensor.type.1026": "Draadloos stopcontact",
      "sensor.type.1027": "Water- en bevriezingssensor",
      "sensor.type.1028": "Detector voor brandbare gassen",
      "sensor.type.1029": "Luchtvochtigheidssensor",
      "sensor.type.1030": "BedliggingSensor",
      "sensor.type.1031": "Draadloos stopcontact",
      "sensor.type.1032": "Draadloos stopcontact",
      "sensor.type.1033": "Draadloos stopcontact",
      "sensor.type.1034": "Draadloos stopcontact",
      "sensor.type.1035": "Bedliggingsensor",
      "sensor.type.1036": "Radiografische knop (Noodknop)",
      "sensor.type.1037": "Alarmsirene",
      "sensor.type.1038": "LED-Lamp (E27)",
      "sensor.type.1039": "Radiografische thermostaat",
      "sensor.type.1040": "3 in 1 Vochtigheidsensor",
      "sensor.type.1041": "Wandschakelaar",
      "sensor.type.1042": "3 in 1 Lichtsterkte- en Deursensor",
      "sensor.type.1043": "Deur- en raamsensor (verborgen inbouw)",
      "sensor.type.1044": "Radiografische versterker tbv vergroting reikwijdte",
      "sensor.type.1045": "Binnensirene met Backup",
      "sensor.type.1046": "Grote wandschakelaar",
      "sensor.type.1047": "Rookhmelder met binensirene",
      "sensor.type.1048": "4-knops mini-afstandsbediening",
      "sensor.type.1049": "CO-Melder",
      "sensor.type.1050": "Paniekknop",
      "sensor.type.1051": "Temperatuursensor met display en richtwaarde-(setpoint)verstelling",
      "sensor.type.1052": "Radiatorthermostaat incl. temperatuurmeting",
      "sensor.type.1053": "Verwarmingsthermostaat met display en richtwaarde-(setpoint)verstelling",
      "sensor.type.1054": "Draadloos stopcontact",
      "sensor.type.1055": "Temperatuursensor",
      "sensor.type.1056": "2 in 1 vochtigheids- en temperatuursensor",
      "sensor.type.1058": "Watermelder",
      "sensor.type.1059": "Thermostaat met display",
      "sensor.type.1060": "Deursensor",
      "sensor.type.1061": "Relaisschakelaar",
      "sensor.type.1062": "Schakelaar Single Switch 2",
      "sensor.type.1063": "Kleurstrip",
      "sensor.type.1065": "Noodknop",
      "sensor.type.1066": "Smart Plug-in schakelaar",
      "sensor.type.1067": "Eenknops-afstandsbediening",
      "sensor.type.1068": "Schakelaar Double Switch",
      "sensor.type.1069": "Watersensor",
      "sensor.type.1070": "3-fasen Smart Meter",
      "sensor.type.1071": "Smart Meter",
      "sensor.type.1072": "Smart Implant",
      "sensor.type.1073": "Schimmelsensor",
      "sensor.type.1074": "Deursensor",
      "sensor.type.1075": "Thermostaat",
      "sensor.type.1076": "Watersensor (2)",
      "sensor.type.1077": "LED-Lamp",
      "sensor.type.1078": "Draadloos stopcontact",
      "sensor.type.1079": "Eurotronic luchtkwaliteitsmeter",
      "sensor.type.1080": "Popp-Z regen",
      "sensor.type.1081": "Popp-Z weer",
      "sensor.type.1082": "Climax/Coqon lichtsensor",
      "sensor.type.11000": "Standaard met voet",
      "sensor.type.12000": "tablet",
      "sensor.type.1255": "Sensor onbekend, a.u.b. bij support melden",
      "sensor.type.13001": "Easywave deur-venster",
      "sensor.type.13002": "Easywave Medbox",
      "sensor.type.13003": "Easywave 1-knops controller",
      "sensor.type.13004": "Easywave bewegingsmelder",
      "sensor.type.13005": "Easywave Aan-/uit schakelaar",
      "sensor.type.13006": "Easywave bedliggingssensor ",
      "sensor.type.13007": "Easywave bedvochtigheidsmeter",
      "sensor.type.13008": "Easywave bed bevestiging",
      "sensor.type.13009": "Easywave bedhoogte",
      "sensor.type.13010": "Easywave bed rail",
      "sensor.type.13200": "Easywave stopcontact",
      "sensor.type.13201": "Easywave binnenwandse actor",
      "sensor.type.14203": "WuT OUT",
      "sensor.type.14204": "WuT IN",
      "sensor.type.14210": "Funksteckdose",
      "sensor.type.15001": "OmniConnect Ort",
      "sensor.type.15002": "OmniConnect Objekt",
      "sensor.type.21": "Batterij van de centrale",
      "sensor.type.3": "Radiografische knop (Noodknop)",
      "sensor.type.4003": "Radiografische knop(GPS SOS)",
      "sensor.type.5000": "Knop",
      "sensor.type.5001": "Radiografischek-Minipager",
      "sensor.type.5002": "Radiografische pager",
      "sensor.type.5003": "Deur- en Raamsensor",
      "sensor.type.5004": "Bewegingsmelder",
      "sensor.type.5005": "Draadloos stopcontact",
      "sensor.type.5006": "Thermostaat",
      "sensor.type.5007": "Schakelaer",
      "sensor.type.5008": "Schakelaar",
      "sensor.type.5009": "Dimmer",
      "sensor.type.5010": "Jaloezie",
      "sensor.type.5011": "Bewegingsmelder",
      "sensor.type.5012": "Card-schakelaar",
      "sensor.type.5013": "Vochtigheids- en Temperatuursensor",
      "sensor.type.5014": "Watermelder",
      "sensor.type.5015": "Bewegingsmelder AAL",
      "sensor.type.5016": "Bewegings-Helderheidssensor",
      "sensor.type.5017": "Thermostaat",
      "sensor.type.5018": "Raamkruk",
      "sensor.type.5019": "Raamkruk met kantelherkenning",
      "sensor.type.5020": "Vochtigheidss- en temperatuursensor",
      "sensor.type.5021": "Luchtkwaliteitsensor",
      "sensor.type.5023": "virtuele thermostaat",
      "sensor.type.5024": "beweging- / lichtsensor",
      "sensor.type.6006": "Bedligging en -vochtigheidssensor",
      "sensor.type.7004": "Rauchmelder",
      "sensor.type.7005": "Wasserzähler",
      "sensor.type.7006": "Wärmezähler",
      "sensor.type.7007": "Wasserzähler",
      "sensor.type.7008": "Wasserzähler",
      "sensor.type.7009": "Wärmezähler",
      "sensor.type.7011": "Temperatur/Feuchtigkeit",
      "sensor.type.7012": "Temperatur",
      "sensor.type.7013": "Temperatur/Feuchtigkeit",
      "sensor.type.8000": "Eldat social alarmknop",
      "sensor.type.placeholder": "bijv. woonkamerdeur",
      "sent": "succesvol verzonden",
      "shipping.settings": "Verzendingsinstellingen",
      "situation.delay.1": "Es soll eine Aktion erfolgen, wenn die Tür ungewöhnlich lange geöffnet ist, das heißt länger als",
      "situation.delay.16": "Es soll eine Aktion erfolgen, wenn das Fenster ungewöhnlich lange geöffnet ist, das heißt länger als",
      "situation.delay.18": "Es soll eine Aktion erfolgen, wenn gleichzeitig ein Elektrogerät (z.B. der Herd) eingeschaltet und die Tür geöffnet ist, und zwar länger als",
      "situation.delay.19": "Es soll eine Aktion erfolgen, wenn ein Elektrogerät (z.B. der Herd) eingeschaltet ist und keine Aktivität mehr festgestellt wird für länger als",
      "situation.delay.2": "Es soll eine Aktion erfolgen, wenn ein Elektrogerät (z.B. der Herd) ungewöhnlich lange eingeschaltet ist, das heißt länger als",
      "situation.delay.21": "Abschaltung bei fehlender Aktivität länger als",
      "situation.delay.27": "Es soll eine Aktion erfolgen, wenn die Zentraleinheit länger im Batteriebetrieb ist als",
      "situation.delay.31": "Es soll eine Aktion erfolgen, wenn die Kühlschranktür ungewöhnlich lange offen steht, das heißt länger als",
      "situation.delay.34": "Es soll eine Aktion erfolgen, wenn die Kühlschranktür ungewöhnlich lange nicht geöffnet wird, das heißt länger als",
      "situation.delay.38": "Nach welcher Verzögerung sollte Licht / der Aktor ausgeschaltet werden, wenn keine Bewegung mehr festgestellt wird?",
      "situation.delay.4": "Es soll eine Aktion erfolgen, wenn ungewöhnlich lange keine Aktivität registriert wird, das heißt länger als",
      "situation.delay.65": "Einstellung der Temperatur bei keiner Aktivität länger als",
      "situation.delay.68": "Es soll eine Aktion erfolgen, wenn eine Temperatur über 58°C gemessen wird, und zwar für länger als",
      "situation.hint.103": "Diese Situation ermöglicht eine regelmäßige und schaltungsabhängige Prüfung der Wasserabsperrventile auf manuelle Arretierung. Hierzu kann ein Spannungs-Schwellwert pro Sensor gesetzt werden, dessen Überschreitung beim Arretierungstest zu einem Alarm führt.",
      "situation.hint.11": "Die Zentraleinheit gilt als \"Offline\" wenn sie länger als 15 Minuten keine Verbindung zum Rechenzentrum aufbauen konnte.",
      "situation.hint.21": "Bitte beachten Sie, dass erst die Auswahl mehrerer Sensoren sicherstellt, dass die Situationserkennung zuverlässig funktioniert.",
      "situation.hint.70": "Es soll eine Aktion erfolgen, wenn Sensoren verschoben oder entfernt werden.",
      "situation.hint.90": "Bei Fensteröffnung werden ausgewählten Thermostate abgeschaltet. Wenn alle zugeordneten Fenster wieder geschlossen sind, wird entweder der Wert des aktuellen Zeitschaltplans benutzt, oder der letzte bekannte Wert vor der Öffnung.",
      "situation.type.1": "De woningdeur staat ongewoonlijk lang open",
      "situation.type.10": "Er is rookontwikkeling gedecteerd.",
      "situation.type.100": "De SOS-knop werd ingedrukt.",
      "situation.type.101": "De aangegeven snelheid werd overschreden.",
      "situation.type.102": "De aangegeven afstand werd overschreden.",
      "situation.type.103": "Ventielsturung vergrendelingsherkenning",
      "situation.type.106": "snelkoppeling thermostaat / temperatuur",
      "situation.type.11": "De centrale is beschadigd, of defect en meldt zich niet meer bij het Datacenter.",
      "situation.type.12": "De werkingsperiode van de centrale eindigt binnenkort.",
      "situation.type.13": "Er lekt brandbaar gas.",
      "situation.type.14": "Er is sprake van een waterlek, of overstromend water.",
      "situation.type.15": "Er lekt koolmonoxide.",
      "situation.type.16": "Raam staat ongebruikelijk lang open.",
      "situation.type.17": "Het bed werd ongewoonlijk lang niet meer gebruikt.",
      "situation.type.18": "De oven/kookplaat staat aan en de huis-/voordeur werd geopend.",
      "situation.type.19": "De oven/kookplaat is ingeschakeld bij het verlaten van de woning.",
      "situation.type.2": "Een electronisch apparaat (bijv oven/fornuis) is ongewoonlijk lang ingeschakeld.",
      "situation.type.20": "De oven/kookplaat is ingeschakeld, terwijl bed in gebruik is.",
      "situation.type.21": "Geselecteerde electronische apparaten dienen te worden uitgeschakeld, wanneer er geen beweging in de woning wordt geconstateerd.",
      "situation.type.22": "Geselecteerde electronische apparaten dienen te worden uitgeschakeld, wanneer het bed in gebruik is.",
      "situation.type.23": "Geselecteerde electronische apparaten dienen op een bepaald tijdstip (dagelijks) uitgeschakeld te worden.",
      "situation.type.24": "Geselecteerde electronische apparaten dienen met de \"alles uit\"-schkeleaarAusgewählte uitgeschakeld te worden.",
      "situation.type.25": "Geselecteerde electronische apparaten dienen met een schakelaar aan- en uitgeschakeld te worden.",
      "situation.type.26": "Gedurende afwezigheid van de bewoner wordt activiteit in de woning vastgesteld.",
      "situation.type.27": "De centrale is in batterijmodus.",
      "situation.type.31": "De koelkastdeur staat ongewoonlijk lang open.",
      "situation.type.33": "De woning wordt op ongebruikelijk tijdstip (bijv. 's nachts) verlaten.",
      "situation.type.34": "Een contact werd in de bepaalde periode niet gebruikt.",
      "situation.type.35": "De bewoner gebruikt electronische apparaten op ongewoonlijke tijden.",
      "situation.type.36": "De bewoner verlaat 's nachts het bed en keert niet binnen bepaalde tijd terug.",
      "situation.type.38": "Schakeling van een actor (bijv. licht) bij beweging.",
      "situation.type.39": "Geselecteerde electronische apparaten dienen te worden uitgeschakeld, wanneer de woning verlaten is en geen activiteit meer wordt herkend.",
      "situation.type.4": "Er is ongewoonlijk lang geen activiteit meer vastgesteld.",
      "situation.type.45": "Een actor werd in de bepaalde periode niet gebruikt.",
      "situation.type.46": "Thermostaatinstelling voor bepaalde tijd (dagelijks).",
      "situation.type.5": "Het bed werd ongewoonlijk lang niet meer gebruikt.",
      "situation.type.50": "De bloeddruk overstijgt/valt beneden de aangegeven grenswaarden.",
      "situation.type.52": "De polsmeting overstijgt/valt beneden de aangegeven grenswaarden.",
      "situation.type.54": "De bloedzuikerwaarrde overstijgt/valt beneden de aangegeven grenswaarden.",
      "situation.type.56": "Het gewicht overstijgt/valt beneden de aangegeven grenswaarden.",
      "situation.type.58": "Een sensor geeft lage stroomvoorziening v.w.b. accu. respectieveljk haar batterij aan.",
      "situation.type.59": "De bewoner wordt aan het meten van de bloeddruk herinnert.",
      "situation.type.6": "Het innemen van medicamenten is niet binnen daarvoor geplande tijdsruimte gedaan.",
      "situation.type.60": "De bewoner wordt aan het meten van de pols herinnert.",
      "situation.type.61": "De bewoner wordt aan het meten van de bloeddruk herinnert.",
      "situation.type.62": "De bewoner wordt aan de meting van het gewicht herinnert.",
      "situation.type.63": "Thermostaatinstellig bij gebruik van het bed.",
      "situation.type.64": "Thermostaatinstelling bij het verlaten van de woning.",
      "situation.type.65": "Thermostaatinstelling wanneer geen activiteit in de woning wordt vastgesteld.",
      "situation.type.66": "Aanduiding van het sensoroverzicht op de centrale.",
      "situation.type.68": "Er werd een temperatuur boven 58°C geregistreerd.",
      "situation.type.7": "Er is een noodoproep/alarm geactiveerd.",
      "situation.type.70": "Er werd een sensor verwijderd of verschoven.",
      "situation.type.71": "Actieve apparaten bij het verlaten van de woning",
      "situation.type.72": "Een sensor heeft zich al langer dan 36 uur niet gemeldt.",
      "situation.type.8": "Het is ongewoonlijk warm in de woning.",
      "situation.type.81": "De bewoner heeft de woning verlaten of is teruggekeerd.",
      "situation.type.82": "De aangegeven vochtigheidsgrens werd overschreden/of waardes vallen aangegeven grens.",
      "situation.type.83": "de aangegeven vochtigheidsgrens werd onder-/overschreden",
      "situation.type.84": "Thermostaat schakelt andere schakelaars.",
      "situation.type.85": "De temperaturen voor comfort (-verhoging) & nacht (-daling) modi instellen.",
      "situation.type.88": "de concentratei aan vluchtige organische verbindingen in de lucht is te hoog",
      "situation.type.89": "De tablet heeft zich langer dan 30 minuten niet meer gemeld.",
      "situation.type.90": "Temperaturregelung beim Lüften",
      "situation.type.93": "Instelling voor thermostaten of uit-/aanschakeling van electronisch apparaten.",
      "situation.type.94": "De aangegeven temperaturgrenzen werden overschreden/waarde vallen onder de aangegeven grens.",
      "situation.type.95": "De aangegeven temperatuurgrens werd onder-/overschreden.",
      "situation.type.96": "Er werd een val herkend.",
      "situation.type.97": "Een actor werd binnen de aangegeven tijdsduur niet gebruikt.",
      "situation.type.configured": "geconfigureerde situaties",
      "situation.type.initial": "Voer a.u.b. onder 'Contacten'een contact in, om situaties te kunnen configureren",
      "situation.type.list_header.affected": "Betreffende sensoren",
      "situation.type.title": "Configureerbare situaties",
      "time": "Tijd",
      "title": "Titel",
      "title-bar.date": "Datum",
      "title-bar.duration": "Dauer",
      "title-bar.sender": "Afzender",
      "title-bar.tile": "Titel",
      "title-required": "Titel is vereist",
      "to": "Tot",
      "tooltip.clock": "Zal worden verzonden op \\\"{{clock}}\\\"",
      "tooltip.delete": "Verwijder",
      "tooltip.forward": "Doorgaan naar de WebApp",
      "tooltip.hubs": "Betrokken centrale eenheden",
      "tooltip.information": "Verdere informatie",
      "tooltip.loop": "Wordt \\\"{{loop}}\\\"herhaald",
      "username": "Gebruikersnaam",
      "voice.archive": "Sprachnachrichten-Archiv",
      "voice.description": "In diesem Bereich können Sprachnachrichten aufgenommen und versendet werden sowie bereits versendete und erhaltene Sprachnachrichten eingesehen werden.",
      "voice.message.send": "Sprachnachricht versenden",
      "voice.messages.create.select.file.please": "Bitte nehmen Sie ein Audio auf.",
      "voice.messages.created": "Sprachnachricht wurde versendet",
      "voice.send": "Sprachnachricht versenden",
      "voice.title": "Sprachnachrichten",
      "wifi": "Wlan",
      "wifi.error.start": "Wlan konnte nicht eingeschaltet werden. Bitte probieren Sie es erneut.",
      "wifi.ip": "IP Addresse",
      "wifi.ip.unknown": "unbekannte IP Adresse",
      "wifi.noconnection": "Keine Verbindung",
      "wifi.noconnection.content": "Wlan ist eingeschaltet aber hat keine Verbindung.",
      "wifi.off": "Wlan ist ausgeschaltet",
      "wifi.signalquality": "Signalqualität",
      "wifi.ssid": "SSID",
      "wifi.turnon": "Wlan einschalten",
      "wrong-password": "Het wachtwoord is onjuist.",
      "wrong-user": "De gebruiker \"{{username}}\" werd niet gevonden."
   }
}