import React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { setTooltip } from 'r_actions/noApi.actions'



const SelectedProperty = ({ propId, description }) => {
  
  // const dispatch = useDispatch()

  // const missing = useSelector(s => s.missing)

  // const message = { 
  //   message: missing && missing[propId] ? `Es fehlen ${missing[propId].map(h => h.label)}` : description, 
  //   position: 'top' }

  return <span/* onMouseEnter={() => dispatch(setTooltip(message))} onMouseLeave={() => dispatch(setTooltip(null))}*/>{description}</span>
}

SelectedProperty.propTypes = {
  propId: PropTypes.number,
  description: PropTypes.string,
}
  
  
export default SelectedProperty




