
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import { setSearch } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'
import './ByName.scss'

const ByName = ({ properties, skip }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const searchValue = useSelector(s => s.search)

  const onSearch = ({ target }) => dispatch(setSearch({ text: target.value }))

  const setFocus = () => {document.getElementById('input-stuff').focus()}
 
  return (
    <div className={`ByName container ${properties}`}>

      { 
        !skip && <div className="align-items-center row ">
          <input className="search-input col" id="input-stuff" type="text" onChange={onSearch} value={searchValue.text} placeholder={t('title')} ></input>

          <div className="search-icon col-auto" onClick={() => setFocus()}>
            <div className="to-center">
              <SimpleLineIcon name="magnifier" size="Medium" color="white" />
            </div>
          </div>

        </div>}
 
    </div>
  )
}

ByName.propTypes = {
  properties: PropTypes.string,
  skip: PropTypes.bool,
}

export default ByName















