import { VOICE_MESSAGES } from './feathers'

export const _fetchVoiceMessages = async () => {
  const voiceMessages = await VOICE_MESSAGES.find({ query: { $sort: { id: -1 } } })
  return voiceMessages?.data || voiceMessages
}

export const _createVoiceMessages  = async ({ file, voiceMessagesPackage }) => await VOICE_MESSAGES.create(voiceMessagesPackage, { file })


