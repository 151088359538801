import React, { useState, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledDateTimeSelector, StyledLabel, StyledDatePickerWrapper } from './Styles'
import { useSelector } from 'react-redux'
import XButton from 'components/common/XButton/XButton'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import de from 'date-fns/locale/de'
import nl from 'date-fns/locale/nl'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('de', de)
registerLocale('nl', nl) 

const DateTimeSelector = ({ onChange, planedDate }) => {
  const { language } = useSelector(s => s.i18n)
  const [startDate, setStartDate] = useState(planedDate)

  const { t } = useTranslation()

  useEffect(() => {
    setStartDate(planedDate)
  }, [planedDate])
  

  const setDate = date => {
    setStartDate(date)
    onChange({ target: { name: 'planedDate', value: date } })
  }

  const filterPassedTime = (time) => new Date().getTime() < new Date(time).getTime()
   
  const placeholder = <span className="d-flex align-items-center row">
    <div className="LabelIcon col-1">
      <SimpleLineIcon  name="clock" size="medium" color="grey"/>
    </div>
    <div className="LabelText col-auto">
      {t('alerts.send.now')}        
    </div>
  </span>    
  

  const CustomInput = forwardRef(({ value, onClick }, ref) => <StyledDateTimeSelector 
    className="row"
    ref={ref}
    onClick={onClick}
  > 

    <StyledLabel className="label col">
      {  startDate 
        ? value
        : placeholder
      }
    </StyledLabel>
  
  
    {  startDate 
      ? <XButton className="col-auto"
        onClick={() => setDate(null)}
        value={1}
      />
      : null
    }
     
  </StyledDateTimeSelector>
  )


  return <StyledDatePickerWrapper >
    <DatePicker
      selected={startDate}
      onChange={(date) => setDate(date)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={5}
      timeCaption={t('time')}
      dateFormat="MMMM d, yyyy HH:mm"
      locale={language}
      customInput={<CustomInput/>}
      minDate={new Date()}
      filterTime={filterPassedTime}
    />
  </StyledDatePickerWrapper>
}    


DateTimeSelector.propTypes = {
  onChange: PropTypes.func
}

export default DateTimeSelector

