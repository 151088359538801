import styled from 'styled-components'

const Wrapper = styled.div`
  margin-left: 3em;
  position: relative;
  user-select: none;
`

const Title = styled.div`
  padding-left: 0.5em;
  font-size: 20px;
  color: white;
  
  white-space: nowrap;
  flex: 1;                
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

const MenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const SubMenu = styled.div`
  position: absolute;
  background-color: #050505d4;
  backdrop-filter: blur(2px);
  color: white;
  z-index: 999;
  cursor: pointer;
  padding: 1em 0;
  line-height: 2.5em;
  border-radius: 5px;
  margin-left: -1.5em;
  box-shadow: 4px 10px 10px -5px #000000ad;

  @media (max-width: 768px) {
    overflow: scroll;
    max-height: 40rem;
  }



  -webkit-animation: swing-in-top-fwd 0.5s
    cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
`

const Category = styled.div``

const SubCategory = styled.div`
  display: flex;
  cursor: default;
  padding: 0 1.5em;
`

const SubSubCategory = styled.div`
  display: flex;
  cursor: default;
  white-space: nowrap;
  cursor: pointer;
  text-indent: 40px;
  padding: 0 2em;
  &:hover {
    background-color: #ffffff36;
    color: ${(p) => p.activeColor};
  }
`

export {
  Wrapper,
  MenuButton,
  Title,
  SubMenu,
  Category,
  SubCategory,
  SubSubCategory,
}
