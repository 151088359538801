import initialState from './initialState'
import { CREATE_ALERT_SUCCESS, PATCH_ALERT_SUCCESS } from 'r_constants/alerts.constants'
import { CREATE_NEWS_SUCCESS } from 'r_constants/news.constants'
import { UPDATE_RESULT_VALUE } from 'r_constants/noApi.constants'
import { CREATE_VOICE_MESSAGES_SUCCESS } from 'r_constants/voiceMessages.constants'

export default function result(result = initialState.result, action) {
  switch (action.type) {
      
  case CREATE_ALERT_SUCCESS:
    return action.alert
      
  case CREATE_NEWS_SUCCESS:
    return action.news
      
  case UPDATE_RESULT_VALUE:
    return action.value

  case PATCH_ALERT_SUCCESS:
    return action.alert

  case CREATE_VOICE_MESSAGES_SUCCESS :
    return action.voiceMessages

  default:
    return result
  }
}
