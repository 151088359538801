import React from 'react'
import PropTypes from 'prop-types'
import InputField from '../common/InputField/InputField'
import LanguagePicker from 'components/mainHeader/LanguagePicker/LanguagePicker'
import { useTranslation } from 'react-i18next'
import logo from '../../iconsImages/cockpit.svg'
import './auth.scss'

const LoginForm = ({ username, password, onChange, onSubmit, submitted }) => {

  const { t } = useTranslation()

  const handleKeyPress = (target) => {
    if(target.code === 'Enter') onSubmit()  
  }

  return (
    <div className="LoginWrapper" >
      <div className="LoginForm" onKeyDown={handleKeyPress}>    

        <LanguagePicker/>    

        <div className="toolbar__logo"><img src={logo} alt="" /></div>
                   
        <InputField className="field"
          type="text"
          property="username"
          placeholder={t('username')}
          isValid= {!submitted || username} 
          value={username}
          onChange={onChange}
          invalidText={t('input-username')}
        />
      
        <InputField className="field"
          type="password"
          property="password"
          placeholder={t('password')}
          isValid={!submitted || password}
          value={password}
          onChange={onChange}
          invalidText={t('input-password')}
        />
        <button className="left-btn btn-success btn-sm col" type="submit" onClick={onSubmit}>Login</button>    
      </div>
    </div>
  )
}

LoginForm.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  submitted: PropTypes.bool,
}

export default LoginForm