import initialState from './initialState'
import { FETCH_HUBS_SUCCESS, FETCH_HUBS_FAILURE } from 'r_constants/hubs.constants'

export default function hubs(hubs = initialState.hubs, action) {
  switch (action.type) {
      
  case FETCH_HUBS_SUCCESS:
    return action.hubs.map(h => ({ ...h, description: `${h.description} (${h.casaid})` })) || null

  case FETCH_HUBS_FAILURE:
    return hubs

  default:
    return hubs
  }
}
