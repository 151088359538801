import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DropDownSelector from 'components/common/DropDownSelector/DropDownSelector'

import { useTranslation } from 'react-i18next'


const RepetitionSelector = ({ onChange, repeat }) => {

  const { t } = useTranslation()

  const options = [
    { value: 'd', label: t('alerts.send.daily') },
    { value: 'w', label: t('alerts.send.weekly') },
    { value: 'm', label: t('alerts.send.monthly') },
    { value: 'y', label: t('alerts.send.yearly') },
  ]

  const formedValue = (value = repeat) => options.find(o => o.value === value)

  const [_repeat, setRepeat] = useState(formedValue())

  useEffect(() => {if(!repeat) setRepeat(null)}, [repeat])

  const _setRepeat = e => {
    setRepeat(formedValue(e?.value))
    onChange({ target: { name: 'repeat', value: e?.value } })
  }
  
  return(
    <DropDownSelector
      closeMenuOnSelect={true}
      selected={_repeat} 
      options={options}
      placeholderID={'alerts.not.repeat'}
      icon="loop"
      onChange={e => _setRepeat(e) }
      noOptionsMessage={() => '1337'}
    />
  )
}

RepetitionSelector.propTypes = {
  onChange: PropTypes.func,
  repeat: PropTypes.string
}

export default RepetitionSelector


