import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import InputRow from './InputRow'
import DatePicker, { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import nl from 'date-fns/locale/nl'
import { StyledDateLine } from './Styles'

import 'react-datepicker/dist/react-datepicker.css'
registerLocale('de', de)
registerLocale('nl', nl)


const DateLine = ({ properties, property, placeholder, date, referenceDate, onChange, maxValue = false  }) => {

  const { language } = useSelector(s => s.i18n)

  return ( 

    <StyledDateLine className={`DateLine ${properties}`}>
      <DatePicker
        type="datetime"
        dateFormat="dd MM yyyy"
        customInput={  

          <InputRow 
            onEmpty={onChange}
            property={property}
            placeholderText={placeholder}
            date={date}
          />

        }
        selected={date}
        onChange={date => onChange({ [property]: date })}
        minDate={maxValue ? null : referenceDate}
        maxDate={maxValue ? referenceDate : null}
        locale={language}
      />   
    </StyledDateLine>
  )
}

DateLine.propTypes = {
  properties: PropTypes.string,
  property: PropTypes.string, 
  placeholder: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  referenceDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  maxValue: PropTypes.bool,
}
  
  
export default DateLine