import initialState from './initialState'
import { 
  FETCH_OEM_SUCCESS, FETCH_OEM_FAILURE
} from 'r_constants/oem.constants'

export default function oem(oem = initialState.oem, action) {
  switch (action.type) {
      
  case FETCH_OEM_SUCCESS:
    return action.OEM || null

  case FETCH_OEM_FAILURE:
    return oem

  default:
    return oem
  }
}
