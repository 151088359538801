import React from 'react'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'




const HubCell = ({ hub, itemId, boots = '' }) => {
  const { pid_hubs, description, info: { acked } } = hub

  const { t } = useTranslation()

  const message = t(acked ? 'read' : 'not-read')

  return  <div key={pid_hubs} className={`${boots} d-flex align-items-center row`}>

    <div className="col-auto pr-1">{description}</div>

    <div className="col-auto p-0">
      <SimpleLineIcon  
        id={`hubCellAnchor-${itemId}-${pid_hubs}`}
        name={acked ? 'check' : 'ban'}
        size="Medium" 
        color={acked ? '#456914' : '#d93209'}
      />
      {/* <Tooltip anchorId={`hubCellAnchor-${itemId}-${pid_hubs}`} content={message}  place="top"/> */}
    </div>

    <div className="col"/>

  </div>
      
}
       
  
HubCell.propTypes = {
  hub: PropTypes.object,
  boots: PropTypes.string,
  
}

export default HubCell




