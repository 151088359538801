import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchDocs } from 'r_actions/docs.actions'
import List from 'components/common/SearchList/List'

import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`


const DocsArchive = () => {
  const dispatch = useDispatch()
     
  useEffect(() => {
    dispatch(fetchDocs())
  }, [dispatch])

  const docs = useSelector(state => state.docs || [])

  return (

    <List 
      array={docs} 
      searchProperty="name"
      sortProperty="id" 
      idProperty="id"
      titleProperty="name" 
      dateProperty="created" 
      content="content"
      heading="Dokumente"
      searchDateProp="created"
    />

  )
}

DocsArchive.propTypes = {
  onClick: PropTypes.func,
}

export default DocsArchive

