import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconRow from './Icons/IconRow'
import './ListElement.scss'
import { deleteDocs } from 'r_actions/docs.actions'
import ModalHandler from './ModalHandler'

const remove = { 'docs-archive': deleteDocs }

const ListElement = ({ itemId, title, date, onClick, open, item, hasContent }) => {


  const [modal, setModal] = useState(false)

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')


  return (
    <div className="ListElement d-flex align-items-center row" style={{ cursor: hasContent ? 'pointer' : 'default' }}>

      <div className="pt-0 border-0 col-xs-2 col-lg-3 " onClick={() => onClick(itemId, 'content')}>
        <span className="li-title">{title}</span>
      </div>

      <div className="pt-0 border-0 col" onClick={() => onClick(itemId, 'content')}>
        {date 
          ? <span className="li-title">{date}</span> 
          : <label/> 
        }
      </div> 



      <IconRow itemId={itemId} onClick={onClick} open={open} item={item} setModal={setModal} pathname={pathname} remove={remove[pathname]} />

      <ModalHandler modal={modal} setModal={setModal} item={item} pathname={pathname}/>
       
    </div>

  )
}

ListElement.propTypes = {
  itemId: PropTypes.number,
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  onClick: PropTypes.func,
  content: PropTypes.string,
  open: PropTypes.bool,
  item: PropTypes.object,
  hasContent: PropTypes.bool,
  
}

export default ListElement


