import styled from 'styled-components'

const Navigation = styled('div')`
 line-height: 2em;
 padding-left: 1em;
 padding-right: 1em;

`
const Title = styled('div')`
text-align: center;
vertical-align: middle;
background-color: #464e59;
color: white;
`

const Button = styled('button')`
background-color: #464e59;
color: white;
&:focus{
    outline: none;
}
&:hover{
    background-color: #272c32;
}
`


export { Navigation, Title, Button }
