import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import SimpleLineIcon from 'react-simple-line-icons'
import makeAnimated from 'react-select/animated'
import { useTranslation } from 'react-i18next'
import { StyledSelector } from './Styles'
import { customStyles } from './CustomStyles'
import PropertyInformation from './PropertyInformation'


const DropDownSelector = ({ selected, defaultValue, options, onChange, icon, placeholderID, noOptionsID, closeOnSelect, isMulti = false }) => {

  const { t } = useTranslation()

  const missing = useSelector(s => s.missing)


  const placeholder = <span className="d-flex align-items-center row">
    <div className="LabelIcon col-1">
      <SimpleLineIcon  name={icon} size="medium" color="grey"/>
    </div>
    <div className="col-auto">
      {t(placeholderID)}        
    </div>
  </span>    

  return <StyledSelector> 
    <Select className="Selector"
      closeMenuOnSelect={closeOnSelect}
      components={makeAnimated()}
      defaultValue={defaultValue || null}
      value={selected} 
      options={options}
      placeholder={placeholder}
      onChange={e => onChange(e) }
      noOptionsMessage={() => t(noOptionsID)}
      isClearable={true}
      styles={customStyles}
      isMulti={isMulti}
    />

    {
      (missing && icon === 'home') && <PropertyInformation missing={missing}/>
    }

  </StyledSelector>
}


DropDownSelector.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func, 
  placeholderID: PropTypes.string, 
  icon: PropTypes.string,
  noOptionsID: PropTypes.string, 
  closeOnSelect: PropTypes.bool, 
  isMulti: PropTypes.bool

}
  
  
export default DropDownSelector