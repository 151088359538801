import React from 'react'
import PropTypes from 'prop-types'
import DateTimeSelector from './DateTimeSelector'
import RepetitionSelector from './RepetitionSelector'
import { StyledOptionsHandler } from './Styles'

const SendingOptionsHandler = ({ onChange, data }) => {
    
  return(
    <StyledOptionsHandler className="SendingOptions">

      <DateTimeSelector onChange={onChange} planedDate={data.planedDate}/>

      <RepetitionSelector onChange={onChange} repeat={data.repeat}/>
     
    </StyledOptionsHandler>
      

  )
}

SendingOptionsHandler.propTypes = {
  onChange: PropTypes.func
  
}

export default SendingOptionsHandler

