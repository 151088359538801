import { DOCS } from './feathers'
// import { checkSent, checkDeleted } from './userMessages'

export const _fetchDocs = async () => { 
  const docs = await DOCS.find()
  return docs?.data || docs
}

export const _createDocs = async (doc) => await DOCS.create(doc)


export const _deleteDocs = async (id) =>  await DOCS.remove(id)

