import React from 'react'
import PropTypes from 'prop-types'
import InputArea from 'components/common/InputArea/InputArea'
import FileSelector from '../_common/FileSelector'
import SubmitButton from 'components/common/SubmitButton/SubmitButton'
import PropertiesHubsSelector from '../_common/PropertiesHubsSelector'
import { useTranslation } from 'react-i18next'
import './Images.scss'

const accept = 'image/jpeg,image/gif,image/png,image/x-png'


const CreateImagesForm = ({ data, setHubs, setProperties, hubs, onChange, submitForm, intl }) => {
  const { description, submitted, file } = data
  const { t } = useTranslation()

  return(
    <div className="EditImagesForm container">
      <div className="images-title col-12">{t('images.send')}</div>
      <div className="borderFrame">
        <div className="images-form col-lg-8 offset-lg-2">

          <InputArea className="field"
            type="text"
            property="description"
            placeholder={t('description')}
            isValid= {!!description || !submitted} 
            value={description}
            onChange={onChange}
            invalidText={t('description-required')}
            maxLength={124}
            minHeight="5"
          />

          <FileSelector 
            file={file}
            onChange={ onChange }
            accept={accept}
            additionalMimes={['image/gif']}
          />
   
          <PropertiesHubsSelector 
            data={data}
            hubs={hubs} 
            setHubs={setHubs}
            setProperties={setProperties}
          />

          <SubmitButton onClick={submitForm}/>
  
        </div>         
      </div>
     
    </div>

  )
}

CreateImagesForm.propTypes = {
  data: PropTypes.object,
  hubs: PropTypes.array,
  onChange: PropTypes.func, 
  submitForm: PropTypes.func,
  setHubs: PropTypes.func, 
  setProperties: PropTypes.func, 
}

export default CreateImagesForm

