import { USER_PROFILE } from './feathers'

export const _patchUserProfile = async data => {
  const profile = await USER_PROFILE.patch(null, data)
  return profile
}

export const _createUserProfile = async data => {
  const profile = await USER_PROFILE.create(data)
  return profile
}



