import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'toastr'
import CreateImagesForm from './CreateImageForm'
import { fetchHubs } from 'r_actions/hubs.actions'
import { createImages } from 'r_actions/images.actions'
import { fetchProperties } from 'r_actions/properties.actions'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'


const CreateImage = () => {

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [data, setData] = useState({
    description: '', 
    selectedHubs: [], 
    selectedProperties: [],
    file: null,
    submitted: false, 
  })
  const { description, selectedHubs, file } = data
  
  
  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchProperties())
  }, [dispatch])


  const hubs = useSelector(state => state.hubs || [])
  const result = useSelector(state => state.result)

  useEffect(() => {
    if(!result) return 
    toastr.success(t('images.sent'))
    dispatch(setResult(null))
  }, [dispatch, result, t]) 
 
 
  const onChange = (e) => {   
    let { name, value } = e.target   
    setData(d => ({ ...d, [name]: value }))  
  }

       
  const submitForm = async () => {
    let empty =  { description: '', selectedHubs: [], selectedProperties: [], file: null, submitted: false } 

    const imagesPackage = { description, selectedHubs, file }

    if (!!description && file && selectedHubs.length) {
      dispatch(createImages({ file, imagesPackage }))
      return setData(d => ({ ...d, ...empty, filePreviewUrl: '' }))
    }
    
    if (!file) {toastr.error(t('images.create.select.file.please'))}
   
    if (!selectedHubs.length) {toastr.error(t('select-least-hub'))}
    
    return setData(d => ({ ...d, submitted: true }))
  }


  const setHubs = (sHubs) => setData(d => ({ ...d, selectedHubs: sHubs }))
  const setProperties = (sProperties) => setData(d => ({ ...d, selectedProperties: sProperties }))
    
              

  return hubs.length 
    ? <CreateImagesForm 
      data={data}
      hubs={hubs}
      onChange={onChange}
      submitForm={submitForm}      
      setHubs={setHubs}
      setProperties={setProperties}
          
    />
    : <div className="notAvailibleMsg">{t('hubs.select.missing')}</div>
         
}



export default CreateImage





  
