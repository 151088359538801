import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button'
import Modal from './ModalComponents/Modal'
import ModalHeader from './ModalComponents/ModalHeader'
import ModalBody from './ModalComponents/ModalBody'
import ModalFooter from './ModalComponents/ModalFooter'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { deleteDocs } from 'r_actions/docs.actions'
import { useDispatch } from 'react-redux'


const remove = { 'docs-archive': deleteDocs }

const DeleteModal = ({ item, setModal }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()



  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')

  const onRemove = () => {
    setModal(false)
    dispatch(remove[pathname](item.id))
  }

  
  
  return (
    <Modal className="modal modal-lg" active={true}>
      <ModalHeader>
        <h5>{`${item.name} löschen?`}</h5>
      </ModalHeader>
      <ModalBody>
        <h6 className="text-danger">Achtung</h6>
        <p>Dieser Vorgang kann nicht Rückgängig gemacht werden!</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onRemove} semantic="danger">{t('delete')}</Button>
        <Button onClick={() => setModal(false)} semantic="dark">{t('cancel')}</Button>
      </ModalFooter>
    </Modal>
  )}

DeleteModal.propTypes = {
  item: PropTypes.object,
  cancel: PropTypes.func,
}

export default DeleteModal
