import styled from 'styled-components'

const StyledOptionsHandler = styled.div`
 margin: 0;
 padding: 0;
`
const StyledDatePickerWrapper = styled.div`
 width: 100%;


 .react-datepicker-wrapper {
    width: 100%;
  }
`

const StyledDateTimeSelector = styled.div`
 border: 1px solid hsl(0,0%,80%);
 border-radius: 4px;
 margin: auto;
 color: rgb(128, 128, 128);
 background-color: #ffffff;
 line-height: 35px;
 margin-top: 2em;


.LabelIcon{
  max-width: 18px;
  .icon-clock{
max-width: 18px;
  }
}


.XButton{
margin-bottom: 0;
margin-right: 0;
}

`

const StyledLabel = styled.div`
 padding-left: 10px; 
`


export { StyledOptionsHandler, StyledDateTimeSelector, StyledDatePickerWrapper, StyledLabel }
