import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import ListElement from './SubComponents/ListElement/ListElement'
import moment from 'moment'
import Empty from '../Empty/Empty'
import MoreButton from './MoreButton/MoreButton'
import HubsList from '../HubsList/HubsList'
import { resetSearch } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'
import './List.scss'
import Content from './SubComponents/ListElement/Content/Content'

const AMOUNT_OF_RESULTS = 30

const noContent = ['hubs-archive']

const List = ({ 
  array, 
  searchProperty, 
  sortProperty, 
  idProperty, 
  titleProperty, 
  dateProperty = null, 
  sender = null, 
  sortOrder, 
  content, 
  heading, 
  string = false,
  searchDateProp = 'init'

}) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')

  const hasContent = !noContent.find(c => c === pathname)

  const [openElements, setOpenElements] = useState({ hub: [], content: [] })
  const [count, setCount] = useState(AMOUNT_OF_RESULTS)
  
  const searchValue = useSelector(s => s.search)
 
  const handleClick = (id, section) =>  openElements[section].find(e => +e === +id) 
    ? setOpenElements(oe => ({ ...oe, [section]: openElements[section].filter(_oe => +_oe !== +id) })) 
    : setOpenElements(oe => ({ ...oe, [section]: [...openElements[section], id] }))
    
  useEffect(() => {
    return () => dispatch(resetSearch())    
  }, [dispatch])

  useEffect(() => {setCount(AMOUNT_OF_RESULTS)}, [searchValue])


  const returnContent = (item) => Array.isArray(item[content]) 
    ? `${item[content].map(c => c.username)}`.replace(',', ', ')
    : item[content]

  const filtered = returnListOnSearch(array, searchValue, searchProperty, sortProperty, sortOrder, string, searchDateProp)
    
  return  array && array.length ?
    <>
      <ul className="List mb-0">
        {filtered.slice(0, count).map(item => {

          const open = (section) => !!openElements[section].find(oe => +oe === +item[idProperty])
 
          return (
            <li className="ListItem" key={item[idProperty]}>

              <ListElement  
                key={item[idProperty]} 
                itemId={item[idProperty]} 
                title={item[titleProperty]}
                date={dateProperty && returnDate(item[dateProperty])}
                onClick={handleClick}
                open={open('content')}
                item={item}
                hasContent={hasContent}
              />

           
              {(hasContent && open('content')) && <Content
                isOpen={open('content')}
                content={returnContent(item)}
                path={item.file_path}
                sender={item[sender]}
              />
              }
           
              {open('hub') && <HubsList
                isOpen={open('hub')}
                itemId={item[idProperty]}
                hubs={item.hubs}
                info={item.hubsObjects}
              />
              }

            </li>   
          ) 
        }  
        )}
      </ul> 

      {count < filtered.length && <MoreButton text={t('load.more')} action={() => setCount(count + AMOUNT_OF_RESULTS)}/>}
    </>


    : <Empty title={`${t('none')} ${heading} ${t('available')}.`}/>
        
}


List.propTypes = {
  array: PropTypes.array,
  searchProperty: PropTypes.string,
  sortProperty: PropTypes.string,
  idProperty: PropTypes.string,
  titleProperty: PropTypes.string,
  dateProperty: PropTypes.string,
  sortOrder: PropTypes.number,
  content: PropTypes.string,
  heading: PropTypes.string,
  dateProp: PropTypes.string,
}

export default List

export const  returnListOnSearch = (array, searchValue, searchProperty, sortProperty, sortOrder = 1, string, dateProp) => {
 
  const { text, date, hub } = searchValue
  let filteredByText = text ? array.filter(e => e[searchProperty].toLowerCase().includes(text)) : array
  let filteredByHub = hub ? filteredByText.filter(e => e.hubs.find(h => +h.id === +hub.value)) : filteredByText
  let filteredByDate = filterByDate(date, filteredByHub, dateProp)
  return filteredByDate.sort(compareValueByKey( sortProperty, sortOrder, string))
}

const filterByDate = ({ from, to }, stuff, dateProp) => {
  if (!from && !to) return stuff
  const dfy = (d) => new Date(d)
  if (from) stuff = stuff.filter(s => dfy(s[dateProp]).setHours(0,0,0,0) >= dfy(from))
  if (to) stuff = stuff.filter(s => dfy(s[dateProp]).setHours(0,0,0,0) <= dfy(to))
  return stuff
}

  
export const compareValueByKey = (key, order = 1, string) => 
  (a, b) => order * ( string  
    ? (a[key].toUpperCase() > b[key].toUpperCase()) ? 1 : -1 
    : (+a[key] - +b[key] ) > 0 ? 1 : -1
  )


const returnDate = (stamp) => {
  let utcDate = moment.utc(stamp).toDate()
  let date = moment(utcDate).local().format('DD.MM.YYYY')
  let time = moment(utcDate).local().format('HH:mm')
  return `${date} - ${time}`
}
