import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { StyledContainer, StyledDeleteButton } from './Styles'

import CustomPlayer from './CustomPlayer'


const VoiceRecord = ({ audio, onDelete }) => (
  <StyledContainer>

    <CustomPlayer 
      audio={audio} 
    />
    
    { onDelete && <StyledDeleteButton onClick={onDelete}>
      <FontAwesomeIcon icon={faTrashAlt} size="1x"/>
    </StyledDeleteButton>}

  </StyledContainer>
)

export default VoiceRecord
