import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { StyledImage, StyledFallback } from './Styles'
import BrokenImage from './broken_image.svg'


const Image = ({ path, mode, onClick }) => {

  const [failed, setFailed] = useState(false)

  return failed 
    ? <StyledFallback onClick={onClick}> 
      <img src={BrokenImage} alt="" /> 
    </StyledFallback>

    : <StyledImage mode={mode} src={path} alt="" onError={() => setFailed(true)} onClick={onClick}/>
        
}


Image.propTypes = {
  path: PropTypes.string,
  mode: PropTypes.string,
}
  

export default Image

