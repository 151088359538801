import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { history } from './helpers/history'
import LoginPage from './components/auth/LoginPage'
import LandingPage from './components/landing/LandingPage'
import { authenticate } from 'r_actions/auth.actions'
import Header from './components/mainHeader/Header'
import { getUserData } from 'r_actions/rights.actions'
import ErrorBoundary from './components/ErrorBoundary'

import CreateNews from 'components/SendStuff/news/CreateNews'
import NewsArchive from 'components/SendStuff/news/NewsArchive'
import CreateAlert from 'components/SendStuff/alerts/CreateAlert'
import AlertsArchive from 'components/SendStuff/alerts/AlertsArchive'
import CreateImage from 'components/SendStuff/images/CreateImage'
import ImagesArchive from 'components/SendStuff/images/ImagesArchive'
import HubsArchive from 'components/hubs/HubArchive'
import CreateDocs from 'components/docs/CreateDocs'
import DocsArchive from 'components/docs/DocsArchive'
import CreateVoiceMessage from 'components/SendStuff/voiceMessages/CreateVoiceMessage'
import VoiceMessagesArchive from 'components/SendStuff/voiceMessages/VoiceMessagesArchive'

import ScrollToTop from 'components/ScrollToTop'
import './App.scss'

const App = () => {

  const dispatch = useDispatch()

  const auth = useSelector(({ auth }) => auth)
  const authenticated = !!(auth && auth.userId)
  const rights = useSelector(s => s.rights)

  useEffect(() => {
    if(!authenticated) {
      dispatch(authenticate())
    }
  }, [authenticated, dispatch])


  useEffect(() => {
    if (auth) {
      dispatch(getUserData(auth.userId))
    }
  }, [auth, dispatch])

  return (
    <ErrorBoundary>

      <Router history={history}>
        {authenticated && rights ? (

          <div className="content">
            <ScrollToTop />
                  
            <Header/>
            <div className="app-body">

              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/news-create" component={CreateNews} />
                <Route path="/news-archive" component={NewsArchive} />
                <Route path="/alerts-create" component={CreateAlert} />
                <Route path="/alerts-archive" component={AlertsArchive} />
                <Route path="/images-create" component={CreateImage} />
                <Route path="/images-archive" component={ImagesArchive} />
                <Route path="/hubs-archive" component={HubsArchive} />
                <Route path="/docs-create" component={CreateDocs} />
                <Route path="/docs-archive" component={DocsArchive} />
                <Route path="/voice-create" component={CreateVoiceMessage} />
                <Route path="/voice-archive" component={VoiceMessagesArchive} />
              </Switch>
   
            </div>
          </div>
               
        ) : (
          <Switch>
            <Route path="/" component={LoginPage} />
          </Switch>
        )}
      </Router>

    </ErrorBoundary>
  )
  
}

App.propTypes = {
  dispatch: PropTypes.func
}



export default App


