import React from 'react'
import PropTypes from 'prop-types'
import './InputField.scss'

const InputField = ({ type, property, value, placeholder, onChange, isValid = true, invalidText, title, className, noLabel = false, pattern=null, maxLength='none', disabled=false }) => {
  let isValidClass = isValid === undefined ? '' : isValid === true ? ' is-valid' : ' is-invalid'
  let titleClass = title ? ' title' : ''
  let additionalClasses = className ? ' ' + className : ''
  return (
    <div className={'InputField form-group mb-0 container' + additionalClasses}>
      <input type={type}
        pattern={pattern}
        className={'form-control px-1 ' + property + titleClass + isValidClass}
        name={property}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={onChange} 
        maxLength={maxLength}
        disabled={disabled}
      />
      
      {value !== '' && value !== null && !noLabel ?
        <div className="value-label">
          {placeholder}
        </div> : <div />
      }
      {invalidText && <div className="invalid-feedback">
        {invalidText}
      </div>} 

    </div>
  )
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  invalidText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.bool,
  className: PropTypes.string,
  noLabel: PropTypes.bool,
  pattern: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
}

export default InputField
