import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { StyledModal, StyledCloseButton } from './Styles'
import SimpleLineIcon from 'react-simple-line-icons'
import Image from '../image/Image'
import InfoBlock from './InfoBlock'
import HubsInfo from './HubsInfo'


const ImageModal = ({ selectedImage, close }) => {

  let { file_path, hubs, hubsObjects } = selectedImage

  const [hubInfo, setHubInfo] = useState(null)

  if(hubs && hubsObjects) hubs = hubs.map(h => ({ ...h, info: hubsObjects.find(i => +i.hubID === +h.id) }))

  const stopClosing = e => e.stopPropagation()
  
  return <StyledModal onClick={close}> 

    <StyledCloseButton onClick={close}>
      <SimpleLineIcon 
        name="close" size="large" 
        color="white"
      />
    </StyledCloseButton>

    <Image path={file_path} mode="modal" onClick={stopClosing}/>

    <InfoBlock selectedImage={selectedImage} setHubInfo={setHubInfo} onClick={stopClosing}/>

    {hubInfo && <HubsInfo hubs={hubInfo} onClose={() => setHubInfo(null)}/>}
    

  </StyledModal>
  
}


ImageModal.propTypes = {
  selectedImage: PropTypes.object,
  close: PropTypes.func,
}
  

export default ImageModal

