import React from 'react'
import PropTypes from 'prop-types'
import { ModalFooterStyled } from './Styles'

const ModalFooter = ({ children }) => (
  <ModalFooterStyled className="modal-footer">
    {children}
  </ModalFooterStyled>
)

ModalFooter.propTypes = {
  children: PropTypes.node,
}

export default ModalFooter
