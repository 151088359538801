import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import './ErrorBoundary.scss'


const FallBackUI = ({ resetError }) => {

  const { t } = useTranslation()
  
  return (<>

    <div>
      <div>
        {t('no_match.title')}
      </div>
    </div>
    <div>
      <div className="col">Ein unerwarteter Fehler ist aufgetreten. Wir wurden darüber informiert</div>
      <btn
      // className={'reload-page'}
        className={'col-auto'}
        semantic={'info'}
        onClick={() => {
          resetError()
          window.location.reload()
        }}
      >
  Bitte klicken Sie hier um die Seite neu zuladen. </btn>
    </div>


  </>)}

FallBackUI.propTypes = {
  resetError: PropTypes.func.isRequired,
}

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={meta => (<FallBackUI {...meta} />)}
    // showDialog={true}
    dialogOptions={{
      title: 'Ups! Da ist leider was schief gelaufen!😿😅',
      lang: 'de',
      labelEmail: 'E-Mail (optional)',
      labelSubmit: 'Fehlerbericht senden'
    }}>
    {children}
  </Sentry.ErrorBoundary>)

ErrorBoundary.propTypes = {
  children: PropTypes.element,
}

export default ErrorBoundary



