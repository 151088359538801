import React from 'react'
import PropTypes from 'prop-types'
import useMeasure from 'helpers/hooks/useMeasure'
import { useSpring, a } from 'react-spring'
import { AnimatedContent } from './Styles'
import HubCell from './subs/HubCell'
import { useTranslation } from 'react-i18next'

const HubsList = ({ isOpen, itemId, hubs, info }) => {

  const { t } = useTranslation()

  if(hubs && info) hubs = hubs.map(h => ({ ...h, info: info.find(i => +i.hubID === +h.id) }))
 

  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, transform: `translate3d(${isOpen ? 0 : 20}px,0,0)` }
  })

  return <AnimatedContent style={{ opacity, height: isOpen  ? 'auto' : height }}>
    <a.div className="HubsList" style={{ transform }} {...bind}>
      <div className="hubs-list-wrapper row"> 
   
        <div className="key col-5 col-lg-auto">{t('affected.hubs')}</div>

        <div className="data col row">
          {hubs && hubs.length 
            ? hubs.map(h => <HubCell key={h.id} itemId={itemId} hub={h} boots={ hubs.length > 8 ? 'col-lg-4 col-md-5' : ''} />)
            : <div className="col">{`- ${t('none')}`}</div>
          }
        </div>
       
      </div> 
    </a.div>
  </AnimatedContent>
}

        
  
HubsList.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.string,
  path: PropTypes.string,
  itemId: PropTypes.number,
  hubs: PropTypes.array,
  
}

export default HubsList




