import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'toastr'
import CreateDocsForm from './CreateDocsForm'
import { createDocs, fetchDocs } from 'r_actions/docs.actions'
import { fetchOEM } from 'r_actions/oem.actions'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'


const CreateDocs = () => {

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [data, setData] = useState({
    name: '', 
    file: null,
    submitted: false, 
    oem: null
  })
  const { name, file, oem } = data
  
  
  useEffect(() => {
    dispatch(fetchDocs())
    dispatch(fetchOEM())
  }, [dispatch])


  const docs = useSelector(state => state.docs || [])
  const oems = useSelector(state => state.oem || [])
  const result = useSelector(state => state.result)

  useEffect(() => {
    if(!result) return 
    toastr.success(t('docs.sent'))
    dispatch(setResult(null))
  }, [dispatch, result, t]) 
 
 
  const onChange = (e) => {   
    let { name, value } = e.target   
    setData(d => ({ ...d, [name]: value }))  
  }

  const submitForm = () => {

    let empty =  { name: '', file: null, submitted: false } 
   
    if (!!name && !!file && !!oem) {
      dispatch(createDocs({ file, name, oem: oem.value }))
      return setData(d => ({ ...d, ...empty, filePreviewUrl: '' }))
    }

    if (!file) {toastr.error(t('docs-create.select.file.please'))}
    if (!oem) {toastr.error(t('docs-create.select.oem'))}

    return setData(d => ({ ...d, submitted: true }))
  }

     

  return <CreateDocsForm 
    data={data}
    docs={docs}
    oems={oems}
    onChange={onChange}
    submitForm={submitForm}      

  />
         
}


export default CreateDocs





  
