import React from 'react'
import PropTypes from 'prop-types'
import { ModalStyled } from './Styles'

const Modal = ({ active, className, children, scroll }) => {
  let additionalClasses = className ? ' ' + className : ''
  let activeClass = active ? ' show' : ''
  let scrollable = scroll ? ' pinToNav scroll' : ''
  return (
    <ModalStyled className="Modal">
      <div className={'modal' + activeClass} role="dialog" aria-hidden="true">
        <div className={'modal-dialog modal-dialog-centered modal-dialog-scrollable' + additionalClasses} role="document">
          <div className={'modal-content' + scrollable}>
            {children}
          </div>
        </div>
      </div>
      <div className={'modal-backdrop' + activeClass} />
    </ModalStyled>
  )
}

Modal.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  scroll: PropTypes.bool,
}

export default Modal
