import initialState from './initialState'
import { GET_USER_CONTENT_SUCCESS } from 'r_constants/rights.constants'

export default function result(rights = initialState.rights, action) {
  switch (action.type) {
      
  case GET_USER_CONTENT_SUCCESS:
    return action.userData[0].rights
          
  default:
    return rights
  }
}

