import styled from 'styled-components'

const StyledSelector = styled.div`
 margin-top: 2em;
 position: relative;
 .LabelIcon{
   max-width: 18px;
  .icon-clock{
   max-width: 18px;
  }
}
`
export { StyledSelector  }
