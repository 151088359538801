import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreateAlertForm from './CreateAlertForm'
import toastr from 'toastr'
import { fetchHubs } from 'r_actions/hubs.actions'
import { fetchProperties } from 'r_actions/properties.actions'
import { createAlert } from 'r_actions/alerts.actions'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'


const CreateAlert = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  
  const [data, setData] = useState({ name: '', description: '', planedDate: null, repeat: null, submitted: false, selectedHubs: [], selectedProperties: [] })
  const { name, description, planedDate, repeat, selectedHubs } = data
      
  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchProperties())
  }, [dispatch])

  const hubs = useSelector(s => s.hubs || [])
  const result = useSelector(s => s.result)

  
  useEffect(() => {
    if(!result) return 
    toastr.success(t(result.planedDate ? 'alerts.created' : 'alerts.sent'))
    dispatch(setResult(null))
  }, [data.planedDate, dispatch, result, t]) 



  const onChange = (e) => {   
    let { name, value } = e.target   
    setData(d => ({ ...d, [name]: value }))  
  }

  const onSubmit = async () => {
    let empty =  { name: '', description: '', planedDate: null, repeat: null, selectedHubs: [], selectedProperties: [], submitted: false } 
    if (!!name && !!description && selectedHubs.length) {
      dispatch(createAlert({ name, description, planedDate, repeat, selectedHubs }))
      return setData(d => ({ ...d, ...empty }))
    }
    if (!selectedHubs.length) {toastr.error(t('select-least-hub'))}
    return setData(d => ({ ...d, submitted: true }))
  }


  const setHubs = (sHubs) => setData(d => ({ ...d, selectedHubs: sHubs }))
  const setProperties = (sProperties) => setData(d => ({ ...d, selectedProperties: sProperties }))

            
  return hubs.length 
    ? <CreateAlertForm 
      data={data}
      onChange={onChange}
      onSubmit={onSubmit}
      setHubs={setHubs}
      setProperties={setProperties}
    /> 
    : <div className="notAvailibleMsg">{t('hubs.select.missing')}</div>
     
}


export default CreateAlert


