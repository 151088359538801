import toastr from 'toastr'
import '../../node_modules/toastr/build/toastr.min.css'

toastr.options = {
  'positionClass': 'toast-bottom-left',
  'showDuration': '1',
  'hideDuration': '1',
  'timeOut': '2500',
  'extendedTimeOut': '1',
  'showEasing': 'linear',
  'hideEasing': 'linear',
  'preventDuplicates': true
}

