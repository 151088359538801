export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS'
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE'

export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS'
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE'

export const PATCH_ALERT_SUCCESS = 'PATCH_ALERT_SUCCESS'
export const PATCH_ALERT_FAILURE = 'PATCH_ALERT_FAILURE'

  

