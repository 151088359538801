import React from 'react'
import PropTypes from 'prop-types'
import { ModalBodyStyled } from './Styles'

const ModalBody = ({ children }) => (
  <ModalBodyStyled className="modal-body">
    <div>

      {children}
    </div>
  </ModalBodyStyled>
)

ModalBody.propTypes = {
  children: PropTypes.node,
}

export default ModalBody
