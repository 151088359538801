import React from 'react'
import PropTypes from 'prop-types'
import DropDownSelector from 'components/common/DropDownSelector/DropDownSelector'

const OEMSelector = ({ oem, onChange, oems }) => {

  const oemOptions = oems.map(o => ({ value: o.pid_oem, label: o.company }))

  const selectOem = oem => onChange({ target: { name: 'oem', value: oem } })

  return(
    <div>
      <DropDownSelector
        closeMenuOnSelect={true}
        defaultValue={null}
        value={oem} 
        options={oemOptions}
        placeholderID="docs-create.select.oem"
        icon="organization"
        onChange={e => selectOem(e) }
        noOptionsMessage="docs-create.select.oem.none"
        isClearable={true}
      />
    </div>
  )
}

OEMSelector.propTypes = {
  oem: PropTypes.object,
  onChange: PropTypes.func, 
  oems: PropTypes.array,
}

export default OEMSelector


