import styled from 'styled-components'
import { animated } from 'react-spring'

const FormControlStyled = styled.textarea`
  min-height: ${props => props.minHeight}em;
  border: 2px solid #d9d9d9 !important;
  border-radius: 2;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const InputAreaStyled = styled.div`
  margin: 0;
  padding: 0;
  padding-top: 2em;

  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    background-image: none;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }
`

const ValueLabelStyled = styled(animated.label)`
  color: #aeaeae;
  padding-left: 0.2em;
  font-size: 15px;
`

export { InputAreaStyled, ValueLabelStyled, FormControlStyled }
