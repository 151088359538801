import initialState from './initialState'
import { 
  FETCH_ALERTS_SUCCESS, FETCH_ALERTS_FAILURE,
  CREATE_ALERT_SUCCESS, CREATE_ALERT_FAILURE,
  PATCH_ALERT_SUCCESS, PATCH_ALERT_FAILURE
} from 'r_constants/alerts.constants'

export default function alerts(alerts = initialState.alerts, action) {
  switch (action.type) {
      
  case FETCH_ALERTS_SUCCESS:
    return action.alerts || null

  case FETCH_ALERTS_FAILURE:
    return alerts

  case CREATE_ALERT_SUCCESS:
    return alerts

  case CREATE_ALERT_FAILURE:
    return alerts

  case PATCH_ALERT_SUCCESS:
    return alerts.map(a => +a.id === +action.alert.id 
      ? { ...a, planedDate: action.alert.planedDate, repeat: action.alert.repeat }
      : a 
    )
  
  case PATCH_ALERT_FAILURE:
    return alerts

  default:
    return alerts
  }
}
