import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useMeasure from 'helpers/hooks/useMeasure'
import { useSpring, a, config, useChain } from 'react-spring'
import { Image, AnimatedContent } from './Styles'
import { useTranslation } from 'react-i18next'
import VoiceRecord from 'components/SendStuff/voiceMessages/VoiceRecord'
import PDFViewer from './PDFViewer/PDFViewer'

const Content = ({ isOpen, content, path, sender }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const title = `${t('description')}: `
  const fallback = '-'
  
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, transform: `translate3d(${isOpen ? 0 : 20}px,0,0)` }
  })

  const springRef = useRef()
  const { size, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { size: '20em', PDFSize: '50em', background: 'grey' },
    to: { size: open ? '50em' : '20em', background: 'grey', color: 'white' }
  })
  

  const returnType = () => {
    if(!path) return null
    const indexOfLastDot = path.lastIndexOf('.')
    return path.substring(indexOfLastDot + 1)
  }

  useChain(open ? [springRef ] : [ springRef], [0, open ? 0.1 : 0.6])

  return <AnimatedContent style={{ opacity, height: isOpen  ? 'auto' : height }}>
    <a.div className="content" style={{ transform }} {...bind}>
      <div className="content-wrapper row"> 

        {path && 
        <div className="col-auto" onClick={() => setOpen(open => !open)}>
                 
          { returnType() === 'pdf' //TODO: unTernaryOrFired
            ? <PDFViewer path={path} open={isOpen}/> 
            : returnType() === 'wav' 
              ? <VoiceRecord audio={path}/>
              : <Image src={path} alt="¯\_(ツ)_/¯" style={{ ...rest, maxWidth: size }}/>
          }
   
        </div>}

        { content && <>
          <div className="content-key col-auto">{title}</div>
          <div className="col">{content || fallback}</div>
        </>
        }

        {sender && <div className="content-key col-12">{`${t('sender')}: ${sender.username}`}</div>}
       
      </div> 
    </a.div>
  </AnimatedContent>
}

        
  
Content.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.string,
  sender: PropTypes.object,
  path: PropTypes.string,
   
}

export default Content




