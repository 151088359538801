import styled from 'styled-components'

const StyledWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`

const StyledRow = styled.div`
  max-height: 94.6%;
  overflow-x: hidden;
  overflow-y: auto;
`

const StyledTile = styled.div`
  max-width: 300px;
  margin: 20px;
  box-shadow: 5px #fff;
  cursor: pointer;
`

const StyledImageContainer = styled.div`
  max-width: 250px;
  max-height: 300px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  box-shadow: 4px 10px 10px -5px #000000ad;
  background-color: #000000bf;

  @media (max-width: 768px) {
    max-width: 300px;
    width: 300px;
  }
`

const StyledDescription = styled.div`
  background-color: #000000ad;
  width: 100%;
  color: white;
  padding: 5px;
  position: absolute;
  height: 50px;
  bottom: 0px;
  font-size: 13px;
  overflow: hidden;
`

const StyledImageTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export {
  StyledWrapper,
  StyledRow,
  StyledTile,
  StyledDescription,
  StyledImageContainer,
  StyledImageTitle,
}
