import React from 'react'
import PropTypes from 'prop-types'
import './SearchHeader.scss'
import ByName from './SearchSections/ByName/ByName'
import ByHub from './SearchSections/ByHub/ByHub'
import ByDate from './SearchSections/ByDate/ByDate'

const skipDate = ['hubs-archive']
const skipHub = ['hubs-archive']
const skipName = ['voice-archive']

const SearchHeader = ({ location }) => {

  return (
    <div className="SearchHeader container-fluid">
      <div className="d-flex align-items-center row p-0 m-0">

        <ByName properties="col-lg-3" skip={skipName.includes(location)}/>
        <div className="col-lg"/>
        <ByDate properties="col-lg-3" skip={skipDate.includes(location)}/> 
        <div className="col-lg"/>
        <ByHub properties="col-lg-3" skip={skipHub.includes(location)}/> 

      </div>
    </div>
  )
}

SearchHeader.propTypes = {
  location: PropTypes.string,
}

export default SearchHeader


