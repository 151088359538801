import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import toastr from 'toastr'
import CreateVoiceMessageForm from './CreateVoiceMessageForm'
import { fetchHubs } from 'r_actions/hubs.actions'
import { createVoiceMessages } from 'r_actions/voiceMessages.actions'
import { fetchProperties } from 'r_actions/properties.actions'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'

const CreateVoiceMessage = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const recorderHandlers = useAudioRecorder()

  const { recordingBlob, recordingTime } = recorderHandlers

  const [data, setData] = useState({
    audio: null,
    duration: null,
    selectedHubs: [], 
    selectedProperties: []
  })


  useEffect(() => {

    if (!recordingBlob) return

    const url = URL.createObjectURL(recordingBlob)
    const audio = document.createElement('audio')
    audio.src = url
    audio.controls = true
    document.body.appendChild(audio)
    setData(d => ({ ...d, audio: url }))
    
  }, [recordingBlob, recordingTime])
  

  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchProperties())
  }, [dispatch])

  const hubs = useSelector(state => state.hubs || [])
  const result = useSelector(state => state.result)

  useEffect(() => {
    if(!result) return 
    toastr.success(t('voice.messages.created'))
    dispatch(setResult(null))
  }, [dispatch, result, t]) 

  const onDelete = ()  => setData(d => ({ ...d, audio: null, duration: null }))
 
 
  const onSubmit = async () => {
    let empty =  { audio: null, duration: null, selectedHubs: [], selectedProperties: [] } 

    const audioFile = await new File([recordingBlob], 'voice.wav', { type: 'audio/wav' })

    const file = { buffer: audioFile, mimetype: 'audio/wav', type: 'wav', }

    if (!!data.audio && data.selectedHubs.length) {
      dispatch(createVoiceMessages({ file, voiceMessagesPackage: { ...data, file } }))
      onDelete()
      return setData(d => ({ ...d, ...empty }))
    }
    
    if (!data.audio) {toastr.error(t('voice.messages.create.select.file.please'))}
    if (!data.selectedHubs.length) {toastr.error(t('select-least-hub'))}
  }

  const setHubs = (sHubs) => setData(d => ({ ...d, selectedHubs: sHubs }))
  const setProperties = (sProperties) => setData(d => ({ ...d, selectedProperties: sProperties }))
  const setDuration = (duration) => setData(d => ({ ...d, duration }))
      
  return <CreateVoiceMessageForm 
    {...{
      data, 
      onDelete,
      recorderHandlers, 
      onSubmit, 
      hubs, 
      setHubs, 
      setProperties,
      setDuration 
    }}
  />
}


export default CreateVoiceMessage





  
