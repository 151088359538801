import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DropDownSelector from 'components/common/DropDownSelector/DropDownSelector'
import { setMissing } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'

import { formedHubsOptions, filteredHubsOptions, filteredPropertiesOptions, sortHubsByLabel } from 'components/SendStuff/_helpers/formSelectorOptions'


const PropertiesHubsSelector = ({ data, setHubs, setProperties  }) => {
  const dispatch = useDispatch ()

  const properties = useSelector(s => s.properties || [])
  const hubs = useSelector(s => s.hubs || [])

  const { t } = useTranslation()

  const selectAllOption = { value: '*', label: t('select.all'), bgColor: '#6e6e6e' }
  
  const { selectedHubs, selectedProperties } = data

  useEffect(() => {
    !selectedProperties.length &&  dispatch(setMissing(null))
  }, [dispatch, selectedProperties])
  

  const onHubSelect = (e) => {

    e.find(ev => ev.value === '*') 
      ? setHubs(sortHubsByLabel(formedHubsOptions(hubs)))
      : setHubs(sortHubsByLabel(e))
    
    let isMissing = selectedProperties.reduce((acc, sp) => {
      const missingHubs = sp.hubs.filter(h => !e.find(ee => +ee.value === +h.value))
      return missingHubs.length 
        ? { ...acc, [sp.value]: missingHubs } 
        : acc
    }, {})

    const colorOptions = id => ({ bgColor: isMissing && isMissing[id] ? '#f4d8b5' : '#e7e7e7' })
    

    const filteredProperties = selectedProperties.reduce((acc, sel) => {
      return isMissing[sel.value] && sel.hubs.length === isMissing[sel.value].length 
        ? acc 
        : [...acc, { ...sel, ...colorOptions(sel.value) }]
    } , [])

    isMissing = Object.keys(isMissing).length ? isMissing : null 
    setProperties(filteredProperties)
    dispatch(setMissing(isMissing))
  }

  const onPropertySelect = (e) => {
    const removed = selectedProperties.find(sp => !e.find(ee => +ee.value === +sp.value))
    const added = e.find(ee => !selectedProperties.find(sp => +sp.value === +ee.value))

    setProperties(e)
    handleHubsOnProperty(added, removed)
  }

  const handleHubsOnProperty = (added, removed) => {
    added && setHubs([...selectedHubs, ...added.hubs])
    removed && setHubs([...selectedHubs.filter(sh => !removed.hubs.find(r => +r.value === +sh.value))])
  }

  return(
    <>
      {properties && properties.length 
        ? <DropDownSelector
          isMulti
          selected={selectedProperties}
          options={filteredPropertiesOptions(properties, selectedProperties, hubs)} 
          placeholderID="properties.select.placeholder"
          icon="home"
          onChange={onPropertySelect}
          customMessage={t('properties.select.missing')}
        /> 
        : <></>
      }
                   
      <DropDownSelector
        isMulti
        selected={selectedHubs}
        options={[selectAllOption, ...filteredHubsOptions(hubs, selectedHubs)]} 
        placeholderID="hubs.select.placeholder"
        icon="grid"
        onChange={onHubSelect}
        customMessage={t('hubs.select.missing')}
      /> 
    </>
  )
}

PropertiesHubsSelector.propTypes = {
  data: PropTypes.object, 
  setHubs: PropTypes.func,
  setProperties: PropTypes.func,
  
}

export default PropertiesHubsSelector

