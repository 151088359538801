import React from 'react'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'

const InfoItemHeader = ({ icon, text }) => {

  return  (
    <h6 className="dropdown-header d-flex align-items-center col">
      <SimpleLineIcon  
        style={{ marginRight: '5px' }}
        name={icon} 
        size="medium" 
  
      />
      {text || '-'}
    </h6> 
  )
}

InfoItemHeader.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string
}

export default InfoItemHeader

