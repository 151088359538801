import React from 'react'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import { Icon } from './Styles'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


const IconWithTooltip = ({ name, itemId, message, onClick }) => {

  return (

    <Icon 
      id={`ListIconWithTooltip-${itemId}-${name}`}
      className="col-auto" 
      onClick={onClick}

    >
      <SimpleLineIcon  name={name} size="large" color="grey" />

      <Tooltip 
        anchorId={`ListIconWithTooltip-${itemId}-${name}`}
        content={message} 
        place="left"
      />
    </Icon>
   
  )
}

IconWithTooltip.propTypes = {
  name: PropTypes.string, 
  message: PropTypes.string, 
  onClick: PropTypes.func
  
}

export default IconWithTooltip


