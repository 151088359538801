import React from 'react'
import PropTypes from 'prop-types'
import InputField from 'components/common/InputField/InputField'
import PDFViewer from '../common/SearchList/SubComponents/ListElement/Content/PDFViewer/PDFViewer'
import FileSelector from '../SendStuff/_common/FileSelector'
import SubmitButton from 'components/common/SubmitButton/SubmitButton'
import OEMSelector from './OEMSelector'
import { useTranslation } from 'react-i18next'

import './Docs.scss'
const accept = 'application/pdf'


const CreateDocsForm = ({ data, onChange, submitForm, oems }) => {
  const { name, submitted, file, oem } = data

  const { t } = useTranslation()

  const path = file && file.filePreview

  return(
    <div className="EditDocsForm container">
      <div className="docs-title col-12">{t('docs.create')}</div>
      <div className="borderFrame">
        <div className="docs-form col-lg-8 offset-lg-2">
      
          <InputField className="field"
            type="text"
            property="name"
            placeholder={t('docs.create.title' )}
            isValid= {!!name || !submitted } 
            value={name}
            onChange={onChange}
            invalidText={t('docs.create.title.required')}
            maxLength="20"
          />

          <div className="ViewWrapper">
            {path && <PDFViewer path={path} open={true}/> }
          </div>

   
          <FileSelector 
            file={file}
            onChange={onChange}
            accept={accept}
            additionalMimes={['application/pdf']}
          />

          <OEMSelector 
            selected={oem}
            oems={oems}
            onChange={onChange}            
          /> 

          <SubmitButton onClick={submitForm}/>
  
        </div>         
      </div>
     
    </div>


  )
}

CreateDocsForm.propTypes = {
  data: PropTypes.object,
  hubs: PropTypes.array,
  oems: PropTypes.array,
  onChange: PropTypes.func, 
  submitForm: PropTypes.func,
  setHubs: PropTypes.func, 
  setProperties: PropTypes.func, 
}

export default CreateDocsForm


