import feathers from '@feathersjs/client'
import io from 'socket.io-client'
import config from '../helpers/api_config'

const socket = io(config.feathersAPI)

const app = feathers()
  .configure(feathers.socketio(socket,{ timeout: 10000 }))
  .configure(feathers.authentication({ storage: localStorage }))

// feathers services
export const ALERTS = app.service('manual-alerts')
export const NEWS = app.service('news')
export const IMAGES = app.service('images')
export const HUBS = app.service('oem-hubs-cockpit')
export const PROPERTIES = app.service('properties')
export const USER_CONTENT = app.service('user-content')
export const DOCS = app.service('docs')
export const FOREIGN_AUTH = app.service('foreign-login')
export const OEM = app.service('oem')
export const I18N_PROJECTS = app.service('i18n-projects')
export const USER_PROFILE = app.service('user-profile')
export const VOICE_MESSAGES = app.service('voice-messages')


export default app
