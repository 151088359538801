import initialState from './initialState'
import { LOAD_I18N_INFO_SUCCESS, SWITCH_LANGUAGE } from 'r_constants/i18n.constants'

export default function i18n(i18n = initialState.i18n, action) {
  switch (action.type) {

  case LOAD_I18N_INFO_SUCCESS:
    return { ...i18n, info: action.i18n }

  case SWITCH_LANGUAGE:
    return { ...i18n, language: action.lng }

  default:
    return i18n
  }
}
