import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import initialState from './initialState'
import { 
  FETCH_IMAGES_SUCCESS, FETCH_IMAGES_FAILURE,
  CREATE_IMAGES_SUCCESS, CREATE_IMAGES_FAILURE
} from 'r_constants/images.constants'

export default function images(images = initialState.images, action) {
  switch (action.type) {
      
  case FETCH_IMAGES_SUCCESS:
    return action.images 
      ? formImages(action.images) 
      : null

  case FETCH_IMAGES_FAILURE:
    return images

  case CREATE_IMAGES_SUCCESS:
    return images

  case CREATE_IMAGES_FAILURE:
    return images

  default:
    return images
  }
}


const formImages = (images) => {

  const formDate = (date, form) => format(new Date(date), form, { locale: de })
 
  return images.map(i => { 

    return { ...i, 
      formedDate: formDate(i.init, 'dd.MM.yyyy HH:mm')
    }
  
  })
}