import React, { useRef } from 'react'
import { useTransition, useChain } from 'react-spring'
import { useHistory  } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Card, CardTitle, Item, Description, Content, Hint } from './Styles'
import { Icons } from 'constants/navIcons'
 

const NavCard = ({ selected, setSelected, section }) => {

  const history = useHistory()
  const open = selected === section.id

  const transRef = useRef()
   
  const transitions = useTransition(open ? section.items : [], item => item.name, {
    ref: transRef, //!importanto => no new items on fast clicks
    unique: true,
    trail: 200 / section.items.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  })

  const navigate = (name) =>  history.push(`/${name}`)

  useChain( [transRef], [open ? 0.1 : 0])

  const additional = open ? {} : { gridTemplateRows: 'none' }

  return ( <Card className="col-lg-5 col-sm-12" style={ additional } onClick={() => setSelected(section.id)}>

    <CardTitle className=" d-flex align-items-center">
      <img src={Icons[section.id]} alt="" style={{ marginRight: '5px' }}/>   
      {section.title}
    </CardTitle>

    <Content>
      { 
        open 
          ? transitions.map(({ item, key, props }) => <Item 
            key={key} 
            style={{ ...props }} 
            onClick={() => navigate(key)}
          > 
            {item.title} 
          </Item>) 
          : <Description>{section.description}</Description> 
      }
    </Content>

    { section.hint && <Hint>{section.hint}</Hint> }

  </Card>


  )
}


NavCard.propTypes = {
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  section: PropTypes.object,
}
  
  
export default NavCard

