import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'toastr'
import CreateNewsForm from './CreateNewsForm'
import { fetchHubs } from 'r_actions/hubs.actions'
import { createNews } from 'r_actions/news.actions'
import { fetchProperties } from 'r_actions/properties.actions'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'


const CreateNews = () => {

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [data, setData] = useState({
    name: '', 
    content: '', 
    selectedHubs: [], 
    selectedProperties: [],
    file: null,
    submitted: false, 
  })
  const { name, content, selectedHubs, file } = data
  
  
  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchProperties())
  }, [dispatch])


  const hubs = useSelector(state => state.hubs || [])
  const result = useSelector(state => state.result)

  useEffect(() => {
    if(!result) return 
    toastr.success(t('news.sent'))
    dispatch(setResult(null))
  }, [dispatch, result, t]) 
 
 
  const onChange = (e) => {   
    let { name, value } = e.target   
    setData(d => ({ ...d, [name]: value }))  
  }

  const submitForm = async () => {
    let empty =  { name: '', content: '', selectedHubs: [], selectedProperties: [], file: null, submitted: false } 

   
    const newsPackage = { title: name, content, selectedHubs, file }

    if (!!name && selectedHubs.length && (!!content || !!file)) {
      dispatch(createNews({ file, newsPackage }))
      return setData(d => ({ ...d, ...empty, filePreviewUrl: '' }))
    }

    if (!content && !file) {
      toastr.error(t('news-create.content-or-file'))
    }
    
    if (!selectedHubs.length) {
      toastr.error(t('select-least-hub'))
    }
    return setData(d => ({ ...d, submitted: true }))
  }


  const setHubs = (sHubs) => setData(d => ({ ...d, selectedHubs: sHubs }))
  const setProperties = (sProperties) => setData(d => ({ ...d, selectedProperties: sProperties }))
    
              

  return hubs.length 
    ? <CreateNewsForm 
      data={data}
      hubs={hubs}
      onChange={onChange}
      submitForm={submitForm}      
      setHubs={setHubs}
      setProperties={setProperties}
          
    />
    : <div className="notAvailibleMsg">{t('hubs.select.missing')}</div>
         
}


export default CreateNews





  
