import React from 'react'
import SelectedProperty from '../../../helpers/SelectedProperty'


export const formedHubsOptions = (hubs) => hubs.map(h => ({ value: h.id, label: h.description, ccid: h.ccid, users: h.users }))
  
export const filteredHubsOptions = (hubs, selectedHubs) => formedHubsOptions(hubs)
  .filter((itm) => selectedHubs.map(sh => sh.value).indexOf(itm.value) === -1)

export const formedPropertiesOptions = (properties, hubs) => {

  properties = addHubsToProperties(properties, hubs)
  return properties.map(p => ({ 
    value: p.id, 
    label: <SelectedProperty propId={p.id} description={`${p.name} (${p.street})`}/>, 
    hubs: p.hubs, 
    bgColor: '#e7e7e7' }))
}

export const filteredPropertiesOptions = (properties, selectedProperties, hubs) => formedPropertiesOptions(properties, hubs)
  .filter((itm) => selectedProperties.map(sp => sp.value).indexOf(itm.value) === -1) 

export const sortHubsByLabel = (hubs) => {
  return hubs.sort((a, b) => {
    const isNumberA = /^\d/.test(a.label) 
    const isNumberB = /^\d/.test(b.label) 
    
    if (isNumberA && !isNumberB) return -1 
    if (!isNumberA && isNumberB) return 1 
    
    return a.label.localeCompare(b.label)
  })
}

const addHubsToProperties = (properties, hubs) => {

  //TODO: refactor on a brainy day...

  const users = hubs.reduce((acc, h) => {

    const thisHub =  { value: h.id, label: h.description, ccid: h.ccid, users: h.users }

    const usersInHub = h.users.reduce((acc, u) => { 

      const reducedProps = { id: u.id, username: u.username }

      const thisUser = { [u.id]: { ...reducedProps, 
        hubs: acc[u.id] && acc[u.id].hubs
          ? [...acc[u.id].hubs.filter(hub => +hub.id !== +thisHub.id), thisHub ] 
          : [thisHub]   
      } }

      return { ...acc, ...thisUser }
    }, {})
    return { ...acc, ...usersInHub }
  }, {})


  return properties.map(p => { 
    return { ...p, 
      hubs: p.users.map(pu => users[pu.userID]?.hubs).filter(Boolean).flat() 
    }
  })

}


