import { 
  FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILURE,
  CREATE_NEWS_SUCCESS, CREATE_NEWS_FAILURE
} from 'r_constants/news.constants'

import { _fetchNews, _createNews } from 'r_services/news.service'

export const fetchNews = () => async dispatch => {
  try {
    const news = await _fetchNews()
    dispatch({ type: FETCH_NEWS_SUCCESS, news })
  } catch (error) {
    dispatch({ type: FETCH_NEWS_FAILURE, error })
  }
}

export const createNews = (_news) => async dispatch => {
  try {
    const news = await _createNews(_news)
    dispatch({ type: CREATE_NEWS_SUCCESS, news })
  } catch (error) {
    dispatch({ type: CREATE_NEWS_FAILURE, error })
  }
}


