import { 
  FETCH_IMAGES_SUCCESS, FETCH_IMAGES_FAILURE,
  CREATE_IMAGES_SUCCESS, CREATE_IMAGES_FAILURE
} from 'r_constants/images.constants'
  
import { _fetchImages, _createImages } from 'r_services/images.service'
  
export const fetchImages = () => async dispatch => {
  try {
    const images = await _fetchImages()
    dispatch({ type: FETCH_IMAGES_SUCCESS, images })
  } catch (error) {
    dispatch({ type: FETCH_IMAGES_FAILURE, error })
  }
}
  
export const createImages = (_images) => async dispatch => {
  try {
    const images = await _createImages(_images)
    dispatch({ type: CREATE_IMAGES_SUCCESS, images })
  } catch (error) {
    dispatch({ type: CREATE_IMAGES_FAILURE, error })
  }
}
  
  
  