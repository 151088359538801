import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InputAreaStyled, ValueLabelStyled, FormControlStyled } from './Styles'
import { useSpring } from 'react-spring'
import { useTranslation } from 'react-i18next'

const InputArea = ({ type, property, value, placeholder, onChange, isValid, invalidText, title, className, noLabel = false, pattern=null, maxLength=null, minHeight='15' }) => {
  let isValidClass = isValid === undefined ? '' : isValid === true ? ' is-valid' : ' is-invalid'
  let titleClass = title ? ' title' : ''
  let additionalClasses = className ? ' ' + className : ''

  const { t } = useTranslation()

  
  const [state, toggle] = useState(false)
  const { x } = useSpring({ from: { x: 0 }, x: state ? 1 : 0, config: { duration: state ? 1000 : 0 } })

  useEffect(() => {
    if ( +value.length <= +maxLength) toggle(false)
    if ( +value.length >= +maxLength) toggle(true)
  }, [maxLength, state, value.length])

  return (
    <InputAreaStyled className={'InputArea form-group mb-0 container' + additionalClasses}>
      <FormControlStyled 
        type={type}
        pattern={pattern}
        className={'form-control px-1 ' + property + titleClass + isValidClass}
        name={property}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={onChange} 
        maxLength={maxLength}
        minHeight={minHeight}
      />
      
      {value !== '' && value !== null && !noLabel 
        ? <ValueLabelStyled className="value-label"
          style={{ 
            color: x.interpolate({ range: [0, 1], output: ['#aeaeae', '#c21515'] }),
            transform: x.interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
              .interpolate(x => `scale(${x})`)
          }}
        >
          {value.length < maxLength 
            ? t('input.area.chars.left', { chars: value.length, max: maxLength })
            : t('input.area.chars.max', { max: maxLength })

          }
        </ValueLabelStyled> 
        : <div />

      }
      <div className="invalid-feedback">
        {invalidText}
      </div> 

    </InputAreaStyled>
  )
}

InputArea.propTypes = {
  type: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  invalidText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.bool,
  className: PropTypes.string,
  noLabel: PropTypes.bool,
  pattern: PropTypes.string,
  maxLength: PropTypes.number,
  minHeight: PropTypes.string,
}

export default InputArea
