import { animated } from 'react-spring'
import styled from 'styled-components'

const Container = styled.div`
  min-height: 1000px;
`

const NavBucket = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`


const Title = styled.div`
  padding: 20px;
  font-size: 30px;
  color: #343a40;
  font-weight: bold;
`

const Card = styled.div`
  background-color: #363A3F;
  position: relative;
  padding: 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 4px 10px 10px -5px #000000ad;
  will-change: width, height;
  min-height: 250px;
  &:hover{
      background: #2c3035;
  }
`

const CardTitle = styled.div`
  color: white;
  font-size: 22px;
  line-height: 20px;
  padding-bottom: 20px;
`

const Content = styled.div`
  height: 75%;
  display: block;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

const Description = styled.div`
  color: #e2e2e2;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 20px;
`

const Item = styled(animated.div)`
  line-height: 55px;
  height: 55px;
  text-align: center;
  background: #4E515C;
  color: white;
  border-radius: 5px;
  will-change: transform, opacity;
  margin: 5px;

  &:hover {
    background: #63666e;
  }
`

const Hint = styled.div`
color: #f84535;
text-align: end;
font-size: 15px;

`



export {
  Container,
  NavBucket,
  Title,
  CardTitle,
  Description,
  Card,
  Content,
  Item,
  Hint
}
