import React from 'react'
import PropTypes from 'prop-types'
import './ListElement.scss'
import { DeleteModal,AlertSettingsModal } from 'components/common/Modal'

const returnModal = (props, pathname) => 
  pathname === 'docs-archive' 
    ? <DeleteModal {...props}/>
    : <AlertSettingsModal {...props}/> 


const ModalHandler = ({ modal, pathname, ...props }) => {

  return (
    <>
      {modal && returnModal(props, pathname)}
    </>

  )
}

ModalHandler.propTypes = {
  modal: PropTypes.bool,
  setModal:PropTypes.func,
  item: PropTypes.object,
  pathname: PropTypes.string
}

export default ModalHandler


