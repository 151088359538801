import styled from 'styled-components'

const StyledDateLine = styled.div`
  margin-right: 5px;
  padding: 0;
  
  .react-datepicker-wrapper {
    width: 100%;
  }
`

const StyledInput = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
`

const CalIcon = styled.div``

const CloseIcon = styled.div`
  min-width: 18px;
  &:hover {
    color: grey;
  }
`

const Label = styled.div`
  width: 100%;
  text-align: center;
`

export { StyledDateLine, StyledInput, CalIcon, CloseIcon, Label }
