import { combineReducers } from 'redux'

import auth  from './auth.reducer'
import news from './news.reducer'
import images from './images.reducer'
import alerts from './alerts.reducer'
import hubs from './hubs.reducer'
import properties from './properties.reducer'
import search from './search.reducer'
import result from './result.reducer'
import rights from './rights.reducer'
import missing from './missing.reducer'
import docs from './docs.reducer'
import oem from './oem.reducer'
import i18n from './i18nReducer'
import voiceMessages from './voiceMessages.reducer'

import { LOGOUT_SUCCESS } from 'r_constants/auth.constants'

import initialState from './initialState'

const appReducer = combineReducers({
  auth,
  news,
  images,
  alerts,
  hubs,
  properties,
  search,
  result,
  rights,
  missing,
  docs,
  oem,
  i18n,
  voiceMessages,
})


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = initialState 
    sessionStorage.clear()
  }
  return appReducer(state, action)
}

export default rootReducer