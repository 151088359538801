import {
  LOGIN_FAILURE, LOGIN_SUCCESS,
  LOGOUT_SUCCESS, LOGOUT_FAILURE,
  AUTH_SUCCESS, AUTH_ERROR,
  FOREIGN_LOGIN_SUCCESS, FOREIGN_LOGIN_ERROR

} from 'r_constants/auth.constants'

import toastr from 'toastr'

import { _login, _logout, _authenticate, _foreignLogin } from '../r_services/auth.service'
import { setUser } from '@sentry/browser'


export const login = (user, wrongPass, wrongUser) => async dispatch => {
  try {
    const payload = await _login(user)
    setUser(payload)
    dispatch({ type: LOGIN_SUCCESS, payload })

  } catch (error) {
    const { message } = error
    if (message === 'Password incorrect') toastr.error(wrongPass)
    if (message === 'Invalid login') toastr.error(wrongUser)
    dispatch({ type: LOGIN_FAILURE, error })
  }
}

export const authenticate = () => async dispatch => {
  try {
    const payload = await _authenticate()
    dispatch({ type: AUTH_SUCCESS, payload })
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
}


export const logout = expired => async dispatch => {
  try {
    const status = await _logout()
    dispatch({ type: LOGOUT_SUCCESS, status, expired })
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE, error })
  }

}

export const foreignLogin = (username) => async dispatch => {
  try {
    const token = await _foreignLogin({ query: username })
    dispatch({ type: FOREIGN_LOGIN_SUCCESS, token })
  } catch (error) {
    dispatch({ type: FOREIGN_LOGIN_ERROR, error })
  }

}
