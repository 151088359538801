import sha512 from 'js-sha512'
import app from './feathers'
import Cookies from 'js-cookie'

import { FOREIGN_AUTH } from './feathers'

export const _login = async ({ username, password }) => {
  const authResult = await app.authenticate({
    username,
    password: sha512(password),
    strategy: 'local'
  })
  return parseAuth(authResult)
}


export const _authenticate = async () => {
  try {
    const authResult = await app.reAuthenticate()
    return parseAuth(authResult)
  } catch (error) {
    return null
  }
}

export const _logout = async () => {
  await app.logout()
  return { logout: 'success' }
}


const parseAuth = async authResult => {
  try {
    const { accessToken, authentication: { payload }, user } = authResult
    Cookies.set('feathers-jwt', accessToken, { expires: 1 })
    
    return { ...payload, user } || JSON.parse(atob(accessToken.split('.')[1]))
  } catch (error) {
    return null
  }
}

export const _foreignLogin = async (id) => await FOREIGN_AUTH.find(id)