import { NEWS } from './feathers'
// import { checkSent } from './userMessages'

export const _fetchNews = async () => {
  const news = await NEWS.find({ query: { $sort: { id: -1 } } })
  return news?.data || news
}

export const _createNews = async ({ file, newsPackage }) => await NEWS.create(newsPackage, { file })




