import initialState from './initialState'
import { SET_MISSING } from 'r_constants/noApi.constants'

export default function search(missing = initialState.missing, action) {
  switch (action.type) {
      
  case SET_MISSING:
    return action.missing

  default:
    return missing
  }
}


 