import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { StyledProgressBar, StyledPlayButton } from './Styles'

const CustomPlayer = ({ audio }) => {

  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [timeProgress, setTimeProgress] = useState(0)
 
  const audioRef = useRef()
  const progressBarRef = useRef()
  const playAnimationRef = useRef()

  const repeat = useCallback(() => {

    if(!audioRef.current) return

    const currentTime = audioRef.current.currentTime
    setTimeProgress(currentTime)
    progressBarRef.current.value = currentTime
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / duration) * 100}%`
    )
    playAnimationRef.current = requestAnimationFrame(repeat)
  }, [audioRef, duration, progressBarRef, setTimeProgress])

  useEffect(() => {
    isPlaying 
      ? audioRef.current.play()
      : audioRef.current.pause()

    playAnimationRef.current = requestAnimationFrame(repeat)
  }, [isPlaying, audioRef, repeat])

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration
    progressBarRef.current.max = seconds
    setDuration(seconds)
  }

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev)
  }

  const handleProcessBarChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value
  }
  
  const formatTime = (time) => {
    const formatUnits = units => units < 10 ? `0${units}` : `${units}`

    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60)
      const seconds = Math.floor(time % 60)
      return `${formatUnits(minutes)}:${formatUnits(seconds)}`
    }
    return '00:00'
  }

  const handleEnd = () => {
    setIsPlaying(false)
    audioRef.current.currentTime = ''
    progressBarRef.current.value = ''
  }


  return (
    <>
      <StyledPlayButton onClick={togglePlayPause}>
        <FontAwesomeIcon icon={isPlaying ? faPause: faPlay} size="1x" />
      </StyledPlayButton>

      <StyledProgressBar> 
        <span>
          {formatTime(timeProgress)}
        </span>

        <input
          type="range"
          step="0.001"
          ref={progressBarRef}
          defaultValue="0"
          onChange={handleProcessBarChange}
        /> 

        <span>
          {formatTime(duration)}
        </span>
      </StyledProgressBar>   


      <audio
        src={audio}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleEnd}
      />
    </>
  )
}
export default CustomPlayer
