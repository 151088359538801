import { 
  FETCH_ALERTS_SUCCESS, FETCH_ALERTS_FAILURE,
  CREATE_ALERT_SUCCESS, CREATE_ALERT_FAILURE,
  PATCH_ALERT_SUCCESS, PATCH_ALERT_FAILURE
} from 'r_constants/alerts.constants'

import { _fetchAlerts, _createAlert, _patchAlert } from 'r_services/alerts.service'

export const fetchAlerts = () => async dispatch => {
  try {
    const alerts = await _fetchAlerts()
    dispatch({ type: FETCH_ALERTS_SUCCESS, alerts })
  } catch (error) {
    dispatch({ type: FETCH_ALERTS_FAILURE, error })
  }
}

export const createAlert = (_alert) => async dispatch => {
  try {
    const alert = await _createAlert(_alert)
    dispatch({ type: CREATE_ALERT_SUCCESS, alert })
  } catch (error) {
    dispatch({ type: CREATE_ALERT_FAILURE, error })
  }
}

export const patchAlert = (_alert) => async dispatch => {
  try {
    const alert = await _patchAlert(_alert)
    dispatch({ type: PATCH_ALERT_SUCCESS, alert })
  } catch (error) {
    dispatch({ type: PATCH_ALERT_FAILURE, error })
  }
}


