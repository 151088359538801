import React from 'react'
import { PropTypes } from 'prop-types'
import { Navigation, Title, Button } from './Styles'
import SimpleLineIcon from 'react-simple-line-icons'

const PDFNavigation = ({ pages, activePage, onNavigate }) => {

  return (

    <Navigation className="row">
      
      { +activePage <= 1 ? <Title className="col-5"/>
        : <Button className="col-5" onClick={() => onNavigate()}>
          <SimpleLineIcon  name="arrow-left" size="medium" />
        </Button>}

      <Title className="col-2">{`${activePage} / ${pages}`}</Title>

      {+activePage >= +pages ? <Title className="col-5"/>
        : <Button className="col-5" onClick={() => onNavigate(1)}>
          <SimpleLineIcon  name="arrow-right" size="medium" />
        </Button>}

    </Navigation> 
      
  )

}


PDFNavigation.propTypes = {
  pages: PropTypes.number,
  activePage: PropTypes.number,
  onNavigate: PropTypes.func,
}

export default PDFNavigation


