import initialState from './initialState'
import { 
  FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILURE,
  CREATE_NEWS_SUCCESS, CREATE_NEWS_FAILURE
} from 'r_constants/news.constants'

export default function news(news = initialState.news, action) {
  switch (action.type) {
      
  case FETCH_NEWS_SUCCESS:
    return action.news || null

  case FETCH_NEWS_FAILURE:
    return news

  case CREATE_NEWS_SUCCESS:
    return news

  case CREATE_NEWS_FAILURE:
    return news

  default:
    return news
  }
}
