import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './Styles'

const MoreButton = ({ text, action }) => {
 
  return (
    <StyledButton onClick={action}>
      {text}
    </StyledButton>
      
  )
}

MoreButton.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func
}

export default MoreButton
