import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useTranslation } from 'react-i18next'

const Selector = ({ selected, options, onChange, placeholderID, noOptionsID }) => {

  const { t } = useTranslation()

  const customStyles = {
    control: (provided, state) => ({
      ...provided, 
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'hsl(0,0%,80%)',
      },
      border: state.isFocused ? '1px solid hsl(0,0%,80%)' : '1px solid hsl(0,0%,80%)'
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
   
  }

  return <div> 
    <Select className="Selector"
      closeMenuOnSelect={true}
      components={makeAnimated()}
      defaultValue={null}
      value={selected} 
      options={options}
      placeholder={t(placeholderID)}
      onChange={e => onChange(e) }
      noOptionsMessage={() => t(noOptionsID)}
      isClearable={true}
      styles={customStyles}
    />
  </div>
}


Selector.propTypes = {
  selected: PropTypes.object, 
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholderID: PropTypes.string,
  noOptionsID: PropTypes.string,

}
  
  
export default Selector