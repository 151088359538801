import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'

const Button = ({ className, id, active, semantic='primary', type='button', title='', outline=false, inverted=false, onClick, disabled=false, children }) => {
  let classes = 'Button btn '
  semantic = disabled ? 'secondary' : semantic

  classes += inverted
    ? `btn-link text-${semantic}`
    : outline
      ? `btn-outline-${semantic}`
      : disabled
        ? `btn-${semantic}`
        : `btn-${semantic} text-white`

  classes += active ? ' active' : ' inactive'
  classes += className ? ' ' + className : ''

  return (
    <button
      type={type}
      value={id}
      title={title}
      className={classes}
      disabled={disabled}
      onClick={() => onClick(onClick)}>
      {children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  inverted: PropTypes.bool,
  semantic: PropTypes.string,
  outline: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Button
