import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'r_actions/auth.actions'
import { useTranslation } from 'react-i18next'
import { StyledHeaderMenu, StyledList, StyledRow } from './Styles'
import ListItemHeader from './ListItemHeader'
import InfoItemHeader from './InfoItemHeader'
import LanguagePicker from '../LanguagePicker/LanguagePicker'
import { onClickOutside } from 'helpers/hooks'
import SenderModal from '../../landing/SenderModal'
import SimpleLineIcon from 'react-simple-line-icons'




const HeaderMenu = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(s => s.auth)
  const { username, sender } = user
  const { t } = useTranslation()

  const [showOptions, setShowOptions] = useState(false)
  const [modal, setModal] = useState(false)
  
  const ref = onClickOutside(() => setShowOptions(false))

  const logMeOut = () => dispatch(logout())

  return  (
    <>
      <StyledHeaderMenu className="btn user-btn" onClick={() => setShowOptions(!showOptions)} data-toggle="dropdown">
        <SimpleLineIcon  
          name="user"
          color="white"
          style={{ 'fontSize': '20px' }}
        />
      </StyledHeaderMenu>
      {
        showOptions && <StyledList ref={ref} className={'dropdown-menu dropdown-menu-right border-0 shadow show'}>

          <StyledRow className="row">
            <div className="col">
              <InfoItemHeader text={username} icon="user"/>
              <InfoItemHeader text={sender} icon="paper-plane"/>
            </div>

            <div className="col">
              <LanguagePicker />
            </div>
          </StyledRow>
     
          <div className="dropdown-divider" />

          <ListItemHeader text={t('sender.edit')} icon="pencil" action={() => setModal(true)}/>
          <ListItemHeader text={t('logout')} icon="logout" action={() => logMeOut()}/>

        </StyledList>
      }
      { modal && <SenderModal user={user} cancel={() => setModal(false)}/> }
    </>
  )
}

export default HeaderMenu

