import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import 'react-tooltip/dist/react-tooltip.css'

import './Info.scss'

const PropertyInformation = ({ missing }) => {

  const { t } = useTranslation()

  const message = t(
    Object.keys(missing).length > 1
      ? 'properties.multi.incomplete'
      : 'properties.one.incomplete'
  )

  return  <>
    <i 
      id="propertyIncompleteInfo"
      className="icon icon-info" 
    />

    <Tooltip 
      anchorId="propertyIncompleteInfo"
      content={message} 
      place="right"
    />
  </>

}

PropertyInformation.propTypes = {
  missing: PropTypes.object
}


export default PropertyInformation