import { 
  GET_USER_CONTENT_SUCCESS, GET_USER_CONTENT_ERROR
} from 'r_constants/rights.constants'
  
import { _getUserContent } from 'r_services/rights.service'
    
export const getUserData = (id) => async dispatch => {
  const userData = await _getUserContent(id).catch(error => dispatch({ type: GET_USER_CONTENT_ERROR, error }))
  !userData.error && dispatch({ type: GET_USER_CONTENT_SUCCESS, userData })
}
  
  
  