import React from 'react'
import { useSelector } from 'react-redux'
import returnNavItems from '../navigation/_returnNavItems'
import NavPage from './NavPage'
import { useTranslation } from 'react-i18next'



const LandingPage = () => {

  const { t } = useTranslation()

  const rights = useSelector(s => s.rights) 
  const navItems = returnNavItems(rights, t)
  return <NavPage 
    navItems={navItems}
  />

}


export default LandingPage