import React from 'react'
import PropTypes from 'prop-types'
import CockpitLogo from '../../iconsImages/cockpit_white.svg'
import NavList from '../navigation/NavList'
import { useHistory  } from 'react-router-dom'
import HeaderMenu from './HederMenu/HeaderMenu'
import { MainHeaderStyled, StyledLogoPack } from './Styles'


const MainHeader = ({ location }) => {

  const history = useHistory()
  const goHome = () =>  history.push('/')

  return  (
    <MainHeaderStyled >
      <div className="upper row">

        {location && <NavList properties="col-auto"/> }

        <div className="col p-0"/>

        <StyledLogoPack className="col-auto">

          <img className="logo" src={CockpitLogo} alt="" onClick={goHome}/>

          <HeaderMenu/>
    

        </StyledLogoPack>

  
      </div>
    </MainHeaderStyled>
  )
}

MainHeader.propTypes = {
  location: PropTypes.string,
}

export default MainHeader


