import React from 'react'
import PropTypes from 'prop-types'
import { ModalHeaderStyled } from './Styles'

const ModalHeader = ({ children }) => (
  <ModalHeaderStyled className="modal-header" >
    {children}
  </ModalHeaderStyled>
)

ModalHeader.propTypes = {
  children: PropTypes.node,
}

export default ModalHeader
