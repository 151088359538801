import { createStore, compose, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import rootReducer from '../r_reducers'
import checkTokenExpirationMiddleware from '../middleware/authChecker'
import * as Sentry from '@sentry/react'

export const history = createBrowserHistory()
const connectRouterHistory = connectRouter(history)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const configureStoreProd = (initialState) => {
  const reactRouterMiddleware = routerMiddleware(history)
  const middlewares = [
    thunk,
    reactRouterMiddleware,
    checkTokenExpirationMiddleware,
  ]

  return createStore(
    connectRouterHistory(rootReducer), 
    initialState, 
    compose(applyMiddleware(...middlewares), sentryReduxEnhancer)
  )
}

const configureStoreDev = (initialState) => {
  const reactRouterMiddleware = routerMiddleware(history)
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk,
    reactRouterMiddleware,
    checkTokenExpirationMiddleware,
  ]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    connectRouterHistory(rootReducer),  
    initialState, 
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  )

  return store
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore
