import React from 'react'
import PropTypes from 'prop-types'
import InputField from '../../common/InputField/InputField'
import InputArea from '../../common/InputArea/InputArea'
import SubmitButton from '../../common/SubmitButton/SubmitButton'
import PropertiesHubsSelector from '../_common/PropertiesHubsSelector'
import { useTranslation } from 'react-i18next'
import SendingOptionsHandler from './sending-options/SendingOptionsHandler'
import './Alerts.scss'


const CreateAlertForm = ({ data, onSubmit, onChange, hubs, setHubs, setProperties }) => {
  const { name, description, submitted } = data
  const { t } = useTranslation()

  return(
    <div className="EditAlertForm container">
      <div className="alert-title col-12">{t('alerts.send')}</div>
      <div className="borderFrame">
        <div className="alert-form col-lg-8 offset-lg-2">
               
          <InputField className="field"
            type="text"
            property="name"
            placeholder={t('alert.name')}
            isValid= {!!name || !submitted } 
            value={name}
            onChange={onChange}
            invalidText={t('alert.name.required')}
            noLabel={false}
            maxLength="20"
          />
       
          <InputArea className="description-field"
            type="text"
            property="description"
            placeholder={t('content')}
            isValid= {!!description || !submitted } 
            value={description}
            onChange={onChange}
            invalidText={t('content-required')}
            noLabel={false}
            maxLength={128}
          />

          <PropertiesHubsSelector 
            data={data}
            hubs={hubs} 
            setHubs={setHubs}
            setProperties={setProperties}
          />

          <SendingOptionsHandler data={data} onChange={onChange}/>

          <SubmitButton onClick={onSubmit}/>

        </div>
      </div>

    </div>
      

  )
}

CreateAlertForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  hubs: PropTypes.array,
  properties: PropTypes.array,
  onHubSelect: PropTypes.func,
  
}

export default CreateAlertForm

