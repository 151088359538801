import toastr from 'toastr'

import config from 'helpers/api_config'

import initialState from './initialState'
import {
  LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGOUT_SUCCESS, LOGOUT_FAILURE,
  AUTH_SUCCESS, AUTH_ERROR,
  FOREIGN_LOGIN_SUCCESS, FOREIGN_LOGIN_ERROR
} from 'r_constants/auth.constants'

import {
  PATCH_USER_PROFILE_SUCCESS, PATCH_USER_PROFILE_FAILURE,
  CREATE_USER_PROFILE_SUCCESS, CREATE_USER_PROFILE_FAILURE,
} from 'r_constants/userProfile.constants'



export default function authentification(auth = initialState.authentication, action) {
  switch (action.type) {

    case LOGIN_SUCCESS:
    case AUTH_SUCCESS:
      return action.payload || null

    case LOGIN_FAILURE:
    case AUTH_ERROR:
      return auth

    case LOGOUT_SUCCESS:
      return null

    case LOGOUT_FAILURE:
      return auth

    case FOREIGN_LOGIN_SUCCESS:
      redirect(action.token)
      return auth

    case FOREIGN_LOGIN_ERROR:
      return auth

    case PATCH_USER_PROFILE_SUCCESS:
      return { ...auth, user: { ...auth.user, sender: action.profile.sender } }

    case PATCH_USER_PROFILE_FAILURE:
      return auth

    case CREATE_USER_PROFILE_SUCCESS:
      return { ...auth, user: { ...auth.user, sender: action.profile.sender } }

    case CREATE_USER_PROFILE_FAILURE:
      return auth

    default:
      return auth
  }
}

const redirect = async (token) => {
  const webApp = config.webAppURL || 'http://localhost:3001'
  const newTab = window.open(webApp, '_blank')

  if (!newTab) return toastr.error('Popups nicht erlaubt')

  const receiveMessage = (event) => {
    if (event.origin.startsWith(webApp) && event.data?.type === 'webAppReady') {
      event.source.postMessage({ token, type: 'auth' }, webApp)
      window.removeEventListener('message', receiveMessage)
    }
  }

  window.addEventListener('message', receiveMessage)
}