import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import { Wrapper, MenuButton, Title, SubMenu, Category, SubCategory, SubSubCategory } from './Styles'
import { Icons } from 'constants/navIcons'


const NavDropDown = ({ navItems, onSelect, selected }) => {

  const [ activeMenu, setActiveMenu ] = useState(false)

  const activeColor = activeMenu ? '#f75fab' : 'white'

  return <Wrapper onMouseEnter={() => setActiveMenu(true)} onMouseLeave={() => setActiveMenu(false)} onClick={() => setActiveMenu(true)}>

    <MenuButton>
      <SimpleLineIcon 
        name="menu" size="medium" 
        color="white"
      />
      <Title>{selected.title}</Title>
    </MenuButton>

    { activeMenu && <SubMenu>

      {navItems.map(ni => 
        <Category key={ni.id}>

          <SubCategory>
            <img src={Icons[ni.id]} alt="" style={{ marginRight: '5px' }}/>  
            <div style={{ fontWeight: 'bold' }}>{ni.title}</div>
          </SubCategory>

          { ni.items.map(si => 
            <SubSubCategory key={si.name} onClick={() => onSelect(si.name)} activeColor={activeColor}> 
              {si.title}

            </SubSubCategory>
          )
          }

        </Category>
      )}

    </SubMenu>
    }

  </Wrapper>
}



NavDropDown.propTypes = {
  navItems: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.object,
  
}

export default NavDropDown



