import styled from 'styled-components'

const galleryImage = {
  minWidth: '100%',
  height: '300px',
  objectFit: 'cover',
}

const modalImage = {
  maxHeight: '500px',
  margin: 'auto',
  display: 'block',
}

const StyledImage = styled.img`
  ${(props) =>
    props.mode === 'gallery' ? { ...galleryImage } : { ...modalImage }}

  @media (max-width: 600px) {
    max-width: 100%;
  }

  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  &:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
`

const StyledFallback = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;

  margin: auto;
  display: block;

  img {
    height: 100px;
    margin: 60px auto;
    display: block;
  }
`

export { StyledImage, StyledFallback }
