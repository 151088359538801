import React from 'react'
import PropTypes from 'prop-types'
import SubmitButton from '../../common/SubmitButton/SubmitButton'
import PropertiesHubsSelector from '../_common/PropertiesHubsSelector'
import { useTranslation } from 'react-i18next'
import VoiceRecorder from  './VoiceRecorder'
import VoiceRecord from './VoiceRecord'

const CreateVoiceMessageForm = ({ data, onDelete, recorderHandlers, onSubmit, hubs, setHubs, setProperties, setDuration }) => {

  const { t } = useTranslation()

  return(
    <div className="EditAlertForm container">
      <div className="alert-title col-12">{t('voice.message.send')}</div>
      <div className="borderFrame">
        <div className="alert-form col-lg-8 offset-lg-2">
               
          <VoiceRecorder handlers={recorderHandlers} data={data} setDuration={setDuration} />

          {
            data.audio && <VoiceRecord 
              audio={data.audio} 
              onDelete={onDelete}
            />
          }
       
          <PropertiesHubsSelector 
            data={data}
            hubs={hubs} 
            setHubs={setHubs}
            setProperties={setProperties}
          />

          <SubmitButton onClick={onSubmit}/>

        </div>
      </div>
    </div>
  )
}

CreateVoiceMessageForm.propTypes = {
  data: PropTypes.object,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  hubs: PropTypes.array,
  properties: PropTypes.array,
  onHubSelect: PropTypes.func,
  
}

export default CreateVoiceMessageForm

