export default {
  authentication: null,
  hubs: null,
  news: null,
  docs: null,
  images: null,
  alerts: null,
  properties: null,
  search: { text: '', hub: null, date: { from: null, to: null } },
  result: null,
  rights: null,
  missing: null,
  oem: null,
  voiceMessages: null,
  i18n: { info: null, language: 'de' },
}
    