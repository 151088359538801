import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledWrapper, StyledRow, StyledTile, StyledDescription, StyledImageContainer, StyledImageTitle } from './Styles'
import ImageModal from '../modal/ImageModal'
import Image from '../image/Image'
import { returnListOnSearch } from 'components/common/SearchList/List'


const Gallery = ({ imageData }) => {

  const searchValue = useSelector(s => s.search)

  const [selectedImage, setSelectedImage] = useState(null)

  return <StyledRow className="row">

    {selectedImage && <ImageModal selectedImage={selectedImage} close={() => setSelectedImage(null)} />}

    {returnListOnSearch(imageData, searchValue, 'description', 'id', -1).map(i => 

      <StyledWrapper key={i.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"> 

        <StyledTile onClick={() => setSelectedImage(i)}>

          <StyledImageContainer>

            <Image path={i.file_path} mode="gallery" />

            <StyledDescription>
              <StyledImageTitle>{i.description}</StyledImageTitle>
              <div>{i.formedDate}</div>
            </StyledDescription>

            
          </StyledImageContainer>


        </StyledTile>
        
      </StyledWrapper>

    )

   
    }

  </StyledRow>

   
}

export default Gallery



