import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { switchLanguage } from 'r_actions/i18nActions'
import { flags } from 'intl/Flags'
import { loadI18NInformation } from 'r_actions/i18nActions'

import { useTranslation } from 'react-i18next'

import { Wrapper, Background, ListElement, StyledImg } from './Styles'

const LanguagePicker = () => {
  const dispatch = useDispatch()

  const { i18n } = useTranslation()

  const [activePicker, setActivePicker] = useState(false)

  const ref = useRef()

  useEffect(() => {
    dispatch(loadI18NInformation())
  }, [dispatch])
  

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (activePicker && ref.current && !ref.current.contains(e.target)) {
        setActivePicker(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [activePicker])

  const { language, info } = useSelector(s => s.i18n)

  if (!info) return <div/>

  const options = info.languages.split(',').filter(l => l !== language)

  const selectLanguage = lng => {
    setActivePicker(false)
    i18n.changeLanguage(lng)
    dispatch(switchLanguage(lng))
  }


  return <Wrapper className="LanguagePicker" ref={ref} >
    <Background>
      <ListElement onClick={() => setActivePicker(!activePicker)}>
        {language.toUpperCase()}
        <StyledImg src={flags[language]} />
      </ListElement>

      {activePicker &&
      <div>
        {
          options.map(lng =>
            <ListElement key={lng} onClick={() => selectLanguage(lng)}>
              {lng.toUpperCase()}
              <StyledImg src={flags[lng]} />
            </ListElement>
          )
        }
      </div>
      }
    </Background>
  </Wrapper>
}

export default LanguagePicker
