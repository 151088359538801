import React from 'react'
import PropTypes from 'prop-types'
import './XButton.scss'
import { CancelIcon } from 'constants/miscIcons'

const XButton = ({ onClick, value }) => {

  return (
    <div onClick={() => onClick(value)} className="XButton">
      <CancelIcon />
    </div>
  )
}
    
XButton.propTypes = {
  onClick:PropTypes.func,
  value:PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
  
  
export default XButton