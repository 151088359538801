export const alertItems = (t) => [ 
  { title: t('alerts.send'), name: 'alerts-create', right: 2 }, 
  { title: t('alerts.archive'), name: 'alerts-archive', right: 1 },
]
  
export const newsItems = (t) => [
  { title: t('news.send'), name: 'news-create', right: 2 }, 
  { title: t('news.archive'), name: 'news-archive', right: 1 }
]

export const imageItems = t => [
  { title: t('images.send'), name: 'images-create', right: 2 }, 
  { title: t('images.archive'), name: 'images-archive', right: 1 }
]

export const docsItems = t => [
  { title: t('docs.create'), name: 'docs-create', right: 2 }, 
  { title: t('docs.archive'), name: 'docs-archive', right: 1 }
]

export const hubItems = t => [
  { title: t('hubs.overview'), name: 'hubs-archive', right: 2 }
]

export const voiceMessagesItems = t => [
  { title: t('voice.send'), name: 'voice-create', right: 2 }, 
  { title: t('voice.archive'), name: 'voice-archive', right: 1 }
]
  
  
