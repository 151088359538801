import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchImages } from 'r_actions/images.actions'
import { fetchHubs } from 'r_actions/hubs.actions'
import config from 'helpers/api_config'
import Gallery from './subs/gallery/Gallery'


const ImagesArchive = () => {
  const dispatch = useDispatch()
    
  useEffect(() => {
    dispatch(fetchImages())
    dispatch(fetchHubs())
  }, [dispatch])

  const returnPath = (path) => path
    ? `${config.feathersAPI}/upload/${path}`
    : null

  const images = useSelector(s => s.images || [])
  const hubs = useSelector(s => s.hubs || [])
 
  const [imageData, setImageData] = useState([])

  useEffect(() => {
    if (!images.length || !hubs.length) return
    const hubsObject = hubs.reduce(objectify,{})
    setImageData(images.map(a => ({ ...a, 
      hubs: a.hubs.map(h => hubsObject[h]).filter(Boolean),
      file_path: returnPath(a.file_path)  })))
  }, [images, hubs])


  return <Gallery imageData={imageData}  />
     
}

export default ImagesArchive

const objectify = (acc, h) => ({ ...acc, [h.id]: h })


