import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translation from './lng'

const resources = Object.keys(translation).reduce((acc, key) => ({ ...acc, [key]: { translation: translation[key] } }),{})
const storedLanguage = localStorage.getItem('lng')
  
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ 
    lng: storedLanguage,
    fallbackLng: 'de',
    resources 
  })

export default i18n
 
