import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
import { fetchHubs } from 'r_actions/hubs.actions'
import List from '../common/SearchList/List'

const HubsArchive = () => {
  const dispatch = useDispatch()

  const hubs = useSelector(s => s.hubs || [])
  const [hubsData, setHubsData] = useState([])
     
  useEffect(() => {
    dispatch(fetchHubs())
  }, [dispatch])


  useEffect(() => {
    if (!hubs.length) return
    setHubsData([...hubs])
  }, [hubs])

  return <List 
    array={hubsData} 
    searchProperty="description"
    sortProperty="description" 
    idProperty="id"
    titleProperty="description" 
    dateProperty={null}
    sender={null}
    sortOrder={1}
    heading="Zentraleinheiten"
    string={true}
  />
   
}

// AlertsArchive.propTypes = {
// }


export default HubsArchive
