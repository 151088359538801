import styled from 'styled-components'

const StyledButton = styled.button`
cursor: pointer;
color: white;
width: calc(100% - 20px);
margin: 10px;
padding: 5px;
background-color: #363a3f;
border-radius: 3px;
outline: inherit;
border: none;
&:hover{
    background-color: #363a3fe0;
}
`

export { StyledButton }
