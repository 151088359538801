import React from 'react'
import PropTypes from 'prop-types'
import InputField from 'components/common/InputField/InputField'
import InputArea from 'components/common/InputArea/InputArea'
import FileSelector from '../_common/FileSelector'
import SubmitButton from 'components/common/SubmitButton/SubmitButton'
import PropertiesHubsSelector from '../_common/PropertiesHubsSelector'
import { useTranslation } from 'react-i18next'
import './News.scss'

const accept = 'image/jpeg,image/gif,image/png,application/pdf,image/x-png'

const MAX_NEWS_LENGTH = 1000


const CreateNewsForm = ({ data, setHubs, setProperties, hubs, onChange, submitForm }) => {
  const { name, content, submitted, file } = data
  const { t } = useTranslation()

  return(
    <div className="EditNewsForm container">
      <div className="news-title col-12">{t('news.send')}</div>
      <div className="borderFrame">
        <div className="news-form col-lg-8 offset-lg-2">
      
          <InputField className="field"
            type="text"
            property="name"
            placeholder={t('title')}
            isValid= {!!name || !submitted } 
            value={name}
            onChange={onChange}
            invalidText={t('title-required')}
            maxLength="20"
          />
   
          <InputArea className="description-field"
            type="text"
            property="content"
            placeholder={t('content')}
            // isValid= {!!content || !submitted } 
            value={content}
            onChange={onChange}
            // invalidText={translate({ id: 'content-required' })}
            maxLength={MAX_NEWS_LENGTH}
          />

          <PropertiesHubsSelector 
            data={data}
            hubs={hubs} 
            setHubs={setHubs}
            setProperties={setProperties}
          />

          <FileSelector 
            file={file}
            onChange={onChange}
            accept={accept}
            additionalMimes={['image/gif', 'application/pdf']}
          />

          <SubmitButton onClick={submitForm}/>
  
        </div>         
      </div>
     
    </div>


  )
}

CreateNewsForm.propTypes = {
  data: PropTypes.object,
  hubs: PropTypes.array,
  onChange: PropTypes.func, 
  submitForm: PropTypes.func,
  setHubs: PropTypes.func, 
  setProperties: PropTypes.func, 
}

export default CreateNewsForm


