import styled from 'styled-components'

const File = styled('div')`
 margin: 1em;
 border: 1px solid black;

`


export { File }
