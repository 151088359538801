import de from './de.svg'
import en from './en.svg'
import nl from './nl.svg'
import ro from './ro.svg'
import ru from './ru.svg'
import dk from './dk.svg'

export const flags = {
  de,
  en,
  nl,
  ro,
  ru,
  dk
}

//! more flags: https://flagicons.lipis.dev
