import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm'
import toastr from 'toastr'
import { login } from '../../r_actions/auth.actions'
import { useTranslation } from 'react-i18next'



const LoginPage = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [{ username, password, submitted }, setData] = useState({ username: '', password: '', submitted: false })
 
  const onChange = (e) => {     
    const { name, value } = e.target        
    setData(d => ({ ...d, [name]: value })) 
  }


  const onSubmit = () => {
    const wrongUser = t('wrong-user', { username })
    const wrongPass = t('wrong-password')
    if(username === '' || password === '') {
      setData(d => ({ ...d, submitted: true }))
      return toastr.error(t('check-input'))
    }
    dispatch(login({ username, password }, wrongPass, wrongUser))
  }
  
   
  return (    
    <LoginForm 
      username={username} 
      password={password} 
      onChange={onChange} 
      submitted={submitted}
      onSubmit={onSubmit}
    />
  )
   
}



LoginPage.propTypes = {
  authActions: PropTypes.object,
   
}

  
export default LoginPage