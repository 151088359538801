import { 
  PATCH_USER_PROFILE_SUCCESS, PATCH_USER_PROFILE_FAILURE,
  CREATE_USER_PROFILE_SUCCESS, CREATE_USER_PROFILE_FAILURE
} from 'r_constants/userProfile.constants'
    
import { _patchUserProfile, _createUserProfile } from 'r_services/userProfile.service'
    
export const patchUserProfile = data => async dispatch => {
  try {
    const profile = await _patchUserProfile(data)
    dispatch({ type: PATCH_USER_PROFILE_SUCCESS, profile })
    return 1
  } catch (error) {
    dispatch({ type: PATCH_USER_PROFILE_FAILURE, error })
  }
}


export const createUserProfile = data => async dispatch => {
  try {
    const profile = await _createUserProfile(data)
    dispatch({ type: CREATE_USER_PROFILE_SUCCESS, profile })
    return 1
  } catch (error) {
    dispatch({ type: CREATE_USER_PROFILE_FAILURE, error })
  }
}
    
    
    
    