import styled from 'styled-components'

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;


  background: #000000cf;
  backdrop-filter: blur(8px);
  z-index: 999;
  display: flex;
  justify-content: center;
`

const StyledCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

const StyledInfoBlock = styled.div`
  width: 100%;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  bottom: 20px;
  left: 20px;
`

const StyledDescription = styled.div`
  font-weight: bold;
`

const StyledHubWrapper = styled.div`
  margin-left: 0px;
`

const HubsModalComponent = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #000000d6;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  z-index: 100;
  cursor: pointer;
  line-height: 20px;
`

const MoreHubsButton = styled.button`
 border: none;
 padding: 5px;
 background-color: #22c7dd;
 color: white;
 border-radius: 5px;
 &:hover{
  background-color: #69d9e8;
 }


`

export {
  StyledModal,
  StyledCloseButton,
  StyledInfoBlock,
  StyledDescription,
  StyledHubWrapper,
  HubsModalComponent,
  MoreHubsButton,
}
