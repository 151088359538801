import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory  } from 'react-router-dom'
import returnNavItems from './_returnNavItems'
import NavDropDown from './dropDown/NavDropDown'
import { useTranslation } from 'react-i18next'

const NavList = () => {
  const { t } = useTranslation()
  
  const history = useHistory()

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')

  const rights = useSelector(s => s.rights)

  const [navItems, setNavItems] = useState([])


  useEffect(() => {
    rights && setNavItems(returnNavItems(rights, t))
  }, [rights, t])

  const onSelect = (name) => history.push(`/${name}`)

  const selected = navItems.reduce((acc, item) => 
    item.items.find(i => i.name === pathname + '') || acc ,null)


  return  selected ? <NavDropDown 
    navItems={navItems}
    onSelect={onSelect}
    selected={selected}
  />
    : <div/>

}



export default NavList



