import React from 'react'
import PropTypes from 'prop-types'
import { StyledListItem } from './Styles'
import SimpleLineIcon from 'react-simple-line-icons'

const ListItemHeader = ({ icon, text, action }) => {

  return  (
    <StyledListItem className="dropdown-item" type="button" onClick={() => action()} >

      <SimpleLineIcon  
        style={{ marginRight: '8px' }}
        name={icon}
        size="medium" 
        color={'black'} 
      />

      {text}
      
    </StyledListItem>

  )
}

ListItemHeader.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.func
}

export default ListItemHeader

