import styled, { keyframes } from 'styled-components'

const basicButtonProps = `
border-radius: 50%;
border: solid 4px #cfd2d6;
outline: none;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
box-shadow: -10px -10px 15px rgba(255,255,255,0.5),
            10px  10px 15px rgba(70,70,70,0.15);
&:hover{
box-shadow: -10px -10px 15px rgba(255,255,255,0.5),
            10px  10px 15px rgba(70,70,70,0.15),
            inset -10px -10px 15px rgba(255,255,255,0.5),
            inset 10px  10px 15px rgba(70,70,70,0.15);
`

const StyledStartSaveButton = styled.div`
   width: 70px;
   min-width: 70px;
   height:70px;
${basicButtonProps}
`

const StyledDeleteButton = styled.div`
   width: 50px;
   min-width: 50px;
   height:50px;
${basicButtonProps}
&:hover{
    color: #ff0000;
}
`

const StyledPlayButton = styled.div`
   width: 50px;
   min-width: 50px;
   height:50px;
${basicButtonProps}
`

const StyledProgressBar =  styled.div`
  display: flex;
  align-items: center;
  span{
    margin: 15px;
  }
` 

const StyledContainer = styled.div`
  cursor: pointer;
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 5rem 0.5rem 5rem;
  border-radius: 5px;
  border: 1px solid #d9d9d9 !important;
  background-color: #cfd2d6;
  color: #4a4a4a;

  @media (max-width: 768px) {
    padding: 0.5rem;
    justify-content: space-around;

    ${StyledPlayButton}{
        order: 1;
    }
    ${StyledDeleteButton}{
        order: 2;
    }
    ${StyledProgressBar}{
        order: 3;
    }
  }
`

const StyledDisplay = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
`
const IndicatorAnimation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
` 

const StyledIndicator = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #099fff;
  animation-name: ${IndicatorAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export { 
  StyledContainer, 
  StyledDeleteButton,
  StyledPlayButton,
  StyledStartSaveButton,
  StyledDisplay,
  StyledIndicator,
  StyledProgressBar  
}
