let env = process.env.REACT_APP_API_ENV
if (env && env === 'production') env = 'prod'

const webAPP = {
  dev: 'https://dev-v3.casenio.de',
  prod: 'https://prod-v3.casenio.de'
}

const feathersAPI = env ? `https://api-${env}-v3.casenio.de` : 'http://localhost:3030'

const config = {
  feathersAPI,
  webAppURL: webAPP[env]
}

export default config
