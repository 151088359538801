import React from 'react'
import { useSelector } from 'react-redux'

import DatePicker, { registerLocale } from 'react-datepicker'
import RepetitionSelector from 'components/SendStuff/alerts/sending-options/RepetitionSelector'
import { StyledSettingsSection, StyledDateWrapper, StyledRepititionWrapper, StyledTitle } from './Styles'

import { useTranslation } from 'react-i18next'
import de from 'date-fns/locale/de'
import nl from 'date-fns/locale/nl' 


registerLocale('de', de)
registerLocale('nl', nl)

//TODO: refactor modal

const SettingsView = ({ setData, planedDate, repeat }) => {
  const { t } = useTranslation()
  const { language } = useSelector(s => s.i18n)
  const filterPassedTime = (time) => new Date().getTime() < new Date(time).getTime()

  const onChange = (e) => {   
    let { name, value } = e.target   
    setData(d => ({ ...d, [name]: value || null }))  
  }
 
  return (<StyledSettingsSection>
    
    <StyledDateWrapper className="row">

      <div className="col p-0">{t('dispatch.time.set')}</div>

      <div className="col">
        <DatePicker
          selected={planedDate}
          onChange={date => setData(d => ({ ...d, planedDate: date }))}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={5}
          timeCaption={t('time')}
          dateFormat="MMMM d, yyyy HH:mm"
          locale={language}
          minDate={new Date()}
          filterTime={filterPassedTime}
          inline
        />
      </div>
    </StyledDateWrapper>


    <StyledRepititionWrapper >

      <StyledTitle> {t('repetition.cycle.set')}</StyledTitle>

      <div>
        <RepetitionSelector onChange={onChange} repeat={repeat}/>
      </div>

    </StyledRepititionWrapper> 

  </StyledSettingsSection>
  )
}




export default SettingsView
