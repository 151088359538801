
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import './SubmitButton.scss'

const SubmitButton = ({ onClick }) => {
  library.add(faPaperPlane)
  const { t } = useTranslation()

  return (
    <button className="SubmitButton btn btn-success" type="button" onClick={() => onClick()}>
      <FontAwesomeIcon icon="paper-plane"/>  
      {` ${t('send')}`}
    </button>
  )
}
    
SubmitButton.propTypes = {
  onClick:PropTypes.func,
}
    
export default SubmitButton



