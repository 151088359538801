import initialState from './initialState'
import { 
  FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_FAILURE
} from 'r_constants/properties.constants'

export default function properties(properties = initialState.properties, action) {
  switch (action.type) {
      
  case FETCH_PROPERTIES_SUCCESS:
    return action.properties.data || null

  case FETCH_PROPERTIES_FAILURE:
    return properties

  default:
    return properties
  }
}
