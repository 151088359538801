import React from 'react'
import PropTypes from 'prop-types'
import './Empty.scss'




const Empty = ({ title = '' }) => {

  return <div className="Empty">
    {title}
  </div>
}

    
Empty.propTypes = {
  title:PropTypes.string,

}
  
  
export default Empty