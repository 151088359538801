import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import config from 'helpers/api_config' 
import { fetchHubs } from 'r_actions/hubs.actions'
import { fetchVoiceMessages } from 'r_actions/voiceMessages.actions'
import List from 'components/common/SearchList/List'

const formatTime = (time) => {
  const formatUnits = units => units < 10 ? `0${units}` : `${units}`
  
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${formatUnits(minutes)}:${formatUnits(seconds)}`
  }
  return '00:00'
}

const VoiceMessagesArchive = () => {
  const dispatch = useDispatch()
     
  useEffect(() => {
    dispatch(fetchHubs())
    dispatch(fetchVoiceMessages())
  }, [dispatch])

  const returnPath = (path) => path
    ? `${config.feathersAPI}/upload/${path}`
    : null



  const voiceMessages = useSelector(s => s.voiceMessages || [])
  const hubs = useSelector(s => s.hubs || [])

  const [voiceData, setVoiceData] = useState([])

  useEffect(() => {
    if (!voiceMessages.length || !hubs.length) return
    const hubsObject = hubs.reduce(objectify,{})
    setVoiceData(voiceMessages.map(vm => ({ ...vm, 
      hubs: vm.hubs.map(h => hubsObject[h]).filter(Boolean),
      file_path: returnPath(vm.file_path),
      duration: formatTime(vm.duration)
    })))
   
  }, [hubs, voiceMessages])


  return (

    <List 
      array={voiceData} 
      searchProperty={null}
      sortProperty="id" 
      idProperty="id"
      titleProperty="duration" 
      dateProperty="init" 
      sender="user"
      sortOrder={-1}
      content="content"
      heading="Sprachnachrichten"
    />

  )
}

VoiceMessagesArchive.propTypes = {
  onClick: PropTypes.func,
}

export default VoiceMessagesArchive

const objectify = (acc, h) => ({ ...acc, [h.id]: h })
