import { IMAGES } from './feathers'
// import { checkSent } from './userMessages'

export const _fetchImages = async () => {
  const images = await IMAGES.find({ query: { $sort: { id: -1 } } })
  return images?.data || images
}

export const _createImages = async ({ file, imagesPackage }) => await IMAGES.create(imagesPackage, { file })


