import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAlerts } from 'r_actions/alerts.actions'
import { fetchHubs } from 'r_actions/hubs.actions'
import List from '../../common/SearchList/List'
import { setResult } from 'r_actions/noApi.actions'
import { useTranslation } from 'react-i18next'
import toastr from 'toastr'


const AlertsArchive = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

     
  useEffect(() => {
    dispatch(fetchAlerts())
    dispatch(fetchHubs())
  }, [dispatch])

  const alerts = useSelector(s => s.alerts || [])
  const hubs = useSelector(s => s.hubs || [])
  const result = useSelector(s => s.result)

 
  const [alertData, setAlertData] = useState([])

  useEffect(() => {
    if (!alerts.length || !hubs.length) return
    const hubsObject = hubs.reduce(objectify,{})
    setAlertData(alerts.map(a => ({ ...a, 
      hubs: a.hubs.map(h => hubsObject[h]).filter(Boolean) })))
  }, [alerts, hubs])

  
  useEffect(() => {
    if(!result) return 
    toastr.success(t('alerts.applied'))
    dispatch(setResult(null))
  }, [dispatch, result, t]) 


  

  return <List 
    array={alertData} 
    searchProperty="name"
    sortProperty="id" 
    idProperty="id"
    titleProperty="name" 
    dateProperty="init" 
    sender="user"
    sortOrder={-1}
    content="description"
    heading="Alarme"
  />
   
}


export default AlertsArchive

const objectify = (acc, h) => ({ ...acc, [h.id]: h })


