import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import XButton from 'components/common/XButton/XButton'
import imageCompression from 'browser-image-compression'
import toastr from 'toastr'
import { useTranslation } from 'react-i18next'
import './FileSelector.scss'

const BASIC_TYPES = ['image/x-png', 'image/jpeg', 'image/png'] //todo: separate gif


const FileSelector = ({ file, onChange, accept, additionalMimes }) => {

  const location = useLocation()

  const { t } = useTranslation()

  const path = location.pathname.replace('/', '')

  const onRemove = () => onChange({ target: { name: 'file', value: null } })

  const handleFileChange = async e => {

    if (!e.target.files.length) return null
    
    let [buffer] = e.target.files
    let mimetype = buffer.type
    
    const largeFile = mimetype === 'image/gif' && buffer.size > 5000000

    const supportedMime =  [...BASIC_TYPES, ...additionalMimes]
    const notSupported = !supportedMime.includes(mimetype)
  
    const doError = (message) => {
      onChange( { target: { name: 'file', value: null } })
      return toastr.error(message)
    }
     
    if(largeFile) return doError(t('images.big-file', { size: '5' }))
    if(notSupported) return doError(t('images.invalid-file'))

    const options = { maxSizeMB: 2, maxWidthOrHeight: 1920, useWebWorker: true }

    const compressed = BASIC_TYPES.includes(mimetype) ? await imageCompression(buffer, options) : buffer

    const filePreview = URL.createObjectURL(buffer)

    const type = mimetype.split('/')[1]

    let reader = new FileReader()
    reader.readAsDataURL(buffer)
    reader.onloadend = () => 
      onChange({ target: { name: 'file', value: { buffer: compressed, mimetype, filePreview, type } } })
      
  }


  return ( 
    
    <div className="FileSelector row"> 

      <label htmlFor="file-upload" className="custom-file-upload col row">

        <div className="col-1"> 
          <SimpleLineIcon 
            name="doc" size="large" 
            color="grey"
          />
        </div>
        <div className="selected-name col">
          {           
            file 
              ? `Datei: ${file.buffer.name}` 
              : t( `${path}.select.file` )
          }
        </div>
        
      </label>
      {  file ?
        <div className="selected-name col-1">
          <XButton
            onClick={onRemove}
          />
        </div> : null
      }
      <input id="file-upload" type="file" accept={accept} onChange={(e)=>handleFileChange(e)} onClick={(e)=> {e.target.value = null}}/>
    </div>
    
  )
}

FileSelector.propTypes = {
  file: PropTypes.object,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  additionalMimes: PropTypes.array,

}
  
  
export default FileSelector