import { format } from 'date-fns'
import config from 'helpers/api_config'
import { de } from 'date-fns/locale'

import initialState from './initialState'
import { 
  FETCH_DOCS_SUCCESS, FETCH_DOCS_FAILURE,
  CREATE_DOCS_SUCCESS, CREATE_DOCS_FAILURE,
  DELETE_DOCS_SUCCESS, DELETE_DOCS_FAILURE
} from 'r_constants/docs.constants'

export default function docs(docs = initialState.docs, action) {
  switch (action.type) {
      
  case FETCH_DOCS_SUCCESS:
    return action.docs 
      ? formDocs(action.docs) 
      : null

  case FETCH_DOCS_FAILURE:
    return docs

  case CREATE_DOCS_SUCCESS:
    return docs

  case CREATE_DOCS_FAILURE:
    return docs

  case DELETE_DOCS_SUCCESS:
    return docs.filter(d => +d.id !== action.doc.id)

  case DELETE_DOCS_FAILURE:
    return docs

  default:
    return docs
  }
}


const formDocs = (docs) => {

  const formDate = (date, form) => format(new Date(date), form, { locale: de })
  
  return docs.map(i => ({ ...i, 
    formedDate: formDate(i.created, 'dd.MM.yyyy HH:mm'),
    file_path: i.path ? `${config.feathersAPI}/upload/${i.path}` : null
  })
  )
}