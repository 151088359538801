import { 
  FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_FAILURE
} from 'r_constants/properties.constants'
  
import { _fetchProperties } from 'r_services/properties.service'
  
export const fetchProperties = () => async dispatch => {
  try {
    const properties = await _fetchProperties()
    dispatch({ type: FETCH_PROPERTIES_SUCCESS, properties })
  } catch (error) {
    dispatch({ type: FETCH_PROPERTIES_FAILURE, error })
  }
}
    
  