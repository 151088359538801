import styled from 'styled-components'
import { animated } from 'react-spring'

const Image = styled(animated.img)`
  max-width: 25em;
  margin: 1em;
  cursor: pointer;
  will-change: width, height;
`
const AnimatedContent = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0px 0px 0px 14px;
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
  overflow: hidden;
`

export { Image, AnimatedContent }
