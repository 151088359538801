import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import config from 'helpers/api_config'
import { fetchNews } from 'r_actions/news.actions'
import { fetchHubs } from 'r_actions/hubs.actions'
import List from 'components/common/SearchList/List'

import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`


const NewsArchive = () => {
  const dispatch = useDispatch()
     
  useEffect(() => {
    dispatch(fetchNews())
    dispatch(fetchHubs())
  }, [dispatch])

  const returnPath = (path) => path
    ? `${config.feathersAPI}/upload/${path}`
    : null

  const news = useSelector(state => state.news || [])
  const hubs = useSelector(s => s.hubs || [])

  const [newsData, setNewsData] = useState([])

  useEffect(() => {
    if (!hubs.length || !news.length) return
    const hubsObject = hubs.reduce(objectify,{})
    setNewsData(news.map(n => ({ ...n, 
      hubs: n.hubs.map(h => hubsObject[h]).filter(Boolean), 
      file_path: returnPath(n.file_path)  })))
  }, [hubs, news])


  return (

    <List 
      array={newsData} 
      searchProperty="title"
      sortProperty="id" 
      idProperty="id"
      titleProperty="title" 
      dateProperty="init" 
      sender="user"
      sortOrder={-1}
      content="content"
      heading="Nachrichten"
    />

  )
}

NewsArchive.propTypes = {
  onClick: PropTypes.func,
}

export default NewsArchive

const objectify = (acc, h) => ({ ...acc, [h.id]: h })
