import React from 'react'
import { useLocation } from 'react-router-dom'
import MainHeader from './MainHeader'
import SearchHeader from './SearchHeader/SearchHeader'
import TitleRow from './TitleRow/TitleRow'
import { HeaderWrapper } from './Styles'

const needSearch = ['alerts-archive', 'news-archive', 'hubs-archive', 'images-archive', 'docs-archive', 'voice-archive']
const needTitle = ['alerts-archive', 'news-archive', 'hubs-archive', 'docs-archive', 'voice-archive']

const Header = () => {

  let { pathname } = useLocation()
  pathname = pathname.replace('/', '')

  return  <HeaderWrapper>

    <MainHeader location={pathname}/>
    {needSearch.includes(pathname) && <SearchHeader location={pathname} />}
    {needTitle.includes(pathname) && <TitleRow location={pathname} />}

  </HeaderWrapper>
}


export default Header


