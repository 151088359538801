import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import 'intl/i18n'
import './index.scss'
import './helpers/toastr'

import * as Sentry from '@sentry/react'
import packageInfo from '../package.json'

import { switchLanguage } from 'r_actions/i18nActions'

import configureStore from  './helpers/store'
import App from './App'

const storedLanguage = localStorage.getItem('lng')

const env = process.env.REACT_APP_API_ENV

const store = configureStore()
store.dispatch(switchLanguage(storedLanguage || 'de'))

const dsn = env === 'production' 
  ? 'https://38832aeb93e5461cb2c33a374475c5fd@sentry.casenio.de/29'
  : 'https://178783bbecf0476b927aa5c6a0dfb4d1@sentry.casenio.de/30'  

env && Sentry.init({ dsn,
  release: `${packageInfo.version}`,
  environment: process.env.API_ENV,
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
})

render(
  <Provider store={store}>

    <App />

  </Provider>,

  document.getElementById('root')
)
