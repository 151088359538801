import styled from 'styled-components'

const ModalStyled = styled.div`
.modal {
    &.show {
      display: block;
      z-index: 9999;
      overflow: scroll;
    }
  }

  .modal-content{
    background-color: rgba(0,0,0,0.5);
  }


  .modal-backdrop {
    display: none;
    opacity: 0;
    z-index: 9998;
    &.show {
      display: block;
      opacity: .75;
    }
  }
`

const ModalHeaderStyled = styled.div`
background-color: #dee2e6;
`

const ModalBodyStyled = styled.div`
background-color: #dee2e6;
overflow: scroll;
`

const ModalFooterStyled = styled.div`
background: rgba(195, 193, 193, 0.63);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px); 
padding: 8px;

.left-button {
margin-right: auto;
}
`


export { ModalStyled, ModalFooterStyled, ModalBodyStyled, ModalHeaderStyled }
