import { alertItems, newsItems, imageItems, docsItems, hubItems, voiceMessagesItems } from './navItems'

export default ({ news, images, docs, externalAlerts, foreignLogin, voiceMessages }, t) => [
  { 
    id: 1, 
    title: t('alerts.title'), 
    items: alertItems(t).filter(a => +a.right <= +externalAlerts), 
    value: 'alerts-create',
    description: t('alerts.description')
  }, 
  { 
    id: 2, 
    title: t('news.title'), 
    items: newsItems(t).filter(n => +n.right <= +news), 
    value: 'news-create',
    description: t('news.description')
  },
  { 
    id: 3, 
    title: t('images.title'), 
    items: imageItems(t).filter(n => +n.right <= +images), 
    value: 'images-create',
    description: t('images.description'),
  },
  { 
    id: 4, 
    title: t('voice.title'), 
    items: voiceMessagesItems(t).filter(d => +d.right <= +voiceMessages), 
    value: 'voice-archive',
    description: t('voice.description')
  },
  { 
    id: 5, 
    title: t('hubs.title'), 
    items: hubItems(t).filter(h => +h.right <= +foreignLogin), 
    value: 'hubs-archive',
    description: t('hubs.description')
  },
  { 
    id: 6, 
    title: t('docs.title'), 
    items: docsItems(t).filter(d => +d.right <= +docs), 
    value: 'docs-archive',
    description: t('docs.description')
  },

].filter(arr => arr.items.length)