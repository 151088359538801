import React from 'react'
import PropTypes from 'prop-types'
import SimpleLineIcon from 'react-simple-line-icons'
import moment from 'moment'
import { StyledInput, CalIcon, CloseIcon, Label } from './Styles'

class InputRow extends React.Component {  //! dontdothis! leave as component!

  render() {

    const { property, placeholderText, date, onClick, onEmpty } = this.props

    let dateLabel = date ? moment(date).local().format('DD.MM.YYYY') : placeholderText
    
    return ( 
      <StyledInput className="meegaHUdn">

        <CalIcon onClick={onClick}> 
          <SimpleLineIcon name="calendar" size="Medium" />
        </CalIcon>

        <Label onClick={onClick}>
          {dateLabel}
        </Label> 

        <CloseIcon className="close-icon"  onClick={() => onEmpty({ [property]: null })}> 
          { date && <SimpleLineIcon name="close" size="Medium" /> }
        </CloseIcon>

      </StyledInput>

    )
  }
}



InputRow.propTypes = {
  placeholderText: PropTypes.string,
  property: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  onEmpty: PropTypes.func,
}
  
  
export default InputRow