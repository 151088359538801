import initialState from './initialState'
import { UPDATE_SEARCH_VALUE, RESET_SEARCH_VALUE } from 'r_constants/noApi.constants'

export default function search(search = initialState.search, action) {
  switch (action.type) {
      
  case UPDATE_SEARCH_VALUE:
    return { ...search, ...action.search }

  case RESET_SEARCH_VALUE:
    return initialState.search

  default:
    return search
  }
}
